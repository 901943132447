const baseURL= process.env.REACT_APP_BASEURL;

const endpoints = {
    urlPostLoginContributeur: () => `${baseURL}/contributeur/login_check`,
    urlPostLoginAmbassadeur: () => `${baseURL}/administrateur/login_check`,
    postContributeur: () => `${baseURL}/contributeur`,
    postAmbassadeur: () => `${baseURL}/administrateur`,
    postFeedback: () => `${baseURL}/feedback`,
    postContribution: () => `${baseURL}/contribution`,
    urlGetCagnottesProgrammees: (page = 1, statutProgramme = 1) => `${baseURL}/cagnottes?page=${page}&statutProgramme=${statutProgramme}`,
    urlGetCagnottes: (page = 1, status = 1) => `${baseURL}/cagnottes?page=${page}&valide=1&statut=${status}`,
    urlGetFourCagnottes: (page = 1, status = 1) => `${baseURL}/cagnottes?page=${page}&statut=${status}&itemsPerPage=4`,
    urlGetAllCagnottes: () => `${baseURL}/cagnottes`,
    urlSearchCagnottes: (params) => `${baseURL}/cagnottes${params}`,
    urlGetThemes: () => `${baseURL}/themes`,
    urlGetVilles: () => `${baseURL}/villes`,
    urlGetVilleById: (id) => `${baseURL}/ville/${id}`,
    urlGetContributeurByEmail: (email) => `${baseURL}/contributeurs?email=${email}`,
    urlGetContributeurByLogin: (login) => `${baseURL}/contributeurs?login=${login}`,
    urlGetAdministrateurByEmail: (email) => `${baseURL}/administrateurs?email=${email}`,
    urlGetAdministrateurByLogin: (login) => `${baseURL}/administrateurs?login=${login}`,
    urlSearchAdminByVille: (params) => `${baseURL}/administrateurs?rVille=${params}`,
    urlGetCagnotteBySlug: (slug) => `${baseURL}/slug/show?slug=${slug}`,
    urlContributeurConfirmResetPassword: () => `${baseURL}/contributeur/confirm/password_reset`,
    urlgetContributeurById: (id) => `${baseURL}/contributeur/${id}`,
    urlgetAdministrateurById: (id) => `${baseURL}/administrateur/${id}`,
    urlGetCagnottesByAmbassadeur: (page = 1, status = 1, ambassadeur_id) => `${baseURL}/cagnottes?ambassadeur=${ambassadeur_id}&page=${page}&statut=${status}`,
    urlGetAllCagnottesByAmbassadeur: (ambassadeur_id) => `${baseURL}/cagnottes?ambassadeur=${ambassadeur_id}`,
    urlGetContributionsByAmbassadeur: (ambassadeur_id) => `${baseURL}/contributions?ambassadeur=${ambassadeur_id}`,
    urlGetFeedbacksCagnotteByAmbassadeur: (ambassadeur_id) => `${baseURL}/feedbacks?idAmbassadeur=${ambassadeur_id}`,
    urlUpdateAccountContributeur: () => `${baseURL}/contributeur/account/update`,
    urlUpdateAccountAdministrateur: () => `${baseURL}/administrateur/account/update`,
    urlGetContributionsByContributeur: (contributeur_id) => `${baseURL}/contributions?contributeur=${contributeur_id}`,
    urlPostForgotPassword: () => `${baseURL}/contributeur/password_forgot`,
    urlGetContributeurByCagnotte: (cagnotte_id) => `${baseURL}/contributions?idCagnotte=${cagnotte_id}`,
    urlgetFeedbackById: (feedback_id) => `${baseURL}/feedback/${feedback_id}`,
    urlgetContributionById: (contribution_id) => `${baseURL}/contribution/${contribution_id}`,
    urlGetCagnotteById: (cagnotte_id) => `${baseURL}/cagnotte/${cagnotte_id}`,
    urlGetAdministrateurs: () => `${baseURL}/administrateurs`,
    urlGetAmbassadeurById: (ambassadeur_id) => `${baseURL}/administrateur/${ambassadeur_id}`,
    urlGetFeedbacksCagnotteByContribution: (contribution_id) => `${baseURL}/feedbacks?contribution=${contribution_id}`,
    urlGetDisponibiliteByAmbassadeur: (id_ambassadeur) => `${baseURL}/t_disponibilite_ambassadeurs?ambassadeur=${id_ambassadeur}&order[id]=desc`,
    urlPostDisponibiliteAmbassadeur: () => `${baseURL}/t_disponibilite_ambassadeurs`,
    urlDeleteDisponibiliteAmbassadeur: (id_ambassadeur) => `${baseURL}/t_disponibilite_ambassadeurs/${id_ambassadeur}`,
    urlGetDonataire: () => `${baseURL}/donataires`,
    urlGetDonataireByAmbassadeur: (ambassadeur_id) => `${baseURL}/donataires?ambassadeur=${ambassadeur_id}&statut=0`,
    urlPostDonataire: () => `${baseURL}/donataire`,
    urlgetDonataireById: (donataire_id) => `${baseURL}/donataire/${donataire_id}`,
    urlgetStatistiques: () => `${baseURL}/statistiques`,
    urlDownloadCoupon: (id_contribution) => `${baseURL}/coupon/${id_contribution}`,
    urlgetFeedbackAccueil: (valide=1)=> `${baseURL}/feedbacks?valide=${valide}&itemsPerPage=9`,
    urlPostActivationCompteContributeur: () => `${baseURL}/contributeur/compte/activation`,
    urlPostVerificationCompte: () => `${baseURL}/contributeur/compte/verification`,
    urlPostParticipationAmbassadeurCagnotte: () => `${baseURL}/t_participation_ambassadeur_cagnotte`,
    urlGetParticipationAmbassadeurCagnotte: (cagnotte_id) => `${baseURL}/t_participation_ambassadeurs_cagnottes?cagnotte=${cagnotte_id}`,
    urlGetParticipationByAmbassadeurAndCagnotte: (ambassadeur_id, cagnotte_id) => `${baseURL}/t_participation_ambassadeurs_cagnottes?ambassadeur=${ambassadeur_id}&cagnotte=${cagnotte_id}`,
    urlGetParticipationCagnotteByAmbassadeur: (ambassadeur_id) => `${baseURL}/t_participation_ambassadeurs_cagnottes?ambassadeur=/api/administrateur/${ambassadeur_id}`,
    urlGetParticipationAmbassadeurCagnotteByAmbassadeur: (ambassadeur_id) => `${baseURL}/t_participation_ambassadeurs_cagnottes?idAmbassadeur=${ambassadeur_id}`,
    urlGetDemandesEnCours: () => `${baseURL}/donataires?statut=0`,
    urlGetDemandesAComplete: () => `${baseURL}/donataires?statut=1`,
    urlPutDemandeChange: (donataire_id) => `${baseURL}/donataire/${donataire_id}`,
    urlGetDemandeDetails: (donataire_id) => `${baseURL}/donataire/${donataire_id}`,
    urlGetAdministrateursByRoles: () => `${baseURL}/administrateurs?roleAmbassadeur`,
    urlPostCreateDemande: () => `${baseURL}/creation/demande`,
    urlPostValidationDemande: () => `${baseURL}/validation/demande`,
};

export default endpoints;