import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import postCreateDemande from "../api/queries/postCreateDemande";

const daribMontantCoupons= 250;
const daribNbCoupons= 0;
const daribNbTotalCoupons= 1000;

function EditionDonataireForm(props) {

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const [succesMsg, setSuccesMsg] = useState('');
    const [donataireNom, setDonataireNom] = useState('');
    const [donatairePrenom, setDonatairePrenom] = useState('');
    const [donataireTelephone, setDonataireTelephone] = useState('');
    const [donataireNombrePersonnes, setDonataireNombrePersonnes] = useState('');
    const [donataireId, setDonataireId] = useState(0);
    const [errMsg, setErrMsg] = useState('');
    const [donataireNomConjoint, setDonataireNomConjoint] = useState('');
    const [donatairePrenomConjoint, setDonatairePrenomConjoint] = useState('');
    const [donataireNombreEnfants, setDonataireNombreEnfants] = useState('');
    const [donataireStatutMatrimonialConjointe, setDonataireStatutMatrimonialConjointe] = useState('');
    const [donataireNotaire, setDonataireNotaire] = useState('');
    const [donataireEmplacement, setDonataireEmplacement] = useState('');
    const [showConjoint, setShowConjoint] = useState(false);
    const [donataireCagnotteTitre, setDonataireCagnotteTitre] = useState('');
    const [donataireCagnotteDescription, setDonataireCagnotteDescription] = useState('');

    const handleDonataireNomConjoint = (e) => {
        setDonataireNomConjoint(e.target.value);
    }

    const handleDonatairePrenomConjoint = (e) => {
        setDonatairePrenomConjoint(e.target.value);
    }

    const handleSituationMatrimonialeConjointe = (e) => {
        if (e.target.value === "marie") {
            setShowConjoint(true);
        } else {
            setShowConjoint(false);
        }
        setDonataireStatutMatrimonialConjointe(e.target.value);
    }

    const handleDonataireNombreEnfants = (e) => {
        setDonataireNombreEnfants(e.target.value);
    }

    const handleDonataireNom = (e) => {
        setDonataireNom(e.target.value);
    }

    const handleDonatairePrenom = (e) => {
        setDonatairePrenom(e.target.value);
    }

    const handleDonataireTelephone = (e) => {
        setDonataireTelephone(e.target.value);
    }

    const handleDonataireNombrePersonnes = (e) => {
        setDonataireNombrePersonnes(e.target.value);
    }

    const processSituationMatrimonialConjointe = (donataireStatutMatrimonialConjointeParam) => {

        switch (donataireStatutMatrimonialConjointeParam) {
            case 'marie':
                return (
                    <>
                        <option value="">Sélectionner une situation matrimoniale</option>
                        <option selected value={donataireStatutMatrimonialConjointeParam}>Marié(e)</option>
                        <option value="divorce">Divorcé(e)</option>
                        <option value="veuve">Veuf(ve)</option>
                        <option value="celibataire">Célibataire</option>
                    </>
                )
            case 'divorce':
                return (
                    <>
                        <option value="">Sélectionner une situation matrimoniale</option>
                        <option value="marie">Marié(e)</option>
                        <option selected value={donataireStatutMatrimonialConjointeParam}>Divorcé(e)</option>
                        <option value="veuve">Veuf(ve)</option>
                        <option value="celibataire">Célibataire</option>
                    </>
                )
            case 'veuve':
                return (
                    <>
                        <option value="">Sélectionner une situation matrimoniale</option>
                        <option value="marie">Marié(e)</option>
                        <option value="divorce">Divorcé(e)</option>
                        <option selected value={donataireStatutMatrimonialConjointeParam}>Veuf(ve)</option>
                        <option value="celibataire">Célibataire</option>
                    </>
                )
            case 'celibataire':
                return (
                    <>
                        <option value="">Sélectionner une situation matrimoniale</option>
                        <option value="marie">Marié(e)</option>
                        <option value="divorce">Divorcé(e)</option>
                        <option value="veuve">Veuf(ve)</option>
                        <option selected value={donataireStatutMatrimonialConjointeParam}>Célibataire</option>
                    </>
                )
            default:
                return (
                    <>
                        <option value="">Sélectionner une situation matrimoniale</option>
                        <option value="marie">Marié(e)</option>
                        <option value="divorce">Divorcé(e)</option>
                        <option value="veuve">Veuf(ve)</option>
                        <option value="celibataire">Célibataire</option>
                    </>
                )
        }
    }

    // const handleDonataireDescription = (e) => {
    //     setDonataireDescription(e.target.value);
    // }

    const handleDonataireEmplacement = (e) => {
        setDonataireEmplacement(e.target.value);
    }

    const handleDonataireNotaire = (e) => {
        setDonataireNotaire(e.target.value);
    }

    const handleDonataireCagnotteTitre = (e) => {
        setDonataireCagnotteTitre(e.target.value);
    }

    const handleDonataireCagnotteDescription = (e) => {
        setDonataireCagnotteDescription(e.target.value);
    }

    function onGotoListeDonataires() {
        navigate(`/beneficiaires`);
    }

    useEffect(() => {

        if (props.donataireData) {
            setDonataireId(props.donataireData?.id);
            setDonataireNom(props.donataireData?.nom);
            setDonatairePrenom(props.donataireData?.prenom);
            setDonataireTelephone(props.donataireData?.telephone);
            setDonataireNombrePersonnes(props.donataireData?.nombrePersonnes);
            // setDonataireDescription(props.donataireData.description);
            setDonataireNomConjoint(props.donataireData?.nomConjoint);
            setDonatairePrenomConjoint(props.donataireData?.prenomConjoint);
            setDonataireNombreEnfants(props.donataireData?.nombreEnfants);
            setDonataireStatutMatrimonialConjointe(props.donataireData?.situationMatrimonialConjointe);
            setDonataireNotaire(props.donataireData?.notaire);
            setDonataireEmplacement(props.donataireData?.emplacement);
            setDonataireCagnotteTitre(props.donataireData?.cagnotte?.titre);
            setDonataireCagnotteDescription(props.donataireData?.cagnotte?.description);

            // Si la conjointe n'est pas mariée, on cache les champs de saisie des noms et prénoms de son conjoint
            props.donataireData.situationMatrimonialConjointe === 'marie' ? setShowConjoint(true) : setShowConjoint(false);
        }

    }, [props.donataireData]);

    const onSubmit = async (e) => {

        e.preventDefault();

        try {
            let params = {
                nom: donataireNom,
                prenom: donatairePrenom,
                telephone: donataireTelephone,
                nombrepersonne: parseInt(donataireNombrePersonnes),
                // description: donataireDescription,
                nomconjoint: showConjoint === true && donataireNomConjoint ? donataireNomConjoint : null,
                prenomconjoint: showConjoint === true && donatairePrenomConjoint ? donatairePrenomConjoint : null,
                nombreenfant: parseInt(donataireNombreEnfants),
                situationmatrimonialconjointe: donataireStatutMatrimonialConjointe,
                notaire: donataireNotaire,
                emplacement: donataireEmplacement,
                titre: donataireCagnotteTitre,
                description: donataireCagnotteDescription
            }

            let ambassadeur_id = JSON.parse(sessionStorage.getItem('user_data'))?.id;
            let datas = {
                ...params,
                donataire_id: donataireId,
                ambassadeur_id: ambassadeur_id,
                nombreCoupons: daribNbCoupons,
                montantCoupon: daribMontantCoupons,
                nombreTotalCoupons: daribNbTotalCoupons
            }

            let resp = await postCreateDemande(datas, axiosPrivate);
            if (resp?.status === 200) {
                setSuccesMsg('Bénéficiaire modifié avec succès!');
            }

        } catch (err) {
            setErrMsg('Une erreur est survenue lors de la modification des données du bénéficiaire');
        }
    }

    return (
        <div className="col-md-8 d-flex mx-auto" style={{"background": "white"}}>
            <div className="align-self-center w-100">
                <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <div
                    className={succesMsg ? "alert alert-success alert-dismissable" : "offscreen"}>
                    {succesMsg}
                </div>
                <form className="give-form" onSubmit={onSubmit}>
                    <fieldset>
                        <legend>Informations du bénéficiaire</legend>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="exampleFormControlInput1">Nom<span
                                    className="give-required-indicator"> *</span>
                                </label>
                                <input value={donataireNom} onChange={handleDonataireNom} className="form-control"
                                       type="text"/>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="exampleFormControlInput1">Prénom<span
                                    className="give-required-indicator"> *</span>
                                </label>
                                <input value={donatairePrenom} onChange={handleDonatairePrenom} className="form-control"
                                       type="text"/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="exampleFormControlInput1">Téléphone
                                </label>
                                <input value={donataireTelephone} onChange={handleDonataireTelephone}
                                       className="form-control" type="text"/>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="exampleFormControlInput1"> Nombre de personnes
                                    <span className="give-required-indicator"> *</span>
                                </label>
                                <input type="number" className="form-control"
                                       value={donataireNombrePersonnes}
                                       onChange={handleDonataireNombrePersonnes}
                                       min="1"/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="exampleFormControlInput1">Situation matrimoniale
                                </label>
                                <select
                                    style={{margin: "0px"}}
                                    onChange={(e) => handleSituationMatrimonialeConjointe(e)}
                                >
                                    {
                                        processSituationMatrimonialConjointe(donataireStatutMatrimonialConjointe)
                                    }
                                </select>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="exampleFormControlInput1"> Nombre d'enfants
                                    <span className="give-required-indicator"> *</span>
                                </label>
                                <input
                                    value={donataireNombreEnfants}
                                    onChange={handleDonataireNombreEnfants}
                                    type="number" className="form-control"
                                    min="0"/>
                            </div>
                        </div>

                        {showConjoint &&
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label>Nom du conjoint
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={donataireNomConjoint}
                                    onChange={handleDonataireNomConjoint}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label>Prénom du conjoint
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={donatairePrenomConjoint}
                                    onChange={handleDonatairePrenomConjoint}
                                />
                            </div>
                        </div>
                        }

                        {/* <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Description</label>
                        <textarea className="form-control" rows="2" cols="1" value={donataireDescription} onChange={handleDonataireDescription}></textarea>
                    </div> */}

                        <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea2">Nom du notaire</label>
                            <input className="form-control" type="text" value={donataireNotaire}
                                   onChange={handleDonataireNotaire}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea3">L'emplacement de l'appartement</label>
                            <textarea className="form-control" rows="2" cols="1" value={donataireEmplacement}
                                      onChange={handleDonataireEmplacement}></textarea>
                        </div>

                    </fieldset>

                    <fieldset>
                        <legend>Informations sur la cagnotte</legend>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea2">Titre de la cagnotte</label>
                            <input className="form-control" type="text" value={donataireCagnotteTitre}
                                   onChange={handleDonataireCagnotteTitre}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea3">Description de la cagnotte</label>
                            <textarea className="form-control" rows="2" cols="1" value={donataireCagnotteDescription}
                                      onChange={handleDonataireCagnotteDescription}></textarea>
                        </div>
                    </fieldset>


                    {/*<div>*/}
                    {/*    <p style={{color: "crimson", "marginBottom": "5px"}}> Les champs marqués par (*) sont*/}
                    {/*        obligatoires</p>*/}
                    {/*</div>*/}
                    <div className="mt-4">
                        <button
                            onClick={() => onGotoListeDonataires()}
                            type="button"
                            className="give-submit give-btn pull-left valider_contribution_details_for_validation_style"
                        >Retour
                        </button>

                        <input type="submit" value="valider"
                               className="give-submit give-btn pull-right valider_contribution_details_for_validation_style"
                               style={{textTransform: "capitalize"}}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditionDonataireForm;