import React, { useRef, useState, useEffect } from "react";
import {getContributeurByEmail, getVilles, postContributeur, urlGetContributeurByLogin} from "../api/queries";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import validator from 'validator';
import { AsyncPaginate } from 'react-select-async-paginate';
import {Link} from "react-router-dom";
import LoadingSpinner from '../components/LoadingSpinner';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const InscriptionContributeur = (props) => {
    const [loading, setLoading] = useState(false);
    const errRef = useRef();
    const errLoginRef = useRef();
    const nomContributeurRef = useRef();
    const prenomContributeurRef = useRef();
    const emailContributeurRef = useRef();
    const emailRepeatContributeurRef = useRef();
    const telContributeurRef = useRef();
    const [succesMsg, setSuccesMsg] = useState('');
    const passwordRef = useRef();
    const passwordRepeatRef = useRef();

    const [submitSuccess, setSubmitSuccess] = useState(false);

    // Mot de passe du contributeur
    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);
    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    // Nom du contributeur
    const [nomContributeur, setNomContributeur] = useState('');

    // Prénom du contributeur
    const [prenomContributeur, setPrenomContributeur] = useState('');

    // E-mail du contributeur
    const [emailContributeur, setEmailContributeur] = useState('');
    const [emailFocus, setEmailFocus] = useState(false);
    const [matchEmail, setMatchEmail] = useState('');
    const [validMatchEmail, setValidMatchEmail] = useState(false);
    const [matchEmailFocus, setMatchEmailFocus] = useState(false);

    // Téléphone du contributeur
    const [telContributeur, setTelContributeur] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [errMsgLogin, setErrMsgLogin] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [villeDuContributeur, setVilleDuContributeur] = useState(null);

    // Affichage des villes par tranche de 30 dans la liste déroulante
    const villesPerPage = 5;
    const defaultAdditional = {
        page: 1
    };

    const handleEmailContributeur = (e) => {
        setEmailContributeur(e.target.value)
        if (validator.isEmail(e.target.value)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }

    const myCustomStyles = {
        control: styles => ({ ...styles, backgroundColor: '#f9f9f9',padding: '4.9px', borderRadius:'0px' }),
    };

    async function loadVilles(search, page) {

        const listVill = await getVilles();

        const villes = listVill?.["hydra:member"].map((el) => (
            {
                value: el?.id,
                label: el?.nomVille
            }
        ));

        let filteredVilles;
        if (!search) {
            filteredVilles = villes;
        } else {
            const searchLower = search.toLowerCase();

            filteredVilles = villes.filter(({ label }) =>
                label.toLowerCase().includes(searchLower)
            );
        }

        const hasMore = Math.ceil(filteredVilles.length / villesPerPage) > page;

        const slicedVilles = filteredVilles.slice(
            (page - 1) * villesPerPage,
            page * villesPerPage
        );

        return {
            options: slicedVilles,
            hasMore
        };
    }

    const loadPageVilles = async (q, prevOptions, { page }) => {
        const { options, hasMore } = await loadVilles(q, page);

        return {
            options,
            hasMore,
            additional: {
                page: page + 1
            }
        };
    };

    useEffect(() => {
        // Test de conformité du nom de l'utilisateur
        // Test de conformité et de correspondance des mots de passe
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);

        // Test de correspondance des adresses email
        setValidMatchEmail(emailContributeur === matchEmail);
    }, [pwd, matchPwd, emailContributeur, matchEmail])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitSuccess(true);
        // if button enabled with JS hack
        const v2 = PWD_REGEX.test(pwd);
        if (!v2 || emailContributeurRef.current.value !== emailRepeatContributeurRef.current.value || passwordRef.current.value !== passwordRepeatRef.current.value) {
            setErrMsg("Renseigner correctement le formulaire.");
            setSuccesMsg('');
            setSubmitSuccess(false);
            return;
        } else {
            try {

                setLoading(true);
                setErrMsg('');

                let params = {
                    login: emailContributeur,
                    roles:["CONTRIBUTEUR_INSCRIT"],
                    password: pwd,
                    nomContributeur: nomContributeur,
                    prenomContributeur: prenomContributeur,
                    emailContributeur: emailContributeur,
                    telContributeur: telContributeur,
                    paysContributeur : 'Maroc',
                    villeContributeur: villeDuContributeur ? "/api/ville/"+villeDuContributeur.value : null,
                    modeInscription: 'INSCRIT'
                }
    
                // Récupération des données du contributeur à partir de son login
                let resultUser = await urlGetContributeurByLogin(emailContributeur);
    
                // Récupération des données du contributeur à partir de son adresse e-mail
                let result = await getContributeurByEmail(emailContributeur);
                result?.data?.["hydra:member"].length >= 1 ? setErrMsg("Cet email est déjà utilisé") : setErrMsg('');
                if (resultUser?.data?.["hydra:member"].length >= 1) {
                    setErrMsgLogin("Ce Login est déjà utilisé");
                    setLoading(false);
                } else {
                    setErrMsgLogin('');
                }
    
                if (result?.data?.["hydra:member"].length >= 1 || resultUser?.data?.["hydra:member"].length >= 1) {
                    setSuccesMsg('');
                    setPwd('');
                    setMatchPwd('');
                    setMatchEmail('');
                    setNomContributeur('');
                    setPrenomContributeur('');
                    setEmailContributeur('');
                    setTelContributeur('');
                    setSubmitSuccess(false);
                    return;
                }
    
                // Enregistrement du contributeur en BDD
                await postContributeur(params);
                setErrMsg("");
                setSuccesMsg('Contributeur enregitré avec succès! Consulter votre adresse mail pour l\'activation de votre compte ');
                // Activation du bouton de soumission du formulaire
                setSubmitSuccess(false);

                setPwd('');
                setMatchPwd('');
                setMatchEmail('');
                setNomContributeur('');
                setPrenomContributeur('');
                setEmailContributeur('');
                setTelContributeur('');
                setVilleDuContributeur('');
                setLoading(false);

            } catch (err) {
                console.log(err)
                if (!err?.response) {
                    setErrMsg('Aucune réponse du serveur');
                } else if (err.response?.status === 409) {
                    setErrMsg("Nom d'utilisateur déjà existant");
                } else {
                    setErrMsg('Enregistrement échoué')
                }
            }
        }
        
    }

    return (
        <>
            <div id="give-wrap" className="give-wrap" style={{ "position": "relative"}}>
                <div className="give-wrap-inner">
                        <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                            <div className="summary entry-summary">
                                <div id="give-form-62-wrap" className="give-display-onpage">
                                    <p ref={errRef} style={{color: "black"}} className={errMsg ? "alert alert-danger alert-dismissable" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                    <p ref={errLoginRef} style={{color: "black"}} className={errMsgLogin ? "alert alert-danger alert-dismissable" : "offscreen"} aria-live="assertive">{errMsgLogin}</p>
                                    <div
                                        className={succesMsg ? "alert alert-success alert-dismissable" : "offscreen"}>
                                        {succesMsg}
                                    </div>

                                    { loading === false ? (

                                        <form onSubmit={handleSubmit} >
                                            <fieldset>
                                                <legend>Identification du contributeur</legend>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputNom">Nom <span style={{ "color": "#a00"}}> *</span></label>
                                                        <input type="text" ref={nomContributeurRef} className="form-control" id="nomContributeur"
                                                            onChange={(e) => setNomContributeur(e.target.value)}
                                                            value={nomContributeur} required
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputNom">Prénom <span style={{ "color": "#a00"}}> *</span></label>
                                                        <input type="text" ref={prenomContributeurRef} className="form-control" id="prenomContributeur"
                                                            onChange={(e) => setPrenomContributeur(e.target.value)}
                                                            value={prenomContributeur} required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputNom">Email <span style={{ "color": "#a00"}}> *</span></label>
                                                        <input type="text" ref={emailContributeurRef} className="form-control" id="emailContributeur"
                                                            onChange={(e) => handleEmailContributeur(e)} value={emailContributeur} aria-describedby="emailnote"
                                                            onFocus={() => setEmailFocus(true)} onBlur={() => setEmailFocus(false)} required
                                                        />
                                                        <p id="emailnote" className={emailFocus && !validEmail ? "instructions_bis" : "offscreen"}>
                                                            <FontAwesomeIcon icon={faInfoCircle} />&nbsp;<span style={{ fontWeight: 'bold', color: 'red'}}>Entrer un e-mail valide</span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputNom">Confirmer votre Email <span style={{ "color": "#a00"}}> *</span>
                                                            <FontAwesomeIcon icon={faCheck} className={validMatchEmail && matchEmail ? "valid" : "hide"} />
                                                            <FontAwesomeIcon icon={faTimes} className={validMatchEmail || !matchEmail ? "hide" : "invalid"} />
                                                        </label>
                                                        <input
                                                            type="text" className="form-control" ref={emailRepeatContributeurRef}
                                                            onChange={(e) => setMatchEmail(e.target.value)} value={matchEmail}
                                                            required aria-invalid={validMatchEmail ? "false" : "true"} aria-describedby="confirmnote"
                                                            onFocus={() => setMatchEmailFocus(true)} onBlur={() => setMatchEmailFocus(false)}
                                                        />
                                                        <p id="confirmnote" className={matchEmailFocus && !validMatchEmail ? "instructions" : "offscreen"}>
                                                            <FontAwesomeIcon icon={faInfoCircle} /> Doit correspondre au premier champ de saisie de l'adresse e-mail.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputNom">Téléphone</label>
                                                        <input
                                                            type="text"
                                                            ref={telContributeurRef}
                                                            className="form-control"
                                                            id="telContributeur"
                                                            onChange={(e) => setTelContributeur(e.target.value)}
                                                            value={telContributeur}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="give-email"> Ville
                                                        </label>
                                                        <AsyncPaginate
                                                            value={villeDuContributeur}
                                                            loadOptions={loadPageVilles}
                                                            onChange={setVilleDuContributeur}
                                                            placeholder="Sélectionner une ville"
                                                            styles={myCustomStyles}
                                                            additional={defaultAdditional}
                                                        />
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <fieldset>
                                                <legend>Accès contributeur</legend>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="password"> Mot de passe <span style={{ "color": "#a00"}}> *</span>
                                                            <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                                                            <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                                                        </label>
                                                        <input type="password" id="password" onChange={(e) => setPwd(e.target.value)} ref={passwordRef}
                                                            value={pwd} required aria-invalid={validPwd ? "false" : "true"} aria-describedby="pwdnote"
                                                            onFocus={() => setPwdFocus(true)}
                                                            onBlur={() => setPwdFocus(false)}
                                                        />
                                                        <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                                                            <FontAwesomeIcon icon={faInfoCircle} />
                                                            8 à 24 caractères.<br />
                                                            Doit inclure des lettres majuscules et minuscules, un chiffre et un caractère spécial.<br />
                                                            Caractères spéciaux autorisés: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="password"> Confirmer le mot de passe <span style={{ "color": "#a00"}}> *</span>
                                                            <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                                                            <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                                                        </label>
                                                        <input type="password" id="confirm_pwd" onChange={(e) => setMatchPwd(e.target.value)}
                                                            value={matchPwd} ref={passwordRepeatRef} required aria-invalid={validMatch ? "false" : "true"} aria-describedby="confirmnote"
                                                            onFocus={() => setMatchFocus(true)}
                                                            onBlur={() => setMatchFocus(false)}
                                                        />
                                                        <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                                            <FontAwesomeIcon icon={faInfoCircle} />
                                                            Doit correspondre au premier champ de saisie du mot de passe.
                                                        </p>
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <p style={{color: "crimson"}}> Les champs marqués par (*) sont obligatoires</p>
                                            <hr/>

                                            {/* Le bouton retour ne s'affiche que pour le formulaire d'authentification principal */}
                                            { !props.locationPathName &&
                                            <div className="give-submit-button-wrap pull-left">
                                                <Link to="/authentification" className="give-submit valider_contribution_details_for_validation_style">Retour</Link>
                                            </div>
                                            }

                                            <input type="submit" value="valider"
                                                   className="give-submit give-btn pull-right valider_contribution_details_for_validation_style"
                                                   style={{textTransform: "capitalize"}}
                                                   disabled={submitSuccess ? true : false}
                                            />
                                        </form>

                                      ) : <LoadingSpinner />

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default InscriptionContributeur