import { useEffect, useState } from "react";
import {useNavigate, useLocation} from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import LoadingSpinner from '../components/LoadingSpinner';
import { getDemandesEnCours, putChangeDemande, postValidationDemande, updateCagnotte } from '../api/queries';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltUp, faLongArrowAltDown, faTimes, faSearch, faCheck, faCalendarCheck  } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';

const ValidationDemandes = () => {
    let isLogged = JSON.parse(sessionStorage.getItem('isLogged')) ?? null;
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { SearchBar } = Search;
    let [listeDemandes, setListeDemandes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showValide, setShowValide] = useState(false);
    const handleCloseConfirm = () => setShowConfirm(false);
    const handleCloseValide = () => setShowValide(false);
    let [itemSelect, setItemSelect] = useState(null);
    let [msgSuccess, setMsgSucces] = useState(false);

    const [showProgram, setShowProgram] = useState(false);
    const handleCloseProgram = () => setShowProgram(false);
    const handleShowProgram = () => setShowProgram(true);
    const [startDate, setStartDate] = useState(new Date());
    const [idSelect, setIdSelect] = useState();
    registerLocale('fr', fr);
    let [errorDate, setErrorDate] = useState(false);
    let [loadProgram, setLoadProgram] = useState(false);

    const [columns] = useState([{
            dataField: 'ambassadeur.fullName',
            text: 'Ambassadeur'
        },{
            dataField: 'fullNameResponsable',
            text: "Beneficiaire",
            sort: true
        }, {
            dataField: 'nombrePersonnes',
            text: "Nombre de personnes",
            sort: true
        },  {
            dataField: 'nombreEnfants',
            text: "Nombre d'enfants",
            sort: true
        }, {
            dataField: '',
            text: 'Actions',
            formatter: showMenus
        }
    ]);

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            De {from} à {to} sur {size} résultat(s)
        </span>
    );

    const [options] = useState({
        pageStartIndex: 1,
        paginationSize: 5,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: false, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Retour',
        nextPageText: 'Suivant',
        lastPageText: 'Dernier',
        nextPageTitle: 'Première page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Page suivante',
        lastPageTitle: 'Dernière page',
        paginationTotalRenderer: customTotal,
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '30', value: 30
        }, {
            text: '50', value: 50
        }, {
            text: 'Tout', value: listeDemandes?.length
        }]
    });

    const sortFunc = (a, b, order, dataField) => {
        if ('string' == typeof a && 'string' == typeof b) {
            if (order === 'asc') {
                return a.localeCompare(b);
            }

            if (order === 'desc') {
                return b.localeCompare(a);
            }

        } else {
            if (order === 'asc') {
                return a - b;
            }
            return b - a; // desc
        }
    }

    const sortOption = {
        // No need to configure sortFunc per column
        sortFunc: sortFunc,
        // No need to configure sortCaret per column
        sortCaret: (order, column) => {
            if (!order) return (<span> <FontAwesomeIcon icon={faLongArrowAltUp} /><FontAwesomeIcon icon={faLongArrowAltDown} /> </span>);
            else if (order === 'asc') return (<span>&nbsp;<FontAwesomeIcon icon={faLongArrowAltUp} /><font style={{color: "#9C14FC"}}><FontAwesomeIcon icon={faLongArrowAltDown} /></font></span>);
            else if (order === 'desc') return (<span>&nbsp;<font style={{color: "#9C14FC"}}><FontAwesomeIcon icon={faLongArrowAltUp} /></font><FontAwesomeIcon icon={faLongArrowAltDown} /></span>);
            return null;
        }
    };

    const onAnnuleShowModal = (id) => {
        setShowConfirm(true);
        setItemSelect(id);
    }

    const validationDeleteConfirm = async () => {
        let body = { statut: 2 };
        let res = await putChangeDemande(itemSelect, body, axiosPrivate, location, navigate);
        if (res?.status === 200) {
            setMsgSucces(true);
        }
        onCloseModalConfirm();
        initialDatas();
    }

    const onValideShowModal = (id) => {
        setShowValide(true);
        setItemSelect(id);
    }

    const validationDemandeConfirm = async () => {
        let body = { donataire_id: itemSelect };
        let res = await postValidationDemande(body, axiosPrivate);
        if (res?.status === 200) {
            setMsgSucces(true);
        }
        onCloseModalConfirm();
        initialDatas();
    }

    const onCloseModalConfirm = () => {
        setItemSelect(null);
        handleCloseConfirm();
        handleCloseValide();
    }

    const gotoDetails = (id) => {
        navigate(`/contributions/demande/details/${id}`);
    }

    const onClickCagnotteProgrammee = (id) => {
        setIdSelect(id);
        handleShowProgram();
    }

    const createCagnotteProgramme = async () => {

        if(new Date() < new Date(startDate?.toISOString().split('T')[0]) ) {
            setLoadProgram(true);
            setErrorDate(false);
            try {
                let params = {
                    programme: true,
                    datePublication: startDate?.toISOString().split('T')[0]
                }

                let response = await updateCagnotte(idSelect, params);
                if (response?.status === 200) {
                    setMsgSucces(true);
                }

                handleCloseProgram();
                setLoadProgram(false);
            } catch (err) {
                alert("Une erreur est survenue, veuillez réessayer ou contacter l'administrateur");
            }
        } else {
            setErrorDate(true);
        }
    }

    function showMenus(cell, row) {
        return (
            <>
                <button title="Vois plus de details" type="button" onClick={() => gotoDetails(row?.id)} className="btn btn-info btn-sm mr-1">
                    <FontAwesomeIcon icon={faSearch}/>
                </button>
                <button title="Annuler la demande" onClick={() => onAnnuleShowModal(row?.id)} type="button" className="btn btn-danger btn-sm mr-1">
                    <FontAwesomeIcon icon={faTimes}/>
                </button>
                { row?.cagnotte?.programme === false ? (
                    <>
                        <button title="Valider la demande" onClick={() => onValideShowModal(row?.id)} type="button" className="btn btn-success btn-sm mr-1">
                            <FontAwesomeIcon icon={faCheck}/>
                        </button>
                        <button title="Programmée la cagnotte" onClick={() => onClickCagnotteProgrammee(row?.cagnotte?.id)} type="button" className="btn btn-secondary btn-sm mr-1">
                            <FontAwesomeIcon icon={faCalendarCheck}/>
                        </button>
                    </>
                    ) : (
                        <span className="badge badge-primary">Programmée </span>
                    )
                }
                
            </>
        );
    }
    
    const initialDatas = async () => {
        setLoading(true);
        // Si une personne est connecté
        if (isLogged === true) {
            // On récupère le rôle de l'utilisateur actuellement connecté
            let user_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles
            // Si la personne connecté est un ambassadeur
            if (user_role?.includes("ROLE_ADMIN")) {
                // Affichage de la liste des demandes
                const resp = await getDemandesEnCours(axiosPrivate, location, navigate);
                setListeDemandes(resp?.["hydra:member"]);
            }
        }
        setMsgSucces(false);
        setLoading(false);
        handleCloseProgram();
    }

    useEffect(() => {
        initialDatas();
    }, [isLogged, msgSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

    return (

        <>
            <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
                <div className="give-wrap-inner">
                    <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">

                        <div className="summary entry-summary">
                            <div className="card mb-5 custom_card_style_1">
                                <h5 className="card-header text-center custom_card_style_2"> Liste des demandes à valider</h5>
                                <div className="card-body">
                                    <div id="give-form-62-wrap" className="give-display-onpage">

                                            { msgSuccess &&
                                                <div className="alert alert-success alert-dismissable">
                                                    Opération effectuée avec succés.
                                                </div>
                                            }

                                            { loading === false ? (
                                                listeDemandes?.length > 0 ?
                                                    <ToolkitProvider keyField="id" data={listeDemandes} columns={columns} search={{defaultSearch: '', searchFormatted: true}}>
                                                        {
                                                            props => (
                                                                <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                                                    <div style={{paddingRight: "0px"}} className='col-md-4 pull-right'>
                                                                        <SearchBar style={{"borderRadius": "0.5rem"}} placeholder="Rechercher" {...props.searchProps} />
                                                                    </div>

                                                                    <hr/>
                                                                    <BootstrapTable {...props.baseProps} pagination={paginationFactory(options)} sort={ sortOption } classes="fix-responsive-width" />
                                                                </div>
                                                            )
                                                        }
                                                    </ToolkitProvider> : "Vous n'avez aucune demande."
                                                ) : <LoadingSpinner />
                                            }

                                            {/* Modal Refus de demande */}
                                            <Modal show={showConfirm} onHide={onCloseModalConfirm} backdrop="static" keyboard={false}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Réfuser la demande </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    Voulez-vous vraiment annuler la demande ?
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={onCloseModalConfirm}>Annuler </Button>
                                                    <Button onClick={validationDeleteConfirm} style={{"backgroundColor": "#9C14FC"}}>Confirmer</Button>
                                                </Modal.Footer>
                                            </Modal>

                                            {/* Modal Validation de demande */}
                                            <Modal show={showValide} onHide={onCloseModalConfirm} backdrop="static" keyboard={false}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Validation de la demande. </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    Voulez-vous vraiment valider la demande ?
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={onCloseModalConfirm}>Annuler </Button>
                                                    <Button onClick={validationDemandeConfirm} style={{"backgroundColor": "#9C14FC"}}>Confirmer</Button>
                                                </Modal.Footer>
                                            </Modal>

                                            

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal creation de disponibilité */}
            <Modal show={showProgram} onHide={handleCloseProgram} backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Programmer la cagnotte</Modal.Title>
                </Modal.Header>
                
                { loadProgram === false ? (
                        <>
                            <Modal.Body className="text-center">
                                { errorDate &&
                                    <div className="alert alert-danger alert-dismissable">
                                        Date incorrecte.
                                    </div>
                                }
                                Selectionner la date. <br/>
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} locale="fr" dateFormat="Pp" />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button style={{"borderRadius": "0.5rem"}} variant="secondary" onClick={handleCloseProgram}>Fermer </Button>
                                <Button style={{"backgroundColor": "#9C14FC", "borderRadius": "0.5rem"}} onClick={createCagnotteProgramme} >Valider</Button>
                            </Modal.Footer>
                        </>
                    ) : (<LoadingSpinner />)
                }
            </Modal>
        </>
    )

}

export default ValidationDemandes;