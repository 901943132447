import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import DisplayCard from '../components/DisplayCard';
import { getSearchCagnottes, getThemes } from '../api/queries';

const SearchResults = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dataLimit = 6; // nombre d'elements par page
    const pageLimit = 4; // nombre de pagination
    let [listCagnottes, setListCagnottes] = useState([]);
    let [currentPage, setCurrentPage] = useState(1);
    let [listThemes, setListThemes] = useState([]);
    let [dateStart, setDateStart] = useState("");
    let [dateEnd, setDateEnd] = useState("");
    const [nbPages, setNbPages] = useState(1);
    const [isValidForm, setIsValidForm] = useState("");
    const [pageSubmit, setPageSubmit] = useState(false);
    
    const inputDateInf = useRef("");
    const inputDateFin = useRef("");

    // declaration var pour les params url
    const [paramsUrl, setParamsUrl] = useState("");
    const [initParamUrl, setInitParamUrl] = useState("");
    let [themeValue, setThemevalue] = useState("");
    let [statutValue, setStatutValue] = useState("");

    const goToNextPage = () => {
        setParamsUrl(`${initParamUrl}&page=${currentPage+1}`)
        setCurrentPage((currentPage) => currentPage + 1);
    }

    const goToPreviousPage = () => {
        setParamsUrl(`${initParamUrl}&page=${currentPage-1}`)
        setCurrentPage((currentPage) => currentPage - 1) 
    }

    const handleChangeTheme = (e) => { setThemevalue(e.target.value) }
    const handleChangeStatut = (e) => { setStatutValue(e.target.value) }
    const handleChangeDateStart = () => { setDateStart(inputDateInf.current.value) }
    const handleChangeDateEnd = () => { setDateEnd(inputDateFin.current.value) }

    const errorMessageForm = () => {
        return (<div className="alert alert-danger" role="alert"> Veuillez selection un critère de recherche </div>);
    }

    //  afficher le groupe de numéros de page dans la pagination
    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
    }

    const changePage = (event) => {
        const pageNumber = Number(event.target.textContent);
        setParamsUrl(`${initParamUrl}&page=${pageNumber}`);
        setCurrentPage(pageNumber);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setPageSubmit(true)
        setIsValidForm(true); // On verifie si le form est entierement vide
        if (themeValue === "" && statutValue === "" && inputDateInf.current.value === "" && inputDateFin.current.value === "") {
            setIsValidForm(false); return;
        }

        // On genere un nouveau url
        let addUrl = `?idTheme=TID&statut=SID&dateDebut=DD&dateFin=DF&page=1`;
        addUrl = themeValue !== "" ? addUrl.replace('TID', themeValue) : addUrl.replace('idTheme=TID&', '')
        addUrl = statutValue !== "" ? addUrl.replace('SID', statutValue) : addUrl.replace('statut=SID&', '')
        addUrl = dateStart !== "" ? addUrl.replace('DD', dateStart) : addUrl.replace('&dateDebut=DD', '')
        addUrl = dateEnd !== "" ? addUrl.replace('DF', dateEnd) : addUrl.replace('&dateFin=DF', '')

        setParamsUrl(addUrl)
        setInitParamUrl(addUrl.replace('&page=1', ''))
        setCurrentPage(1);
    }

    const renderDisplayComponent = () => {
        return listCagnottes?.map((el, index) => <DisplayCard data={el} key={index} />);
    }

    const renderListThemes = () => {
        return listThemes?.map((el, index) => (<option key={index} value={el.id}>{el.libelle}</option>));
    }

    useEffect(() => {
        const loadDataInitiate = async () => {
            if (location.state === null || location.state === undefined) {
                navigate("/");
            } else {
                if (!pageSubmit) {
                    // initialisation des champs du form depuis les params url
                    setParamsUrl(location.state.url);
                    setInitParamUrl(location.state.url);
                    setThemevalue(location.state.theme);
                    setStatutValue(location.state.statut);
                }
                let lists = await getSearchCagnottes(paramsUrl);
                let listthems = await getThemes();
                setListCagnottes(lists?.["hydra:member"]);
                setNbPages(Math.round(lists?.["hydra:totalItems"]/dataLimit));
                setListThemes(listthems?.["hydra:member"]);
            }
            
        }
        
        loadDataInitiate();
    }, [paramsUrl, currentPage, location.state]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div id="give-wrap" className="give-wrap" style={{ "position": "relative"}}>
                <div className="give-wrap-inner">
                    <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                        <div className="summary entry-summary top-ecart">
                            <div id="give-form-62-wrap" className="give-form-wrap give-display-onpage">
                                <form onSubmit={(e) => handleSubmit(e)} className="give-form">
                                    <div>
                                        <fieldset>
                                            <legend>RECHERCHE RAPIDE</legend>
                                            <p className="form-row form-row-first form-row-responsive">
                                                <label className="give-label" for="give-first"> Thème</label>
                                                <select value={themeValue} onChange={(e) => handleChangeTheme(e)} className="give-input required">
                                                    <option value="">Sélectionner un thème</option>
                                                    {renderListThemes()}
                                                </select>
                                            </p>
                                            <p className="form-row form-row-last form-row-responsive">
                                                <label className="give-label" for="give-last"> Statut de la cagnotte</label>
                                                <select value={statutValue} onChange={(e) => handleChangeStatut(e)} className="give-input required">
                                                    <option value="">Sélectionner le statut</option>
                                                    <option value="0">En cours</option>
                                                    <option value="1">Cloturée</option>
                                                </select>
                                            </p>
                                            <p className="form-row form-row-first form-row-responsive">
                                                <label className="give-label" for="give-last"> Date de début </label>
                                                <input ref={inputDateInf} onChange={handleChangeDateStart} type="date" name="dateDebut" id="dateDebut" defaultValue="" />
                                            </p>
                                            <p className="form-row form-row-last form-row-responsive">
                                                <label className="give-label" for="give-last"> Date de fin </label>
                                                <input ref={inputDateFin} onChange={handleChangeDateEnd} type="date" name="dateFin" id="dateFin" defaultValue="" />
                                            </p>
                                        </fieldset>
                                        <fieldset className="give-donation-submit">
                                            { isValidForm === false ? errorMessageForm() : null }
                                            <div className="give-submit-button-wrap give-clearfix pull-right">
                                                <button type="submit" className="give-submit">Rechercher</button>
                                            </div>
                                        </fieldset>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-ce6aa8f elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default top-ecart"
                data-id="ce6aa8f" data-element_type="section"
                data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">

                <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-869dc3e"
                        data-id="869dc3e" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated margin-30">

                            {/* Cagnottes de recherche */}
                            <section
                                className="elementor-section elementor-inner-section elementor-element elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="09205ac" data-element_type="section">
                                <div className="elementor-container elementor-column-gap-no">
                                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-09f281c"
                                        data-element_type="column"
                                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div
                                            className="elementor-widget-wrap elementor-element-populated">
                                            <div className="elementor-element elementor-element-32c4ddb elementor-align-center elementor-widget__width-initial elementor-widget elementor-widget-heading"
                                                data-id="32c4ddb" data-element_type="widget"
                                                data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                        Résultats de recherche
                                                    </h2>
                                                </div>
                                            </div>

                                            <section
                                                className="elementor-section elementor-inner-section elementor-element elementor-element-448094f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="448094f" data-element_type="section">
                                                <div className="elementor-container-fluid elementor-column-gap-no">
                                                    <div className="wrap">
                                                        <div id="primary" className="content-area">
                                                            <main id="main" className="site-main">
                                                                <div data-elementor-type="wp-post" data-elementor-id="1997" className="elementor elementor-1997"
                                                                    data-elementor-settings="[]">
                                                                    <div className="elementor-inner">
                                                                        <div className="elementor-section-wrap">
                                                                            <div className="elementor-section elementor-top-section elementor-element elementor-element-fe76d22 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                                                data-id="fe76d22" data-element_type="section">
                                                                                <div className="elementor-container elementor-column-gap-no">
                                                                                    <div className="elementor-row">
                                                                                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a476304"
                                                                                            data-id="a476304" data-element_type="column">
                                                                                            <div className="elementor-column-wrap elementor-element-populated">
                                                                                                <div className="elementor-widget-wrap">
                                                                                                    <div className="elementor-element elementor-element-f0782d5 campain-grid-style-1 elementor-widget elementor-widget-opal-give-campain"
                                                                                                        data-id="f0782d5" data-element_type="widget"
                                                                                                        data-widget_type="opal-give-campain.default">
                                                                                                        <div className="elementor-widget-container">
                                                                                                            <div className="give-wrap">
                                                                                                                <div className="give-grid give-grid--3">
                                                                                                                    { renderDisplayComponent() }                                                                                                                
                                                                                                                </div>
                                                                                                                
                                                                                                                <div className="row pull-right">
                                                                                                                    <nav aria-label="Page navigation example">
                                                                                                                        <ul className="pagination">
                                                                                                                            <li className="page-item"> 
                                                                                                                                <button onClick={goToPreviousPage} className="page-link" aria-label="Previous" disabled={currentPage === 1}> 
                                                                                                                                    <span aria-hidden="true">«</span>
                                                                                                                                    <span className="sr-only">Previous</span>
                                                                                                                                </button>
                                                                                                                            </li>
                                                                                                                            {/* show page numbers */}
                                                                                                                            {getPaginationGroup()?.map((item, index) => index+1 <= nbPages ?
                                                                                                                                (
                                                                                                                                    <li key={index} className={`page-item ${currentPage === item ? 'active' : null}`}>
                                                                                                                                        <button onClick={(e) => changePage(e)} className="page-link" href="#">{item}</button>
                                                                                                                                    </li>
                                                                                                                                ) : null
                                                                                                                            )}
                                                                                                                            <li className="page-item">
                                                                                                                                <button onClick={goToNextPage} className="page-link" aria-label="Previous" disabled={currentPage === nbPages}>
                                                                                                                                    <span aria-hidden="true">»</span>
                                                                                                                                    <span className="sr-only">Next</span>
                                                                                                                                </button>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                    </nav>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </main>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SearchResults;