import getContributionsByAmbassadeur from "../api/queries/getContributionsByAmbassadeur";
import paginationFactory from "react-bootstrap-table2-paginator";
import {useNavigate, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import '../Contribution.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLongArrowAltDown, faLongArrowAltUp, faSearch} from '@fortawesome/free-solid-svg-icons';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const ValidationContributions = () => {

    let isLogged = JSON.parse(sessionStorage.getItem('isLogged')) ?? null;
    let [rejetMsgFromParent] = useState(sessionStorage.getItem('rejetMsgFromParent') ?? null);
    let [succesMsgFromParent] = useState(sessionStorage.getItem('succesMsgFromParent') ?? null);
    let [listeContributionsByAmbassadeur, setListeContributionsByAmbassadeur] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();

    const {SearchBar} = Search;
    const navigate = useNavigate();

    const [columns] = useState([{
        dataField: 'cagnotte.titre',
        text: 'LIBELLE CAGNOTTE'
    }, {
        dataField: 'contributeur.nom',
        text: 'CONTRIBUTEUR',
        sort: true
    }, {
        dataField: 'dateContribution',
        text: 'DATE DE CONTRIBUTION',
        // headerFormatter: contributeurNameFormatter,
        formatter: dateFormatter,
        sort: true,
        sortFunc: (a, b, order) => {
            if (order === "asc") {
              return Date.parse(a) - Date.parse(b);
            } else if (order === "desc") {
              return Date.parse(b) - Date.parse(a);
            }
        }
    }, {
        dataField: 'modePaiment',
        text: 'MODE DE PAIEMENT',
        headerFormatter: contributeurNameFormatter,
    }, {
        dataField: "nombreCoupons",
        text: 'NOMBRE DE COUPONS CHOISIS',
        formatter: totalCouponsFormatter,
        sort: true
    },{
        dataField: "cagnotte.progresValide",
        text: 'PROGRESSION DE LA CAGNOTTE (%)',
        sort: true,
        formatter: cagnotteProgresValideFormatter
    }, {
        dataField: 'paymentValide',
        text: 'STATUT DU PAIEMENT',
        formatter: statusFormatter
    }, {
            dataField: '',
            text: 'ACTIONS',
            align: 'center',
            formatter: showMenus
        }
    ]);

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            De {from} à {to} sur {size} résultat(s)
        </span>
    );

    const [options] = useState({
        pageStartIndex: 1,
        paginationSize: 5,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: false, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Retour',
        nextPageText: 'Suivant',
        lastPageText: 'Dernier',
        nextPageTitle: 'Première page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Page suivante',
        lastPageTitle: 'Dernière page',
        paginationTotalRenderer: customTotal,
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '30', value: 30
        }, {
            text: '50', value: 50
        }, {
            text: 'Tout', value: listeContributionsByAmbassadeur?.length
        }] // A numeric array is also available. the purpose of above example is custom the text
    });

    function dateFormatter(cell, row) {
        return (
            <span> {cell} </span>
        );
    }

    function contributeurNameFormatter(column, colIndex) {
        return (
          <strong style={{ "fontSize": "15px" }}>{ column.text }</strong>
        );
      }

    function statusFormatter(cell) {
        if (!cell) {
            return <span className="badge badge-warning">validation en cours</span>;
        } else {

            if (cell === 2) {
                return <span className="badge badge-danger">rejeté</span>;
            }

            return <span className="badge badge-success">validé</span>;
        }
    }

    function totalCouponsFormatter(cell, row) {
        return cell;
    }

    function onGotoContribution(contribution_a_valider_id, cagnotte_slug) {

        // Transmission de données à la page
        let datas = {
            contributionToShow: contribution_a_valider_id,
        }

        navigate(`/contributions/contribution-detail/${cagnotte_slug}`, { state: datas});
    }

    function cagnotteProgresValideFormatter(cell) {

        if (cell === 100) {
            return (
                <>
                    <span className="badge badge-success">{ cell.toFixed(2) }%</span>
                </>
            );
        } else {
            return (
                <>
                    <span className="badge badge-warning">{ cell.toFixed(2) }%</span>
                </>
            );
        }
    }

    function showMenus(cell, row) {
        return (
            <>
                <button title="Détail de la contribution" onClick={() => onGotoContribution(row?.id, row?.cagnotte.slug)} type="button"
                        className="btn btn-info btn-sm">
                    <FontAwesomeIcon icon={faSearch}/>
                </button>
            </>
        );
    }

    const sortFunc = (a, b, order, dataField) => {

        if ('string' == typeof a && 'string' == typeof b) {
            if (order === 'asc') {
                return a.localeCompare(b);
            }

            if (order === 'desc') {
                return b.localeCompare(a);
            }

        } else {
            if (order === 'asc') {
                return a - b;
            }
            return b - a; // desc
        }
    }

    const sortOption = {
        // No need to configure sortFunc per column
        sortFunc: sortFunc,
        // No need to configure sortCaret per column
        sortCaret: (order, column) => {
          if (!order) return (<span> <FontAwesomeIcon icon={faLongArrowAltUp} /><FontAwesomeIcon icon={faLongArrowAltDown} /> </span>);
          else if (order === 'asc') return (<span>&nbsp;<FontAwesomeIcon icon={faLongArrowAltUp} /><font style={{color: "#9C14FC"}}><FontAwesomeIcon icon={faLongArrowAltDown} /></font></span>);
          else if (order === 'desc') return (<span>&nbsp;<font style={{color: "#9C14FC"}}><FontAwesomeIcon icon={faLongArrowAltUp} /></font><FontAwesomeIcon icon={faLongArrowAltDown} /></span>);
          return null;
        }
    };


    useEffect(() => {

        const getListeContributionsByAmbassadeur = async () => {

            if(isLogged === true) {

                // On récupère son rôle
                let user_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles

                // Si la personne connecté est un ambassadeur
                if (user_role?.includes("ROLE_AMBASSADEUR")) {
                    let user_id = JSON.parse(sessionStorage.getItem('user_data'))?.id;
                    // Affichage de la liste des contributions à valider
                    const resp = await getContributionsByAmbassadeur(user_id, axiosPrivate, location, navigate);
                    setListeContributionsByAmbassadeur(resp?.["hydra:member"].reverse());
                }
            }
        }
        getListeContributionsByAmbassadeur();

        // Si le message de sucess de validation de la contribution existe, le supprimé des sessions storage
        if(succesMsgFromParent){
            sessionStorage.removeItem('succesMsgFromParent');
        }

        // Si le message de sucess de suppression de la contribution existe, le supprimé des sessions storage
        if(rejetMsgFromParent){
            sessionStorage.removeItem('rejetMsgFromParent');
        }

    }, [isLogged, axiosPrivate, location, navigate, succesMsgFromParent, rejetMsgFromParent])

    return (
        <div id="content" className="site-content">
            <div className="wrap top-ecart">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <article id="post-1652" className="post-1652 page type-page status-publish hentry">
                                <div className="entry-content">
                                    <div id="give-form-62-wrap" className="give-form-wrap give-display-onpage">
                                        <div
                                            className={succesMsgFromParent ? "alert alert-success alert-dismissable" : "offscreen"}>
                                            {succesMsgFromParent}
                                        </div>
                                        <div
                                            className={rejetMsgFromParent ? "alert alert-danger" : "offscreen"}>
                                            {rejetMsgFromParent}
                                        </div>

                                        <div className="card mb-5 custom_card_style_1">
                                            <h5 className="card-header text-center custom_card_style_2">
                                                Liste des contributions à valider</h5>
                                            <div className="card-body">
                                                {listeContributionsByAmbassadeur?.length > 0 ?
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        data={listeContributionsByAmbassadeur}
                                                        columns={columns}
                                                        search={{defaultSearch: '', searchFormatted: true}}
                                                    >
                                                        {
                                                            props => (
                                                                <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                                                    <div style={{paddingRight: "0px"}} className='col-md-4 pull-right'>
                                                                        <SearchBar style={{"borderRadius": "0.5rem"}} placeholder="Rechercher" {...props.searchProps} />
                                                                    </div>

                                                                    <hr/>
                                                                    <BootstrapTable
                                                                        {...props.baseProps}
                                                                        pagination={paginationFactory(options)}
                                                                        sort={ sortOption }
                                                                        classes="fix-responsive-width"
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </ToolkitProvider> : "Aucune contribution."
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </article>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default ValidationContributions;