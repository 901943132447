import { createStore } from 'redux';
import { combineReducers } from "redux";

export const userConnected = () => ({ type: "userConnected", payload: true });

function setUserConnected(state = false, action) {
    if (action.type === "userConnected") {
        return true;
    }
    return state;
}

const reducer = combineReducers({
    isConnected: setUserConnected
})

export const store = createStore(reducer);

// store.subscribe(() => {
//     console.log("Nouveau state:");
//     console.log(store.getState());
// });