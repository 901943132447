import React, { useEffect, useRef, useState } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import isEmail from 'validator/lib/isEmail';
import {
    getContributeurById,
    getAdministrateurById,
    getVilles,
    updateAccountContributeur,
    updateAccountAdministrateur
} from '../api/queries';

const ContributeurAdminAccount = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let [data, setData] = useState({});
    let [myVille, setMyVille] = useState('');
    const [listVille, setListVille] = useState([]);
    const refInputNom = useRef();
    const refInputPrenom = useRef();
    const refInputLogin = useRef();
    const refInputEmail = useRef();
    const refInputTelephone = useRef();
    const refInputVille = useRef();
    const refInputPwd = useRef();
    const refInputPwd2 = useRef();
    const refInputPwdActuel = useRef();
    let [error, setError] = useState(false);
    let [errorResponse, setErrorResponse] = useState(false);
    let [successResponse, setSuccessResponse] = useState(false);
    let [msgErrorResponse, setMsgErrorResponse] = useState('');
    let [saisiPwd, setSaisiPwd] = useState(false);
    let [identicPassword, setIdenticPassword] = useState(false);

    const loadContributeur = async () => {
        try {

            let response;
            let val = location?.state?.ambassadeur ?? JSON.parse(sessionStorage.getItem('user_data'));
            if (val.roles.includes("ROLE_AMBASSADEUR") || val.roles.includes("ROLE_ADMIN")) {
                response = await getAdministrateurById(val?.id);
                if (response?.status === 200) {
                    setData(response?.data);
                    setMyVille(`/api/ville/${response?.data?.rVille?.id}`);
                } else {
                    navigate("/");
                }
            } else {
                response = await getContributeurById(val?.id);
                if (response?.status === 200) {
                    setData(response?.data);
                    setMyVille(response?.data?.ville);
                } else {
                    navigate("/");
                }
            }
        } catch (erro) {
            navigate("/");
        }
    }

    const loadVilles = async () => {
        let resp = await getVilles();
        setListVille(resp?.["hydra:member"]);
    }

    const renderListVilles = () => {
        return listVille?.map((el, index) => (<option key={index} value={"/api/ville/"+el.id}>{el.nomVille}</option>));
    }

    const changeHandleVille = (e) => {
        setMyVille(e.target.value);
    };

    const onSubmitting = async (e) => {
        e.preventDefault();
        setError(false);
        setSaisiPwd(false);
        setErrorResponse(false);
        setSuccessResponse(false);
        setIdenticPassword(false);
        // verif. password a ete saisi
        if (refInputPwdActuel.current.value === "") { setSaisiPwd(true); return; }

        // verif s'il changement de mot de passe
        if ( (refInputPwd.current.value !== "" || refInputPwd2.current.value !== "") && (refInputPwd.current.value !== refInputPwd2.current.value)) {
            setIdenticPassword(true);
            return;
        }

        if (refInputNom.current.value && refInputPrenom.current.value && refInputLogin.current.value && refInputEmail.current.value && isEmail(refInputEmail.current.value)) {
            
            let user = JSON.parse(sessionStorage.getItem('user_data'));
            const idVille = refInputVille.current.value.split('/')[3];
            let params = {
                identifiant: data?.id ?? user?.id,
                login: refInputLogin.current.value,
                nom: refInputNom.current.value,
                prenom: refInputPrenom.current.value,
                email: refInputEmail.current.value,
                telephone: refInputTelephone.current.value,
                password: refInputPwdActuel.current.value,
                villa: idVille,
                new_password: refInputPwd.current.value
            };

            try {
                let resp = data.roles?.includes("ROLE_AMBASSADEUR") || data.roles.includes("ROLE_ADMIN") ?
                    await updateAccountAdministrateur(params) : await updateAccountContributeur(params);

                if (resp?.data?.error) {
                    setErrorResponse(true);
                    setMsgErrorResponse(resp?.data?.description);
                }
                if (resp?.data?.succes) {
                    setSuccessResponse(true);
                }
            } catch (err) {
                setErrorResponse(true);
                setMsgErrorResponse("Une erreur est intervenue.");
            }

        } else {
            setError(true);
        }

    }

    useEffect(() => {
        const connected = (JSON.parse((sessionStorage.getItem('isLogged') === true)) && sessionStorage.getItem('user_data'));
        if(connected) navigate("/authentification");
        // load data
        loadContributeur();
        loadVilles();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        
        <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
            <div className="give-wrap-inner">
                <div id="give-form-62-content"
                     className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">

                    <div className="summary entry-summary">
                        <div className="card mb-5 custom_card_style_1">
                            <h5 className="card-header text-center custom_card_style_2">
                                {location?.pathname === '/ambassadeur/modification-ambassadeur' ? "Modification ambassadeur":"Mon compte"}
                            </h5>
                            <div className="card-body">
                                <div id="give-form-62-wrap" className="give-display-onpage">
                                    <div id="give-wrap" className="give-wrap" style={{ "position": "relative"}}>
                                        <div className="give-wrap-inner">
                                            <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                                                <div className="summary entry-summary">
                                                    <div id="give-form-62-wrap" className="give-display-onpage">

                                                        {/* error msg response */}
                                                        { errorResponse && <div className="alert alert-danger" role="alert"> { msgErrorResponse } </div> }

                                                        {/* error msg response */}
                                                        { successResponse && <div className="alert alert-success" role="alert"> Modification éffectuée avec succès.</div> }

                                                        { error && 
                                                            <div className="alert alert-danger" role="alert">
                                                                Veuillez remplir tous les champs obligatoires (*)
                                                            </div>
                                                        }

                                                        <form className="give-form">
                                                            <fieldset>
                                                                <legend>Identification { data.roles?.includes("ROLE_AMBASSADEUR") ? "de l'ambassadeur" : "du contributeur" } </legend>
                                                                <div className="form-row">
                                                                    <p className="form-row form-row-first form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Nom <span className="give-required-indicator"> *</span></label>
                                                                        <input ref={refInputNom} type="text" name="nom" id="nom" placeholder="Nom" defaultValue={data?.nom} />
                                                                    </p>
                                                                
                                                                    <p className="form-row form-row-last form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Prénom<span className="give-required-indicator"> *</span></label>
                                                                        <input ref={refInputPrenom} type="text" name="prenom" id="prenom" placeholder="Prenom"  defaultValue={data?.prenom} />
                                                                    </p>
                                                                </div>

                                                                <div className="form-row">
                                                                    <p className="form-row form-row-first form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Email <span className="give-required-indicator"> *</span></label>
                                                                        <input ref={refInputEmail} type="email" name="email" id="email" placeholder="Email" defaultValue={data?.email} />
                                                                    </p>
                                                                    <p className="form-row form-row-last form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Téléphone</label>
                                                                        <input ref={refInputTelephone} type="text" name="telephone" id="telephone" placeholder="Téléphone" defaultValue={data?.telephone} />
                                                                    </p>
                                                                </div>

                                                                <div className="form-row">
                                                                    <p className="form-row form-row-last form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Ville</label>
                                                                        <select ref={refInputVille} value={myVille} onChange={changeHandleVille} id="ville" name="ville" className="give-input required" aria-required="true" aria-describedby="modepaiementnote">
                                                                            <option value="">Sélectionner une ville</option>
                                                                            { renderListVilles() }
                                                                        </select>
                                                                    </p>
                                                                </div>
                                                            </fieldset>

                                                            <fieldset>
                                                                <legend>Accès { data.roles?.includes("ROLE_AMBASSADEUR") ? "ambassadeur" : "contributeur" } </legend>
                                                                <div className="form-row"> 
                                                                    <p className={data.roles?.includes("CONTRIBUTEUR_INSCRIT") ? "hide": "form-row form-row-first form-row-responsive"}>
                                                                        <label className="give-label" htmlFor="give-first"> Identifiant <span className="give-required-indicator"> *</span></label>
                                                                        <input ref={refInputLogin} type="text" name="login" id="login" placeholder="Login" defaultValue={data?.login} />
                                                                    </p>
                                                                    
                                                                    <p className="form-row form-row-last form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Mot de passe actuel <span className="give-required-indicator"> *</span></label>
                                                                        <input ref={refInputPwdActuel} type="password" name="pwdActuel" id="pwdActuel" placeholder="Mot de passe actuel" />
                                                                        { saisiPwd && <p style={{color: "crimson"}}>Ce champs est obligatoire.</p> } 
                                                                    </p>
                                                                
                                                                    <p className="form-row form-row-first form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Nouveau mot de passe </label>
                                                                        <input ref={refInputPwd} type="password" name="pwd" id="pwd" placeholder="Entrer votre mot de passe" />
                                                                    </p>

                                                                    <p className="form-row form-row-last form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Confirmer le nouveau mot de passe</label>
                                                                        <input ref={refInputPwd2} type="password" name="pwd2" id="pwd2" placeholder="Confirmer votre mot de passe" />
                                                                    </p>

                                                                    { identicPassword && <p style={{color: "crimson"}}>Les mots de passe ne sont pas identiques.</p> }
                                                                </div>

                                                                {/* Le bouton retour ne s'affiche que pour le formulaire d'authentification principal */}
                                                                <div className={location?.pathname === '/ambassadeur/modification-ambassadeur' ? "give-submit-button-wrap pull-left":"hide"}
                                                                >
                                                                    <Link to="/gestion-ambassadeur" className="give-submit valider_contribution_details_for_validation_style">Retour</Link>
                                                                </div>
                                                            
                                                                <div className="give-submit-button-wrap give-clearfix pull-right">
                                                                    <button onClick={(e) => onSubmitting(e)} className="give-submit give-btn">Valider</button>
                                                                </div> 
                                                            </fieldset>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
                                
    )
}

export default ContributeurAdminAccount;