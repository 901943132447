import axios from "axios"
import endpoints from '../endpoints';

const getParticipationAmbassadeurCagnotte = async (cagnotte_id) => {
    try {
        const response = await axios ({
            method: 'get',
            url: endpoints.urlGetParticipationAmbassadeurCagnotte(cagnotte_id)
        });
        return response.data;

    } catch (error) {
        console.error(error);
        return false;
    }
}

export default getParticipationAmbassadeurCagnotte;

