import endpoints from '../endpoints';
const controller = new AbortController();

const getContributionsByContributeur = async (contributeur_id, axiosPrivate, location, navigate) => {

    try {
        const response = await axiosPrivate.get(endpoints.urlGetContributionsByContributeur(contributeur_id), {
            signal: controller.signal
        });
        return response.data;
    } catch (err) {
        navigate('/authentification', { state: { from: location }, replace: true });
    }
}

export default getContributionsByContributeur;