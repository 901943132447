import ShareCagnotte from "./ShareCagnotte";
import DedierContribution from "./DedierContribution";
import React, {useState} from "react";
import facebookicon from "../../assets/img/fb-1.svg";
import whatsappicon from "../../assets/img/whatsapp.svg";
import linkedinicon from "../../assets/img/linkedin.svg";
import instagramicon from "../../assets/img/instagram.svg";

const EndItem = (props) => {

    const [statutFeedback, setStatutFeedback] = useState();
    const [etape, setEtape] = useState(1);

    const submitNextPage = (e) => {
        e.preventDefault();
        setEtape(etape + 1);
    }

    const renderFormItem = (etapeParam) => {
        switch (etapeParam) {
            case 1:
                return <DedierContribution
                    submitNext={submitNextPage}
                    setStatutFeedback={setStatutFeedback}
                    submitPrevious={props.submitPrevious}
                    submitContribution={props.submitContribution}
                    cagnotteCurrent={props.cagnotteCurrent}
                    contributionConfig={props.contributionConfig}
                />
            case 2:
                return <ShareCagnotte
                    facebookicon={facebookicon}
                    whatsappicon={whatsappicon}
                    linkedinicon={linkedinicon}
                    instagramicon={instagramicon}
                    cagnotteActuelle={props.cagnotteActuelle}
                    statutFeedback={statutFeedback}
                />
            default:
                return <DedierContribution/>
        }
    }

    return (
        <>
        {renderFormItem(etape)}
        </>
    )   
}

export default EndItem;