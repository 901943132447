import React, {useState, useEffect} from 'react'
import './FeedbackAccueil.css'
import setBodyColor from "../../setBodyColor"

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import getFeedbackAccueil from '../../api/queries/getFeedbackAccueil';

const FeedbackAccueil = () => {
    setBodyColor({color: "#f7f8f9"})
    let [liste, setListe] = useState([]);
    let [show, setShow] = useState(false);
    const [options] = useState({
        loop: true,
        marginLeft: 15,
        items: 3,
        // autoplay: true,
        center: true,
        responsiveClass: true,
        responsive:{
            0:{
                items:1,
            },
            600:{
                items:2
            },
            1000:{
                items:3
            }
        }
    });

    const showing = () => {
     
            return liste?.map((el, index) => (
                <div key={index} className="owl-item item active">
                    <div className="elementor-testimonial-item">
                        <div className="item-box" style={{ height:"350px"}}>
                            { /*<div className="elementor-testimonial-image">
                                <div className="image-inner">
                                    <img width="98" height="98"
                                        src="http://n3ichob7alnass.intelcia-solutions.com/wp-content/uploads/2021/01/testimonail.jpg"
                                        className="attachment-full size-full" alt="" loading="lazy" />
                                </div>
                            </div>*/}
                            <div className="elementor-testimonial-content text-center" style={{color:"#000"}}>
                                { el.message } 
                            </div>
                            <div className="elementor-testimonial-meta elementor-has-image">
                                <div className="elementor-testimonial-meta-inner">
                                    <div className="elementor-testimonial-details">
                                        <span className="elementor-testimonial-name">{el.contributeur.nom}</span>
                                        <span className="elementor-testimonial-job">{el.contributeur.prenom}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>));
    }

    useEffect(() => {
        const getListe = async () => {
            let resp = await getFeedbackAccueil(1);
            setListe(resp["hydra:member"]);
        }

        getListe();
    }, [show]);
    
    useEffect(() => {   
        if (liste.length) {
            setShow(true);
        }
    },[liste, show]);

    return (
        <div className="elementor-container elementor-column-gap-no">
            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6d4b821a"
                data-id="6d4b821a" data-element_type="column">
                <div className="elementor-widget-wrap elementor-element-populated" style={{ marginTop: "45px"}}>
                    <div className="elementor-element elementor-element-7f3f0568 elementor-widget__width-initial elementor-widget-mobile__width-initial elementor-drop-cap-yes elementor-drop-cap-view-default elementor-widget elementor-widget-text-editor"
                        data-id="7f3f0568" data-element_type="widget" data-settings="{&quot;drop_cap&quot;:&quot;yes&quot;}"
                        data-widget_type="text-editor.default">
                        <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix"></div>
                        </div>
                    </div>

                    { show && 
                        <OwlCarousel className="owl-theme elementor-element elementor-element-2145cdb testimonial-custom elementor-widget__width-inherit elementor-widget-tablet__width-inherit e-transform e-transform e-transform elementor-testimonial-text-align-center elementor-widget elementor-widget-opal-testimonials 
                            elementor-widget-container elementor-testimonial-wrapper layout_2 " {...options}>
                            <div className="owl-stage-outer">
                                <div className="owl-stage" style={{transform: "translate3d(-1405px, 0px, 0px)", transition: "all 0.25s ease 0s", width: "3162px"}}>
                                    { showing() }
                                </div>
                            </div>
                           
                        </OwlCarousel>
                    }

                </div>
            </div>
        </div>
    )
}

export default FeedbackAccueil;
