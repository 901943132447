import { Outlet } from "react-router-dom";

import Menu from '../components/Menu';
import Footer from '../components/Footer';
import {useEffect, useState} from "react";
import CharteAmbassadeur from "../components/CharteAmbassadeur";

const Parent = () => {
    let [connected, setConnected] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        if (queryParams.get('error')) {
            const errorMessage =
                queryParams.get('error_description') || 'Échec de la connexion. Veuillez réessayer.';
            window.opener &&
            window.opener.postMessage(
                {
                    error: queryParams.get('error'),
                    state: queryParams.get('state'),
                    errorMessage,
                    from: 'Linked In',
                },
                window.location.origin,
            );
            // Close tab if user cancelled login
            if (queryParams.get('error') === 'user_cancelled_login') {
                window.close();
            }
        }
        if (queryParams.get('code')) {
            window.opener &&
            window.opener.postMessage(
                { code: queryParams.get('code'), state: queryParams.get("state"), from: 'Linked In' },
                window.location.origin,
            );
        }

        const handleShowingCharteAmbassadeur = () => {

            // Récupération du role et de l'id de l'utilisateur connecté
            let user_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles;

            if (user_role?.includes("ROLE_AMBASSADEUR")) {
                try {
                    let user_firstConnection = JSON.parse(sessionStorage.getItem('user_data'))?.firstConnection

                    if(user_firstConnection && !show){
                        setShow(true)
                    }

                } catch (err) {
                    console.log(err)
                }
            }

        }

        handleShowingCharteAmbassadeur();

    }, [connected, show])

    return (

        <div className="opal-wrapper">
            <div id="page" className="site">

                <CharteAmbassadeur show={show} setShow={setShow}/>

                {/* include Menu */}
                <Menu connectedVar={connected} connectedFunc={setConnected} />

                <div className="site-content-contain">
                    <div id="content" className="site-content">
                        <div id="primary" className="content-area">
                            <main id="main" className="site-main">
                                <div data-elementor-type="wp-post" data-elementor-id="1608" className="elementor elementor-1608"
                                    data-elementor-settings="[]">
                                    <div className="elementor-section-wrap">

                                        {/* rendue enfant */}
                                        <Outlet context={[connected, setConnected]}  />

                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>

                {/* include Footer */}
                <Footer />

            </div>
        </div>

    )
}

export default Parent;