import { useNavigate } from "react-router-dom";
import React from "react";

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
            <div className="give-wrap-inner">
                <div id="give-form-62-content"
                     className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                    <div className="summary entry-summary">
                        <div>
                            <h1>Non autorisé</h1>
                            <br />
                            <p>Vous n'avez pas le droit d'accéder à la page demandée.</p>
                            <div className="flexGrow">
                                <button onClick={goBack}>Retour</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Unauthorized
