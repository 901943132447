import React, { useState, useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLongArrowAltDown, faLongArrowAltUp, faSearch, faWindowClose} from '@fortawesome/free-solid-svg-icons'
// import LoadingSpinner from '../components/LoadingSpinner';
import {getAllCagnottes} from '../api/queries';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import updateCagnotte from "../api/queries/updateCagnotte";

const CagnottesAdministrateur = () => {

    const [errMsg, setErrMsg] = useState('');
    const [succesMsg, setSuccesMsg] = useState('');
    const [showConfirmClotureCagnotte, setShowConfirmClotureCagnotte] = useState(false);
    const [cagnotteId, setCagnotteId] = useState(null);
    const handleCloseConfirmClotureCagnotte = () => setShowConfirmClotureCagnotte(false);
    let [idCagnotteCloture, setIdCagnotteCloture] = useState(JSON.parse(sessionStorage.getItem('idCagnotteCloture')) ?? null);
    const { SearchBar } = Search;
    const location = useLocation();
    const navigate = useNavigate();
    // const [loading, setLoading] = useState(false);
    let [listCagnottes, setListCagnottes] = useState([]);
    const [columns] = useState([{
        dataField: 'titre',
        text: 'LIBELLE CAGNOTTE'
        }, {
            dataField: 'datePublication',
            text: 'DATE PUBLICATION',
            // formatter: dateFormatter,
            sort: true,
            sortFunc: (a, b, order) => {
                if (order === "asc") {
                  return Date.parse(a) - Date.parse(b);
                } else if (order === "desc") {
                  return Date.parse(b) - Date.parse(a);
                }
            }
        }, {
            dataField: 'parrainMarraine',
            text: 'PARRAIN/MARRAINE'
        }, {
            dataField: "nombreCoupons",
            text: 'NOMBRE DE COUPONS',
            // formatter: totalCouponsFormatter,
            sort: true
        }, {
            dataField: 'cloture',
            text: 'STATUT DE LA CAGNOTTE',
            formatter: statusFormatter
        }, {
            dataField: '',
            text: 'ACTIONS',
            formatter: showMenus
        }
    ]);

    function statusFormatter(cell, row) {
        if (cell) {
            return <span className="badge badge-success">Clôturée</span>;
        }
        return (
            <button type="button" className="btn btn-light">
                En cours <span className="badge badge-warning">{ row?.progresValide.toFixed(2) }%</span>
            </button>
        )        
    }

    function showMenus(cell, row) {      
        return (
            <>
                <button title="Aller à la cagnotte" style={{"borderRadius": "0.5rem"}} onClick={() => onGotoContribution(row.id, row.slug)}
                        type="button" className="btn btn-info btn-sm">
                    <FontAwesomeIcon icon={faSearch  } />
                </button>
                &nbsp;
                <button
                    title="Clôturer la cagnotte"
                    style={{"borderRadius": "0.5rem"}}
                    onClick={() => onAnnuleShowModal(row.id)}
                    type="button"
                    className={!row.cloture ? 'btn btn-danger btn-sm' : 'hide'}
                >
                    <FontAwesomeIcon icon={faWindowClose}/>
                </button>
            </>
        );
    }

    const onCloseModalConfirmClotureCagnotte = () => {
        setCagnotteId(null);
        handleCloseConfirmClotureCagnotte();
    }

    const onAnnuleShowModal = (id) => {
        setShowConfirmClotureCagnotte(true);
        setCagnotteId(id);
    }

    const cloturerCagnotteConfirm = async () => {

        try {
            let updateStatutCagnotte = {
                cloture: true
            }
            let response = await updateCagnotte(cagnotteId, updateStatutCagnotte);

            if (response?.status === 200) {
                sessionStorage.setItem('idCagnotteCloture', JSON.stringify(cagnotteId));
                setIdCagnotteCloture(cagnotteId);
                setSuccesMsg("La cagnotte a été clôturée avec succès!");
            }

            onCloseModalConfirmClotureCagnotte();

        } catch (err) {
            setErrMsg("Une erreur est survenue lors de la clôture de la cagnotte");
        }
    }

    const sortFunc = (a, b, order, dataField) => {
        if (order === 'asc') {
            return b - a;
        }
        return a - b; // desc
    }

    function onGotoContribution (contribution_a_afficher_id, slug) {
        // Transmission de données à la page
        let datas = {
            contributionToShow: contribution_a_afficher_id,
            urlPath: location.pathname
        }
        navigate(`/contribution-detail/${slug}`, { state: datas});
    }

    const sortOption = {
        // No need to configure sortFunc per column
        sortFunc: sortFunc,
        // No need to configure sortCaret per column
        sortCaret: (order, column) => {
          if (!order) return (<span> <FontAwesomeIcon icon={faLongArrowAltUp} /><FontAwesomeIcon icon={faLongArrowAltDown} /> </span>);
          else if (order === 'asc') return (<span>&nbsp;<FontAwesomeIcon icon={faLongArrowAltUp} /><font style={{color: "#9C14FC"}}><FontAwesomeIcon icon={faLongArrowAltDown} /></font></span>);
          else if (order === 'desc') return (<span>&nbsp;<font style={{color: "#9C14FC"}}><FontAwesomeIcon icon={faLongArrowAltUp} /></font><FontAwesomeIcon icon={faLongArrowAltDown} /></span>);
          return null;
        }
    };

    const initialData = async () => {
        // setLoading(true);
        let cagnottesList = await getAllCagnottes();
        setListCagnottes(cagnottesList["hydra:member"]);
        // setLoading(false);
    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            De {from} à {to} sur {size} résultat(s)
        </span>
    );

    const [options] = useState({
        pageStartIndex: 1,
        paginationSize: 5,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: false, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Retour',
        nextPageText: 'Suivant',
        lastPageText: 'Dernier',
        nextPageTitle: 'Première page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Page suivante',
        lastPageTitle: 'Dernière page',
        paginationTotalRenderer: customTotal,
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '30', value: 30
            }, {
            text: '50', value: 50
            }, {
            text: 'Tout', value: listCagnottes.length
        }] // A numeric array is also available. the purpose of above example is custom the text
    });

    useEffect(() => {
        initialData();
    }, [idCagnotteCloture])

    return (
        <div id="content" className="site-content">
            <div className="wrap top-ecart">
                <div id="primary" className="content-area">

                    {/*{loading === false ? (*/}
                        <div id="give-wrap" className="give-wrap top-ecart" style={{ "position": "relative" }}>
                            <div className="give-wrap-inner">
                                <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                                    <div className="summary entry-summary">
                                        <div className="card mb-5 custom_card_style_1">
                                            <h5 className="card-header text-center custom_card_style_2"> Gestion de cagnottes</h5>
                                            <div className="card-body">
                                                <div id="give-form-62-wrap" className="give-display-onpage">
                                                    <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                                    <div
                                                        className={succesMsg ? "alert alert-success alert-dismissable" : "offscreen"}>
                                                        {succesMsg}
                                                    </div>

                                                    {listCagnottes?.length > 0 ?
                                                        <ToolkitProvider keyField="id" data={listCagnottes} columns={columns} search={{defaultSearch: '', searchFormatted: true}}>
                                                            {
                                                                props => (
                                                                    <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                                                        <div style={{paddingRight: "0px"}} className='col-md-4 pull-right'>
                                                                            <SearchBar style={{"borderRadius": "0.5rem"}} placeholder="Rechercher" {...props.searchProps} />
                                                                        </div>

                                                                        <hr/>
                                                                        <BootstrapTable {...props.baseProps} pagination={paginationFactory(options)} sort={ sortOption } classes="fix-responsive-width" />
                                                                    </div>
                                                                )
                                                            }
                                                        </ToolkitProvider> : "Vous n'avez aucune Cagnotte."
                                                    }

                                                    {/* Modal Cloture de la cagnotte */}
                                                    <Modal show={showConfirmClotureCagnotte} onHide={onCloseModalConfirmClotureCagnotte} backdrop="static" keyboard={false}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Clôturer la cagnotte </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            Voulez-vous vraiment clôturer la cagnotte ?
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={onCloseModalConfirmClotureCagnotte}>Annuler </Button>
                                                            <Button onClick={cloturerCagnotteConfirm} style={{"backgroundColor": "#9C14FC"}}>Confirmer</Button>
                                                        </Modal.Footer>
                                                    </Modal>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/*) : (*/}
                    {/*    <LoadingSpinner />*/}
                    {/*)*/}
                    {/*}*/}
                </div>
            </div>
        </div>
    )

}

export default CagnottesAdministrateur;