import React from "react";
import InscriptionContributeur from "../pages/InscriptionContributeur";

const CreateContributeur = () => {

    return (
        <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
            <div className="give-wrap-inner">
                <div id="give-form-62-content"
                     className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">

                    <div className="summary entry-summary">

                        <div className="card mb-5 custom_card_style_1">
                            <h5 className="card-header text-center custom_card_style_2">
                                Création de compte</h5>
                            <div className="card-body">
                                <div id="give-form-62-wrap" className="give-display-onpage">
                                    <InscriptionContributeur/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateContributeur;