import React, {useEffect, useState} from 'react';
import {CircularProgressbar} from "react-circular-progressbar";
import {Link} from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {
    postParticipationAmbassadeurCagnotte,
    getParticipationByAmbassadeurAndCagnotte
} from "../api/queries";

function ProgressBar(props) {

    const axiosPrivate = useAxiosPrivate();
    const [participationAmbassadeurCagnotteStatut, setParticipationAmbassadeurCagnotteStatut] = useState(true);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    useEffect(() => {

        let isMounted = true;
        const InitiatePage = async () => {
            try {
                // Récupération des données utilisateur depuis les session storage
                let user_data_from_session = sessionStorage.getItem('user_data');

                // Si la variable de session existe est définie, on convertit sa valeur en format json dans le cas contraire c'est undefined
                let user_data_from_session_json = user_data_from_session ? JSON.parse(user_data_from_session) : undefined;

                // Si la variable est définie alors on récupère le rôle de l'utilisateur dans le cas contraire se sera undefined
                let user_role = user_data_from_session_json?.roles;

                if (user_role?.includes("ROLE_AMBASSADEUR")) {
                    let user_id = user_data_from_session_json?.id;

                    if(isMounted && props.cagnotteCurrent.id){
                        let resultParticipationByAmbassadeurAndCagnotte = await getParticipationByAmbassadeurAndCagnotte(user_id, props.cagnotteCurrent.id);
                        if(resultParticipationByAmbassadeurAndCagnotte?.["hydra:member"]?.length >= 1){
                            setParticipationAmbassadeurCagnotteStatut(true);
                        } else {
                            setParticipationAmbassadeurCagnotteStatut(false);
                        }
                    }
                }

            } catch (err) {
                console.error(err)
                if (!err?.response) {
                    props.setErrMsg('Une erreur est survenue');
                } else {
                    props.setErrMsg("Votre participation à la cagnotte à échouée")
                }
            }

        }

        InitiatePage();

        return () => (isMounted = false)

    }, [props.cagnotteCurrent.id, participationAmbassadeurCagnotteStatut]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (e) => {

        e.preventDefault();
        setSubmitSuccess(true);
        try {

            // Récupération des données utilisateur depuis les session storage
            let user_data_from_session = sessionStorage.getItem('user_data');

            // Si la variable de session existe est définie, on convertit sa valeur en format json dans le cas contraire c'est undefined
            let user_data_from_session_json = user_data_from_session ? JSON.parse(user_data_from_session) : undefined;

            // Si la variable est définie alors on récupère le rôle de l'utilisateur dans le cas contraire se sera undefined
            let user_role = user_data_from_session_json?.roles;

            if (user_role?.includes("ROLE_AMBASSADEUR")) {
                let user_id = user_data_from_session_json?.id;

                await postParticipationAmbassadeurCagnotte(user_id, props.cagnotteCurrent.id, axiosPrivate);
                setSubmitSuccess(false);
                props.setSuccesMsg('Votre participation à la cagnotte à été prise en compte');
                setParticipationAmbassadeurCagnotteStatut(true);
            }

        } catch (err) {
            console.error(err)
            if (!err?.response) {
                props.setErrMsg('Une erreur est survenue');
            } else {
                props.setErrMsg("Votre participation à la cagnotte à échouée")
            }
        }
    }

    return (
        <>
            <div id="give-sidebar-left" className="give-sidebar give-single-form-sidebar-left">
                <div className="inner">
                    <div className="give-goal-progress style_block_border">
                        <div className="barometer-wrap barometer-added">
                            <div style={{width: 150, height: 150}}>
                                {
                                    <CircularProgressbar value={parseInt(props.cagnotteCurrent.progresValide)}
                                                         text={`${parseInt(props.cagnotteCurrent.progresValide)}%`}/>
                                }
                            </div>
                            <div className="time-left"><span className="label">Expire dans</span><span
                                className="value">{props.cagnotteCurrent.remainingDaysBeforeFinCollecteCagnotte}</span>
                            </div>
                        </div>
                        <div className="raised">
                            <div className="income"><span className="label">Gain</span><span
                                className="value">{props.cagnotteCurrent.gainValide} DH</span></div>
                            <div className="goal"><span className="label">Objectif</span><span
                                className="value">{props.cagnotteCurrent.objectif} DH</span></div>
                            <div className="donors"><span className="label">Contributeurs</span><span
                                className="value">{props.listContributeurByCagnotte.length}</span></div>
                        </div>
                    </div>
                </div>

                <div className={participationAmbassadeurCagnotteStatut ? "hide" : "give-goal-progress-bis"} style={{marginTop: "20px"}}>
                    <div
                        className="elementor-element elementor-element-5c58d0f elementor-mobile-align-center elementor-button-primary elementor-widget elementor-widget-button"
                        data-id="5c58d0f" data-element_type="widget" data-widget_type="button.default">
                        <div className="elementor-widget-container">
                            <div className="elementor-button-wrapper"
                            >
                                <Link to="#"
                                      className={submitSuccess ? "disabled_link valider_contribution_details_for_validation_style elementor-button-link elementor-button elementor-size-md" :
                                      "valider_contribution_details_for_validation_style elementor-button-link elementor-button elementor-size-md"}
                                      role="button"
                                      onClick={(e) => handleSubmit(e)}
                                      style={{backgroundColor:"#9000ff", color:"#FFF"}}
                                >
                                <span className="elementor-button-content-wrapper">
                                    <span className="elementor-button-text">Participer à cette cagnotte</span>
                                </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProgressBar;