import { axiosPrivate } from "../api/axiosPrivate";
import { useEffect } from "react";
import {useLocation, useNavigate} from "react-router-dom";

const useAxiosPrivate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // Récupération du token depuis les local storage
    let accessToken = JSON.parse(sessionStorage.getItem('token')) ?? null;

    useEffect(() => {

        // Création de la requête d'interception
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    // Ajout du token à la variable "Authorization" du header de la requête
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        // Création de la reponse d'interception
        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if ((error?.response?.status === 403 || error?.response?.status === 401) && !prevRequest?.sent) {
                    console.log('Le token a expiré');
                    prevRequest.sent = true;

                        // Quand le token JWT expire, demander à l'user de se reconnecter
                        sessionStorage.removeItem('token');
                        sessionStorage.setItem('isLogged', false);
                        sessionStorage.removeItem('login');
                        let user_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles
                        sessionStorage.removeItem('user_data');
                        if(user_role?.includes('ROLE_AMBASSADEUR') || user_role?.includes('ROLE_ADMIN')){
                            navigate('/auth', { state: { from: location }, replace: true })
                        } else if(user_role?.includes('CONTRIBUTEUR_INSCRIT')){
                            navigate('/authentification', { state: { from: location }, replace: true })
                        } else {
                            navigate("/");
                        }

                    return axiosPrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [accessToken, navigate, location])

    return axiosPrivate;
}

export default useAxiosPrivate;