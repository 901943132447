import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import Parent from './pages/Parent';
import Login from './pages/Login';
import Cagnottes from './pages/Cagnottes';
import SearchResults from './pages/SearchResults';
import CagnotteEnCours from './pages/CagnotteEnCours';
import CagnotteCloturees from './pages/CagnotteCloturees';
import Contribution from './pages/Contribution';
import InscriptionContributeur from './pages/InscriptionContributeur';
import ContributeurContributionsCagnottes from './pages/ContributeurContributionsCagnottes';
import NotFound from './pages/NotFound';
import ContributeurPasswordReset from './pages/ContributeurPasswordReset';
import ForgotPassword from './pages/ForgotPassword';
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import ContributeurAdminAccount from './pages/ContributeurAdminAccount';
import ContributionDetail from "./pages/ContributionDetail";
import ValidationContributions from "./pages/ValidationContributions";
import ValidationFeedbacks from "./pages/ValidationFeedbacks";
import FeedbackFormValidation from "./components/contribution/FeedbackFormValidation";
import ContributionDetailsForValidation from "./pages/ContributionDetailsForValidation";
import LoginAdministrateur from './pages/LoginAdministrateur';
import AdministrationDisponibilite from './pages/AdministrationDisponibilite';
import Donataire from "./pages/Donataire";
import CreateDonataire from "./components/CreateDonataire";
import UpdateDonataire from "./components/UpdateDonataire";
import DonataireDetail from "./components/contribution/DonataireDetail";
import CreateContributeur from "./components/CreateContributeur";
import Statistiques from "./partiel/Statistiques/Statistiques";
import CagnottesFrame from "./partiel/Cagnottes/CagnottesFrame";
import RequireAuth from './components/RequireAuth';
import Unauthorized from './components/Unauthorized';
import FeedbackAccueil from "./partiel/FeedbackAccueil/FeedbackAccueil";
import ActivationCompteContributeur from './pages/ActivationCompteContributeur';
import CagnotteProgrammee from './pages/CagnotteProgrammee';
import ParticipationAmbassadeurCagnottes from './pages/ParticipationAmbassadeurCagnottes';
import ValidationBeneficiaire from "./pages/ValidationBeneficiaire";
import GestionAmbassadeur from "./pages/GestionAmbassadeur";
import CreateAmbassadeur from "./components/CreateAmbassadeur";
import CagnottesAdministrateur from './pages/CagnottesAdministrateur';
import ValidationDemandes from './pages/ValidationDemandes';
import DemandeDetails from './pages/DemandeDetails';
import CompleteDemandes from "./pages/CompleteDemandes";
import CreateCagnotte from "./pages/CreateCagnotte";
import AdministrateurAccount from "./pages/AdministrateurAccount";

const ROLES = {
  'User': 'ROLE_USER',
  'Contributeur': 'CONTRIBUTEUR_INSCRIT',
  'Ambassadeur': 'ROLE_AMBASSADEUR',
  'Admin': 'ROLE_ADMIN'
}

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Parent />}>

            {/* Routes publiques */}
            <Route path="/" element={<Cagnottes />} />
            <Route path="/auth" element={<LoginAdministrateur />} />
            <Route path="/inscription-contributeur" element={<InscriptionContributeur />} />
            <Route path="/contributeur/creation-contributeur" element={<CreateContributeur />} />
            <Route path="/linkedin" component={LinkedInCallback} />
            <Route path="/cagnottes-en-cours" element={<CagnotteEnCours />} />
            <Route path="/cagnottes-cloturees" element={<CagnotteCloturees />} />
            <Route path="/cagnottes-programmees" element={<CagnotteProgrammee />} />
            <Route path="/resultats-de-recherche" element={<SearchResults />} />
            <Route path="/contribution/:slug" element={<Contribution />} />
            <Route path="/contribution-detail/:slug" element={<ContributionDetail />} />
            <Route path="/authentification" element={<Login />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/reset/password/:key" element={<ContributeurPasswordReset />} />
            <Route path="/forgot/password" element={<ForgotPassword />} />
            <Route path="/mot-de-passe/oublie" element={<ForgotPassword />} />
            <Route path="/compte/activation/:key_confirmation" element={<ActivationCompteContributeur />} />
            <Route path="/beneficiaire/validation-beneficiaire" element={<ValidationBeneficiaire />} />

            {/* Routes protégées */}

            {/* Routes accessibles à tous les utilisateurs authentifiés */}
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="/contributeur/mon-compte" element={<ContributeurAdminAccount />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="/contributions/validation-contributions" element={<ValidationContributions />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="/contributions/contribution-detail/:slug" element={<ContributionDetailsForValidation />} />
              <Route path="/contributions/contribution-a-valider/:slug" element={<ContributionDetailsForValidation />} />
            </Route>

            {/* Routes accessibles à tous les contributeurs uniquement */}
            <Route element={<RequireAuth allowedRoles={[ROLES.Contributeur]} />}>
              <Route path="/mes-contributions-cagnottes" element={<ContributeurContributionsCagnottes />} />
            </Route>

            {/* Routes accessibles aux ambassadeurs et administrateurs uniquement */}
            <Route element={<RequireAuth allowedRoles={[ROLES.Ambassadeur, ROLES.Admin]} />}>
              <Route path="/contributions/validation-feedbacks" element={<ValidationFeedbacks />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Ambassadeur, ROLES.Admin]} />}>
              <Route path="/ambassadeur/modification-ambassadeur" element={<AdministrateurAccount />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Ambassadeur, ROLES.Admin]} />}>
              <Route path="/administrateur/mon-compte" element={<AdministrateurAccount />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Ambassadeur, ROLES.Admin]} />}>
              <Route path="/contributions/feedbacks-a-valider" element={<FeedbackFormValidation />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="/contributions/demandes-a-valider" element={<ValidationDemandes />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="/contributions/demandes-a-conclure" element={<CompleteDemandes />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="/contributions/creation-de-cagnote" element={<CreateCagnotte />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="/contributions/demande/details/:identifiant" element={<DemandeDetails />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Ambassadeur, ROLES.Admin]} />}>
              <Route path="/beneficiaires" element={<Donataire />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Ambassadeur, ROLES.Admin]} />}>
              <Route path="/beneficiaire/detail" element={<DonataireDetail />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Ambassadeur, ROLES.Admin]} />}>
              <Route path="/beneficiaire/creation-beneficiaire" element={<CreateDonataire />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Ambassadeur, ROLES.Admin]} />}>
              <Route path="/beneficiaire/modification-beneficiaire" element={<UpdateDonataire />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Ambassadeur, ROLES.Admin]} />}>
              <Route path="/administration/disponibilite" element={<AdministrationDisponibilite />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Ambassadeur, ROLES.Admin]} />}>
              <Route path="/administration/contribution/mes-participations" element={<ParticipationAmbassadeurCagnottes />} />
            </Route>

            {/* Routes accessibles aux administrateurs uniquement */}
            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="/cagnottes/administration" element={<CagnottesAdministrateur />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="/gestion-ambassadeur" element={<GestionAmbassadeur />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="/ambassadeur/creation-ambassadeur" element={<CreateAmbassadeur />} />
            </Route>

          </Route>
          <Route path="/statistiques" element={<Statistiques/>}/>
          <Route path="/Cagnottes" element={<CagnottesFrame/>}/>
          <Route path="/feedback-accueil" element={<FeedbackAccueil/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;