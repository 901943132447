import React, {useState, useRef} from "react";
import Modal from "react-bootstrap/Modal";
import {updateAmbassadeur} from '../api/queries';

const CharteAmbassadeur = (props) => {
    const [errMsg, setErrMsg] = useState('');
    const handleClose = () => props.setShow(false);
    const errRef = useRef();
    const showFromParent = props.show

    const handleCharteAmbassadeur = async (e) => {
        e.preventDefault();

        try {
            let params = {
                firstConnection: false
            }

            // On récupère son rôle
            let ambassadeur_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles;
            let ambassadeur_id;

            // Si la personne connecté est un ambassadeur
            if(ambassadeur_role?.includes("ROLE_AMBASSADEUR")){
                ambassadeur_id = JSON.parse(sessionStorage.getItem('user_data'))?.id;
            }

            let updateAmbassadeurResponse = await updateAmbassadeur(ambassadeur_id, params);

            if (updateAmbassadeurResponse?.status === 200) {
                let user_data_ss = JSON.parse(sessionStorage.getItem('user_data'));
                user_data_ss.firstConnection = false;
                sessionStorage.setItem('user_data', JSON.stringify(user_data_ss));
                handleClose();
            }

        } catch (err) {
            console.error(err)
            if (!err?.response) {
                setErrMsg('Une erreur est survenue');
            } else {
                setErrMsg("La validation de la charte à échouée")
            }
        }
    }

    return (
        <>
            <Modal show={showFromParent} onHide={handleClose} dialogClassName="modal-90w"
                   backdrop="static" keyboard={false}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header>
                    <Modal.Title>Charte des ambassadeurs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <div className="form-group">
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority
                            have suffered alteration in some form, by injected humour, or randomised words which
                            don't look even slightly believable. If you are going to use a passage of Lorem Ipsum,
                            you need to be sure there isn't anything embarrassing hidden in the middle of text. </p>
                        <p> All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary,
                            making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words,
                            combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.
                            The generated Lorem Ipsum
                            is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
                    </div>
                    <div className="custom-control">
                        <form onSubmit={handleCharteAmbassadeur}>
                            <div className="mt-5">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    value="charte_ambassadeur_ok"
                                    required/>
                                <label>J'accepte les conditions</label>
                            </div>
                            <hr/>
                            <input type="submit" value="valider"
                                   className="give-submit give-btn pull-right valider_contribution_details_for_validation_style mt-3"
                            />
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CharteAmbassadeur;