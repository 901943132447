import endpoints from '../endpoints';

const postCreateDemande = async (params, axiosPrivate) => {

    try {
        return await axiosPrivate.post(endpoints.urlPostCreateDemande(), params);
    } catch (err) {
        console.error(err);
        return false;
    }
}

export default postCreateDemande;