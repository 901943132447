import React, {useEffect, useState, useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getVilles, getCagnotteBySlug} from "../api/queries";
import FirstItem from '../components/contribution/FirstItem';
import SecondItem from '../components/contribution/SecondItem';
import EndItem from '../components/contribution/EndItem';
import 'react-circular-progressbar/dist/styles.css';
import getContributeurByCagnotte from "../api/queries/getContributeurByCagnotte";
import ProgressBarHorizontal from "../components/ProgressBarHorizontal";


const Contribution = () => {
    const navigate = useNavigate();
    const params = useParams();
    let [cagnotteCurrent, setCagnotteCurrent] = useState({});
    const [etape, setEtape] = useState(1);
    let [listVille, setListVille] = useState([]);
    let [listContributeurByCagnotte, setListContributeurByCagnotte] = useState([]);
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    let [contributionConfig] = useState({
        nbCoupons: 1,
        ambassadeur: "",
        modePaiement: "",
        modeRecuperation: ""
    });

    const renderFormItem = (etapeParam) => {
        switch (etapeParam) {
            case 1:
                return <FirstItem 
                            config={contributionConfig} 
                            submitNext={submitNextPage} 
                            villes={listVille}
                            cagnotteCurrent={cagnotteCurrent}
                        />;
            case 2:
                return  <SecondItem
                            config={contributionConfig} 
                            submitPrevious={submitPreviousPage} 
                            submitNext={submitNextPage} 
                            villes={listVille}
                        />;
            case 3:
            return <EndItem
                contributionConfig={contributionConfig}
                submitPrevious={submitPreviousPage}
                cagnotteCurrent={cagnotteCurrent}
                    />;
            default:
                return <FirstItem/>
        }
    }

    const submitNextPage = (e) => {
        e.preventDefault();
        setEtape(etape + 1);
    }

    const submitPreviousPage = (e) => {
        e.preventDefault();
        setEtape(etape - 1);

        // Réinitialisation du state stockant le message d'erreur
        if (errMsg !== '') {
            setErrMsg('');
        }
    }

    const InitiatePage = async () => {
        if (Object.keys(params).length === 0) {
            navigate("/");
        } else {
            let result = await getCagnotteBySlug(params?.slug);
            result === false ? navigate("/") : setCagnotteCurrent(result?.data);
            // Récupération de l'identifiant de la cagnotte courante
            loadDataContributeurByCagnotte(result?.data?.id);
        }
    }

    // Liste des contributeurs ayant contribué à la cagnotte courante
    const loadDataContributeurByCagnotte = async (cagnotte_id) => {
        let rstContributeurByCagnotte = await getContributeurByCagnotte(cagnotte_id);
        setListContributeurByCagnotte(rstContributeurByCagnotte?.["hydra:member"]);
    }

    useEffect(() => {
        InitiatePage();

        const loadDataInitiate = async () => {
            let listVill = await getVilles();
            setListVille(listVill?.["hydra:member"]);
        }

        loadDataInitiate();
        window.scrollTo(0, 0);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <div className="give-wrap-inner">
                    <div id="give-form-62-content"
                        className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">

                        {/*   Rendre ce composant dynamiquement sans recharger la page */}
                        <div className="summary entry-summary">
                            <div className="card" style={{borderRadius:"40px", "border":"1px #E6D7F1 solid"}}>
                                <h5 className="card-header text-center" style={{borderRadius:"40px 40px 0px 0px"}}>Contribuer à la cagnotte</h5>
                                <div className="card-body">
                                    {/* form contribution */}
                                    <div id="give-form-62-wrap" className="give-display-onpage">
                                        <h2>
                                            {cagnotteCurrent?.titre ?? null}
                                        </h2>
                                        <h3 className="give-card__title style_parrain_marraine">
                                            {'Parrain/Marraine : ' + cagnotteCurrent?.parrainMarraine ?? null}
                                        </h3>
                                        <ProgressBarHorizontal cagnotteCurrent={cagnotteCurrent}
                                                            listContributeurByCagnotte={listContributeurByCagnotte}/>
                                        <form name="give-forrem-62-1" id="give-forrem-62-1"
                                            className="give-form give-form-62 give-form-type-multi">
                                            {renderFormItem(etape)}
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
                                <div className="give-wrap-inner">
                                    <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                                        <div className="summary entry-summary">
                                            <div className="card mb-5 custom_card_style_1">
                                                <h5 className="card-header text-center custom_card_style_2">Description</h5>
                                                <div className="card-body">
                                                    <div id="give-form-62-wrap" className="give-display-onpage">
                                                        <div className="form-row p-2">
                                                            <div id="give-form-content-62" className="give-form-content-wrap give_pre_form-content">
                                                                <p className="contenu-justify m-0">
                                                                    {cagnotteCurrent ? cagnotteCurrent.description : null}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="form-row p-2">
                                                            <div className="col-md-3 form-row form-row-wide">
                                                                <label className="label"> Expire dans :</label>&nbsp;
                                                                <p className="value">{cagnotteCurrent ? cagnotteCurrent.remainingDaysBeforeFinCollecteCagnotte : null}</p>
                                                            </div>

                                                            <div className="col-md-3 form-row form-row-wide income">
                                                                <label className="label"> Gain:</label>&nbsp;
                                                                <p className="value">{cagnotteCurrent ? cagnotteCurrent.gainValide : null} DH</p>
                                                            </div>

                                                            <div className="col-md-3 form-row form-row-wide goal">
                                                                <label className="label"> Objectif :</label>&nbsp;
                                                                <p className="value">{cagnotteCurrent ? cagnotteCurrent.objectif : null} DH</p>
                                                            </div>

                                                            <div className="col-md-3 form-row form-row-wide donors">
                                                                <label className="label"> Contributeurs :</label>&nbsp;
                                                                <p className="value">{listContributeurByCagnotte ? listContributeurByCagnotte.length : 'aucun'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contribution;