import axios from "axios"
import endpoints from '../endpoints';

const updateAccountAdministrateur = async (params) => {
    try {
        return await axios({
            method: 'post',
            url: endpoints.urlUpdateAccountAdministrateur(),
            data: params
        });
    } catch (error) {
        console.error(error);
        return false;
    }
}

export default updateAccountAdministrateur;