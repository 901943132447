import endpoints from '../endpoints';

const postDisponibiliteAmbassadeur = async ({ dateDebut, dateFin, ambassadeur }, axiosPrivate) => {
    try {
        return await axiosPrivate.post(endpoints.urlPostDisponibiliteAmbassadeur(), {
            "dateDebut": dateDebut,
            "dateFin": dateFin,
            "ambassadeur": `/api/administrateur/${ambassadeur}`
        });
    } catch (error) {
        console.error(error);
        return false;
    }
}

export default postDisponibiliteAmbassadeur;