import {useNavigate, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import '../Contribution.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import getDonataireByAmbassadeur from '../api/queries/getDonataireByAmbassadeur'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faUserPlus,
    faUserEdit,
    faSearch,
    faLongArrowAltUp,
    faLongArrowAltDown
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const ValidationBeneficiaire = () => {

    let isLogged = JSON.parse(sessionStorage.getItem('isLogged')) ?? null;
    let [listeDonatairesByAmbassadeur, setListeDonatairesByAmbassadeur] = useState([]);

    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();

    const {SearchBar} = Search;
    const navigate = useNavigate();

    const [columns] = useState([{
        dataField: 'nom',
        text: 'Nom',
        sort: true
    }, {
        dataField: 'prenom',
        text: 'Prénom',
        sort: true
    }
        , {
            dataField: 'telephone',
            text: 'Téléphone',
            sort: true
        },
        {
            dataField: 'nombrePersonnes',
            text: 'Nombre de personnes',
            sort: true
        },
        {
            dataField: 'statutDemandeAjout',
            text: 'Statut de la demande',
            formatter: statusFormatter
        },
        {
            dataField: '',
            text: 'Actions',
            formatter: showMenus
        }
    ]);

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
      De {from} à {to} sur {size} résultat(s)
    </span>
    );

    const sortFunc = (a, b, order, dataField) => {
        
        if ('string' == typeof a && 'string' == typeof b) {
            if (order === 'asc') {
                return a.localeCompare(b);
            }

            if (order === 'desc') {
                return b.localeCompare(a);
            }

        } else {
            if (order === 'asc') {
                return a - b;
            }
            return b - a; // desc
        }
    }

    const sortOption = {
        // No need to configure sortFunc per column
        sortFunc: sortFunc,
        // No need to configure sortCaret per column
        sortCaret: (order, column) => {
            if (!order) return (<span> <FontAwesomeIcon icon={faLongArrowAltUp} /><FontAwesomeIcon icon={faLongArrowAltDown} /> </span>);
            else if (order === 'asc') return (<span>&nbsp;<FontAwesomeIcon icon={faLongArrowAltUp} /><font style={{color: "#9C14FC"}}><FontAwesomeIcon icon={faLongArrowAltDown} /></font></span>);
            else if (order === 'desc') return (<span>&nbsp;<font style={{color: "#9C14FC"}}><FontAwesomeIcon icon={faLongArrowAltUp} /></font><FontAwesomeIcon icon={faLongArrowAltDown} /></span>);
            return null;
        }
    };

    const [options] = useState({
        pageStartIndex: 1,
        paginationSize: 5,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: false, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Retour',
        nextPageText: 'Suivant',
        lastPageText: 'Dernier',
        nextPageTitle: 'Première page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Page suivante',
        lastPageTitle: 'Dernière page',
        paginationTotalRenderer: customTotal,
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '30', value: 30
        }, {
            text: '50', value: 50
        }, {
            text: 'Tout', value: listeDonatairesByAmbassadeur?.length
        }]
    });

    function showMenus(cell, row) {

            return (
                <>
                    <button title="Détail du bénéficiaire" onClick={() => onGotoDetailDonataire(row.id)} type="button"
                            className="btn btn-danger btn-sm mr-1">
                        <FontAwesomeIcon icon={faSearch}/>
                    </button>
                    <Button
                        // className="give-submit give-btn valider_contribution_details_for_validation_style"
                        className={row.statutDemandeAjout === true ? "hide":"give-submit btn-sm"}
                        title="Modifier un bénéficiaire"
                        onClick={() => onGotoEditDonataire(row.id)} type="button"
                    >
                        <FontAwesomeIcon icon={faUserEdit}/>
                    </Button>
                </>
            );
    }

    function statusFormatter(cell) {
        if (!cell) {
            return <span className="badge badge-warning">En cours</span>;
        } else {
            return <span className="badge badge-success">validation effectué</span>;
        }
    }

    function onGotoAddDonataire() {
        navigate(`/beneficiaire/creation-beneficiaire`);
    }

    function onGotoDetailDonataire(donataireId) {
        // Enregistrement de l'id du bénéficiaire courant dans les local storage
        sessionStorage.setItem('donataire_id', JSON.stringify(donataireId));
        navigate(`/beneficiaire/detail`);
    }

    function onGotoEditDonataire(donataireId) {
        // Enregistrement de l'id du bénéficiaire courant dans les local storage
        sessionStorage.setItem('donataire_id', JSON.stringify(donataireId));
        navigate(`/beneficiaire/modification-beneficiaire`);
    }

    const showAucunDonataire = () => {
        return <div className="mt-4"><p>Aucun bénéficiaire disponible</p></div>;
    };

    useEffect(() => {

        const getListeDonataireByAmbassadeur = async () => {

            // Si une personne est connecté
            if (isLogged === true) {

                // On récupère le rôle de l'utilisateur actuellement connecté
                let user_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles

                // Si la personne connecté est un ambassadeur
                if (user_role?.includes("ROLE_AMBASSADEUR")) {
                    let ambassadeur_id = JSON.parse(sessionStorage.getItem('user_data'))?.id;
                    // Affichage de la liste des bénéficiaires par ambassadeur
                    const resp = await getDonataireByAmbassadeur(ambassadeur_id, axiosPrivate, location, navigate);
                    setListeDonatairesByAmbassadeur(resp?.["hydra:member"]);
                }
            }
        }

        getListeDonataireByAmbassadeur();


    }, [isLogged, axiosPrivate, location, navigate])

    return (
        <div id="content" className="site-content">
            <div className="wrap top-ecart">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <article id="post-1652" className="post-1652 page type-page status-publish hentry">
                            <div className="page-inner">
                                <div className="entry-content">
                                    <h2 className="entry-title">Liste des bénéficiaires</h2>
                                    <div id="give-form-62-wrap"
                                         className="give-form-wrap give-display-onpage my_custom_flex_col">
                                        {listeDonatairesByAmbassadeur?.length > 0 ?
                                            <ToolkitProvider
                                                keyField="id"
                                                data={listeDonatairesByAmbassadeur}
                                                columns={columns}
                                                search={{defaultSearch: '', searchFormatted: true}}
                                            >
                                                {
                                                    props => (
                                                        <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                                            <div className='col-md-4 pull-right'>
                                                                <SearchBar
                                                                    placeholder="Rechercher" {...props.searchProps} />
                                                            </div>

                                                            <hr/>
                                                            <BootstrapTable
                                                                {...props.baseProps}
                                                                pagination={paginationFactory(options)}
                                                                sort={ sortOption }
                                                                classes="fix-responsive-width"
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </ToolkitProvider> : showAucunDonataire()
                                        }
                                    </div>
                                    <div>
                                        <Button
                                            className="give-submit give-btn pull-left valider_contribution_details_for_validation_style"
                                            title="Ajouter un nouveau bénéficiaire"
                                            onClick={() => onGotoAddDonataire()} type="button"
                                        >
                                            <span>Ajouter</span>&nbsp;<FontAwesomeIcon icon={faUserPlus}/>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default ValidationBeneficiaire;