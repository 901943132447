import axios from "axios"
import endpoints from '../endpoints';

const getStatistiques = async () => {
    try {
        const response = await axios({
            method: 'get',
            url: endpoints.urlgetStatistiques()
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export default getStatistiques;