import { useEffect, useState } from "react";

import getFourCagnottes from "../../api/queries/getFourCagnottes";
import DisplayCardFrame from "../../components/DisplayCardFrame";
import'./CagnottesFrame.css';

const CagnottesFrame = () => {
    let [liste, setListe] = useState([]);

    const renderDisplayComponent = () => {
        return liste.map((el, index) => <DisplayCardFrame data={el} key={index}  />);
    }

    useEffect(() => {
        const getListe = async () => {
            let resp;
            resp = await getFourCagnottes(1, 0);
            setListe(resp?.["hydra:member"]);
        }
        getListe();
    }, [])

    return ( 
    <div>
    <div className="body"></div>

    <section
        className="elementor-section elementor-top-section elementor-element elementor-element-ce6aa8f elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="ce6aa8f" data-element_type="section"
        data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
        >
        <div className="elementor-container elementor-column-gap-no">
            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-869dc3e"
                data-id="869dc3e" data-element_type="column">
                <div className="elementor-widget-wrap elementor-element-populated ">

                    <section className="elementor-section elementor-inner-section elementor-element elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="09205ac" data-element_type="section">
                        <div className="elementor-container elementor-column-gap-no">
                            <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-09f281c"
                                data-id="09f281c" data-element_type="column"
                                data-settings='{"background_background":"classic"}'>
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    
                                    <section
                                        className="elementor-section elementor-inner-section elementor-element elementor-element-448094f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                        data-id="448094f" data-element_type="section">
                                        <div className="elementor-column-gap-no">
                                            <div className="wrap">
                                                <div id="primary" className="content-area">
                                                    <main id="main" className="site-main">
                                                        <div data-elementor-type="wp-post" data-elementor-id="1997"
                                                            className="elementor elementor-1997" data-elementor-settings="[]">
                                                            <div className="elementor-inner">
                                                                <div className="elementor-section-wrap">
                                                                    <div className="elementor-section elementor-top-section elementor-element elementor-element-fe76d22 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                                        data-id="fe76d22" data-element_type="section">
                                                                        <div
                                                                            className="elementor-container elementor-column-gap-no">
                                                                            <div className="elementor-row">
                                                                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a476304" data-element_type="column">
                                                                                    <div className="elementor-column-wrap elementor-element-populated">
                                                                                        <div className="elementor-widget-wrap">
                                                                                            <div className="elementor-element elementor-element-f0782d5 campain-grid-style-1 elementor-widget elementor-widget-opal-give-campain"
                                                                                                data-element_type="widget" data-widget_type="opal-give-campain.default" style={{"overflow-x": "auto" }}>
                                                                                                <div className="elementor-widget-container">
                                                                                                    <div className="give-wrap container">
                                                                                                        <div className="give-grid give-grid--4 CA" style={{gridTemplateColumns: "265px 265px 265px 265px"}}>
                                                                                                            { renderDisplayComponent() }
                                                                                                        </div>
                                                                                                        
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </div>
    </section>
    </div>
)
}

export default CagnottesFrame;