import {useLocation, Navigate, Outlet} from "react-router-dom";
import React from "react";
import NotFound from "../pages/NotFound";

const RequireAuth = ({allowedRoles}) => {
    const location = useLocation();
    // Récupération depuis la session du naviguateur du role et du login de l'utilisateur actuellement connecté
    let user_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles
    let user_login = JSON.parse(sessionStorage.getItem('user_data'))?.login

    const checkRoles = (allowedRolesParam) => {

        // Lorsque les données d'authentification de l'utilisateur actuellement connecté
        // n'existe plus, forcé sa déconnexion

        if(user_role?.includes('ROLE_AMBASSADEUR') || user_role?.includes('ROLE_ADMIN')){
            return (
                user_role?.find(role => allowedRolesParam?.includes(role))
                    ? <Outlet/>
                    : user_login
                    ? <NotFound />
                    : <Navigate to="/auth" state={{from: location}} replace/>
            )
        }else if(user_role?.includes('CONTRIBUTEUR_INSCRIT')){
            return (
                user_role?.find(role => allowedRolesParam?.includes(role))
                    ? <Outlet/>
                    : user_login
                    ? <NotFound />
                    : <Navigate to="/authentification" state={{from: location}} replace/>
            )
        } else {
            return (
                <NotFound />
            )
        }
    }

    return (
        <>
            {checkRoles(allowedRoles)}
        </>
    );
}

export default RequireAuth;
