import axios from "axios"
import endpoints from '../endpoints';

const postFeedback = async ({feedbackMsg, contributeur, cagnotte, contribution}) => {
    try {
        return await axios({
            method: 'post',
            url: endpoints.postFeedback(),
            data: {
                message: feedbackMsg,
                valide: 0,
                contributeur: contributeur,
                cagnotte: cagnotte,
                contribution: contribution
            }
        });
    } catch (error) {
        console.error(error);
        return false;
    }
}

export default postFeedback;