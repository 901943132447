import React, { useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import DropdownBlock from "./DropdownBlock";
import getParticipationAmbassadeurCagnotteByAmbassadeur from "../api/queries/getParticipationAmbassadeurCagnotteByAmbassadeur"

const Menu = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [participationAmbassadeurCagnotteStatutOverFour, setParticipationAmbassadeurCagnotteStatutOverFour] = useState(false);
    const connected = props.connectedVar;
    const connectedFunc = props.connectedFunc;
    const [dropdown, setDropdown] = useState(false);
    const [displayValue, setDisplayValue] = useState('none');

    const setDropdownAndDisplayValue = () => {
        setDropdown(false);
        setDisplayValue('none');
    };

    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
            setDropdownAndDisplayValue();
        } else {
            setDropdown(true);
            setDisplayValue('block')
        }
    };

    const onMouseLeave = () => {
        setDropdownAndDisplayValue();
    };

    const menuContributionsActive = () => {
        if (splitLocation[splitLocation.length-1] === "validation-contributions" || splitLocation[splitLocation.length-1] === "validation-feedbacks") {
            return 'elementor-item elementor-item-active';
        }
        return 'elementor-item';
    }

    const onDeconnexion = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('isLogged');
        sessionStorage.removeItem('login');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('user_data');
        props.connectedFunc(false);
        navigate("/");
    }

    const showMenuContributionsCagnottes = () => {
        // Si l'utilisateur est connecté ou authentifié
        if (connected === true) {

            let user_role = getRoleUser();

            // Si la personne connecté est un contributeur inscrit on récupère la liste de ses contributions
            if(user_role?.includes("CONTRIBUTEUR_INSCRIT") || user_role?.includes("ROLE_CONTRIBUTEUR")){
                return (
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                        <a href="/mes-contributions-cagnottes"
                              className={splitLocation[1] !== "mes-contributions-cagnottes" ? "elementor-item" : "elementor-item elementor-item-active"}>Mes
                            contributions</a>
                    </li>
                )
            }
        }
    }

    const getRoleUser = () => {

        // Récupération des données utilisateur depuis les session storage
        let user_data_from_session = sessionStorage.getItem('user_data');

        // Si la variable de session existe est définie, on convertit sa valeur en format json dans le cas contraire c'est undefined
        let user_data_from_session_json = user_data_from_session ? JSON.parse(user_data_from_session) : undefined;

        // Si la variable est définie alors on récupère le rôle de l'utilisateur dans le cas contraire se sera undefined
        return user_data_from_session_json?.roles;
    }

    const showMenuContributions = () => {

        // Si l'utilisateur est connecté ou authentifié
        if (connected === true) {

            let user_role = getRoleUser();
            // Si la personne connecté est un ambassadeur
            if(user_role?.includes("ROLE_AMBASSADEUR")){
                return(
                    <>
                        <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-1608 current_page_item menu-item-9643">
                            <Link to="#" aria-current="page"
                                className={menuContributionsActive()}
                            >
                                contributions <span className="sub-arrow"></span>
                            </Link>
                            {dropdown && <DropdownBlock displayValue={displayValue}/>}
                        </li>
                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-1608 current_page_item menu-item-9643">
                            <a href="/administration/contribution/mes-participations" className={splitLocation[2] !== "contribution" ? "elementor-item" : "elementor-item elementor-item-active"}>Mes participations</a>
                        </li>
                    </>
                )
            }
        }
    }

    const showMenuGestionAmbassadeurDemandes = () => {

        // Si l'utilisateur est connecté ou authentifié
        if (connected === true) {

            let user_role = getRoleUser();
            // Si la personne connecté est un administrateur
            if(user_role?.includes("ROLE_ADMIN")){
                return(
                    <>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                            <a href="/gestion-ambassadeur" className={splitLocation[1] !== "gestion-ambassadeur" ? "elementor-item" : "elementor-item elementor-item-active"}>
                                Ambassadeurs</a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                            <a href="/contributions/demandes-a-valider" className={splitLocation[splitLocation.length-1] !== "demandes-a-valider" ? "elementor-item" : "elementor-item elementor-item-active"}>
                                Demandes</a>
                        </li>
                    </>
                )
            }
        }
    }

    const showMenuEspaceContributeurMobile = () => {
        if (!connected) {
            // Si la personne connecté est un ambassadeur
            return(
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                    <a href="/authentification" className="elementor-item">
                    Espace contributeur</a>
                </li>
            )
        }
    }

    const showMenuContributionsMobile = () => {
        // Si l'utilisateur est connecté ou authentifié
        if (connected === true) {

            let user_role = getRoleUser();
            // Si la personne connecté est un ambassadeur
            if(user_role?.includes("ROLE_AMBASSADEUR")){
                return(
                    <>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                            <a href="/contributions/validation-contributions"
                                className={splitLocation[1] !== "contributions/validation-contributions" ? "elementor-item" : "elementor-item elementor-item-active"}>
                                Valider les contributions</a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                            <a href="/contributions/validation-feedbacks"
                                className={splitLocation[1] !== "contributions/validation-feedbacks" ? "elementor-item" : "elementor-item elementor-item-active"}>
                                Valider les dédicaces</a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                            <a href="/administration/contribution/mes-participations"
                                className={splitLocation[1] === "contribution" ? "elementor-item" : "elementor-item elementor-item-active"}>
                                Mes participations</a>
                        </li>
                    </>
                )
            }
        }
    }

    const showMenuAmbassadeurDemandesMobile = () => {
        // Si l'utilisateur est connecté ou authentifié
        if (connected === true) {

            let user_role = getRoleUser();
            // Si la personne connecté est un ambassadeur
            if(user_role?.includes("ROLE_ADMIN")){
                return(
                    <>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                            <a href="/contributions/demandes-a-valider" className={splitLocation[1] !== "contributions/demandes-a-valider" ? "elementor-item" : "elementor-item elementor-item-active"}>
                                Demandes à valider
                            </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                            <a href="/gestion-ambassadeur" className={splitLocation[1] !== "gestion-ambassadeur" ? "elementor-item" : "elementor-item elementor-item-active"}>
                                Gestion Ambassadeur
                            </a>
                        </li>
                    </>
                )
            }
        }
    }

    const showMenuDeconnexionMobile = () => {
        // Si l'utilisateur est connecté ou authentifié
        if (connected === true) {
            return(
                <>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                        <a onClick={onDeconnexion} className="register-link" href="/#" title="Se deconnecter">Se deconnecter</a>
                    </li>
                </>
            )
        }
    }

    const showMenuMonCompteMobile = () => {
        // Si l'utilisateur est connecté ou authentifié
        if (connected === true) {
            return(
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                    <a className="register-link" href="/contributeur/mon-compte" title="Mon compte">Mon compte</a>
                </li>
            )
        }
    }

    const gotoContrinuteurAccount = () => {
        navigate("/contributeur/mon-compte");
    }

    const gotoAdministrateurAccount = () => {
        navigate("/administrateur/mon-compte");
    }

    const handlePathToAccountEditing = () => {

        // Si l'utilisateur est connecté ou authentifié
        if (connected === true) {

            let user_role = getRoleUser();

            // Si la personne connecté est un contributeur
            if(user_role?.includes("CONTRIBUTEUR_INSCRIT") || user_role?.includes("ROLE_CONTRIBUTEUR")){
                return(
                    <button type="submit" onClick={gotoContrinuteurAccount} data-button-action className="btn btn-primary btn-block w-100 mt-1">Mon compte</button>
                )
            }

            // Si la personne connecté est un ambassadeur ou un administrateur
            if(user_role?.includes("ROLE_AMBASSADEUR") || user_role?.includes("ROLE_ADMIN")){
                return(
                    <button type="submit" onClick={gotoAdministrateurAccount} data-button-action className="btn btn-primary btn-block w-100 mt-1">Mon compte</button>
                )
            }
        }
    }

    const showDonataires = () => {

        // Si l'utilisateur est connecté ou authentifié
        if (connected === true) {

            let user_role = getRoleUser();

            // Si la personne connecté est un ambassadeur
            if(user_role?.includes("ROLE_AMBASSADEUR") && participationAmbassadeurCagnotteStatutOverFour){
                return(
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-1608 current_page_item menu-item-9643">
                    <a href="/beneficiaires" className={splitLocation[1] !== "beneficiaires" ? "elementor-item" : "elementor-item elementor-item-active"}>Bénéficiaires</a>
                </li>
                )
            }
        }
    }

    const showMenuTwoContributeurConnected = () => {
        if (JSON.parse(sessionStorage.getItem('isLogged')) === true) {
            return (
                <div className="elementor-element elementor-element-2bea0ae elementor-widget__width-auto elementor-hidden-tablet elementor-hidden-phone elementor-align-center elementor-widget elementor-widget-opal-account" data-id="2bea0ae" data-element_type="widget" data-widget_type="opal-account.default">
                    <div className="elementor-widget-container">
                        <div className="site-header-account">
                            <i className="opal-icon-user"></i> <i className="opal-icon-angle-down submenu-indicator" aria-hidden="true"></i>
                            <div className="account-dropdown">
                                <div className="account-wrap">
                                    <div className="account-inner ">

                                        {
                                            handlePathToAccountEditing()
                                        }
                                        <button onClick={onDeconnexion} className="btn btn-block w-100 mt-1 btn-deconexion">Se deconnecter</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    useEffect(() => {
        JSON.parse(sessionStorage.getItem('isLogged')) === true ? connectedFunc(true): connectedFunc(false);

        let isMounted = true;
        const loadDataParticipationAmbassadeurCagnotteByAmbassadeur= async () => {
            try{
                // Récupération des données utilisateur depuis les session storage
                let user_data_from_session = sessionStorage.getItem('user_data');
                // Si la variable de session existe est définie, on convertit sa valeur en format json dans le cas contraire c'est undefined
                let user_data_from_session_json = user_data_from_session ? JSON.parse(user_data_from_session) : undefined;
                // Si la variable est définie alors on récupère le rôle de l'utilisateur dans le cas contraire se sera undefined
                let user_role = user_data_from_session_json?.roles;

                if (user_role?.includes("ROLE_AMBASSADEUR")) {
                    let user_id = user_data_from_session_json?.id;
                    if(isMounted){
                        let rstParticipationAmbassadeurCagnotteByAmbassadeur = await getParticipationAmbassadeurCagnotteByAmbassadeur(user_id);
                        if(rstParticipationAmbassadeurCagnotteByAmbassadeur?.["hydra:totalItems"] >= 4){
                            setParticipationAmbassadeurCagnotteStatutOverFour(true);
                        } else {
                            setParticipationAmbassadeurCagnotteStatutOverFour(false);
                        }
                    }
                }
            } catch(err){
                console.log(err);
            }
        }

        loadDataParticipationAmbassadeurCagnotteByAmbassadeur();
        return () => (isMounted = false)
    }, [connected, connectedFunc])

    return (
        <>
            <header id="masthead" className="site-header">
                <div className="site-header">
                    <div data-elementor-type="wp-post" data-elementor-id="5155" className="elementor elementor-5155"
                        data-elementor-settings="[]">
                        <div className="elementor-section-wrap">
                            <section
                                className="elementor-section elementor-top-section elementor-element elementor-element-ef41c55 elementor-section-content-middle elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="ef41c55" data-element_type="section"
                                data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                                <div className="elementor-container elementor-column-gap-no">
                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-74b710b"
                                        data-id="74b710b" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div className="elementor-element elementor-element-486779a elementor-widget__width-auto elementor-widget elementor-widget-opal-site-logo elementor-widget-image"
                                                data-id="486779a" data-element_type="widget" data-widget_type="opal-site-logo.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-image">
                                                        <a href="https://n3ichob7alnass.intelcia-solutions.com/">
                                                            <img width="318" height="260" src="wp-content/uploads/2020/12/logo-darib7alnass.png" className="attachment-full size-full" alt="" loading="lazy"
                                                                srcSet="https://n3ichob7alnass.intelcia-solutions.com/wp-content/uploads/2020/12/logo-darib7alnass.png 318w, https://n3ichob7alnass.intelcia-solutions.com/wp-content/uploads/2020/12/logo-darib7alnass-300x245.png 300w"
                                                                sizes="(max-width: 318px) 100vw, 318px" /> 
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-26de578 elementor-nav-menu__align-right elementor-menu-toggle-mobile__align-right elementor-menu-toggle-tablet__align-right elementor-widget__width-auto mx-xl-auto ml-auto elementor-nav-menu--indicator-angle elementor-nav-menu--dropdown-tablet elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-menu-toggle__align-left elementor-widget elementor-widget-opal-nav-menu"
                                                data-id="26de578" data-element_type="widget"
                                                data-settings="{&quot;layout&quot;:&quot;horizontal&quot;,&quot;toggle&quot;:&quot;burger&quot;}" data-widget_type="opal-nav-menu.default">
                                                <div className="elementor-widget-container">
                                                    <nav className="elementor-nav-menu--mobile-enable elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-none"
                                                        data-submenusminwidth="250" datasubmenusmaxwidth="500">
                                                        <ul id="menu-main-menu" className="elementor-nav-menu">
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-1608 current_page_item menu-item-9643">
                                                                <a className="elementor-item" href="https://n3ichob7alnass.intelcia-solutions.com" aria-current="page">Accueil</a>
                                                            </li>
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-1608 current_page_item menu-item-9643">
                                                                <a className="elementor-item" href="https://n3ichob7alnass.intelcia-solutions.com/a-propos/" aria-current="page">A Propos</a>
                                                            </li>
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                                                                <Link to="/" className={splitLocation[1] === "" || splitLocation[1] === "contribution" || splitLocation[1] === "cagnottes" ? "elementor-item  elementor-item-active" : "elementor-item"}>Cagnottes</Link>
                                                            </li>
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-1608 current_page_item menu-item-9643">
                                                                <a className="elementor-item" href="https://n3ichob7alnass.intelcia-solutions.com/media/" aria-current="page">Media</a>
                                                            </li>
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-1608 current_page_item menu-item-9643">
                                                                <a className="elementor-item" href="https://n3ichob7alnass.intelcia-solutions.com/contact/" aria-current="page">Contact</a>
                                                            </li>
                                                            {showMenuGestionAmbassadeurDemandes()}
                                                            { showMenuContributions() }
                                                            {showMenuContributionsCagnottes()}
                                                            {showDonataires()}
                                                        </ul>
                                                    </nav>

                                                    <div className="elementor-menu-toggle" data-target="#menu-26de578">
                                                        <i className="eicon" aria-hidden="true"></i>
                                                    </div>

                                                    <nav id="menu-26de578" className="elementor-nav-menu--canvas mp-menu">
                                                        <ul id="menu-canvas-2-26de578" className="nav-menu--canvas">
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                                                                <a className="elementor-item" href="https://n3ichob7alnass.intelcia-solutions.com" aria-current="page">Accueil</a>
                                                            </li>
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                                                                <a className="elementor-item" href="https://n3ichob7alnass.intelcia-solutions.com/a-propos/" aria-current="page">A Propos</a>
                                                            </li>
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                                                                <a href="/">Cagnottes</a>
                                                            </li>
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                                                                <a className="elementor-item" href="https://n3ichob7alnass.intelcia-solutions.com/media/" aria-current="page">Media</a>
                                                            </li>
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9742">
                                                                <a className="elementor-item" href="https://n3ichob7alnass.intelcia-solutions.com/contact/" aria-current="page">Contact</a>
                                                            </li>
                                                            { showMenuContributionsCagnottes() }
                                                            { showMenuContributionsMobile() }
                                                            {showDonataires()}
                                                            { showMenuEspaceContributeurMobile() }

                                                            { showMenuAmbassadeurDemandesMobile() }

                                                            { showMenuMonCompteMobile() }
                                                            { showMenuDeconnexionMobile() }
                                                        </ul>
                                                    </nav>

                                                </div>
                                            </div>

                                            {/* afficher 2e Menu contributeur connecté */}
                                            { showMenuTwoContributeurConnected() }

                                            <div className="elementor-element elementor-element-1b0eebc elementor-widget__width-auto elementor-hidden-tablet elementor-hidden-phone elementor-button-primary elementor-widget elementor-widget-button"
                                                data-id="1b0eebc" data-element_type="widget" data-widget_type="button.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-button-wrapper">
                                                        <Link to="/authentification"
                                                            className="elementor-button-link elementor-button elementor-size-md" role="button">
                                                            <span className="elementor-button-content-wrapper">
                                                                <span className="elementor-button-icon elementor-align-icon-left">
                                                                    <i className="opal-icon-arrow-circle-right" aria-hidden="true"></i>
                                                                </span>
                                                                <span className="elementor-button-text">Espace contributeur</span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </header>
            <div id="page-title-bar" className="page-title-bar"></div>
        </>
    )
}

export default Menu;