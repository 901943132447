import axios from "axios"
import endpoints from '../endpoints';

const updateAmbassadeur = async (ambassadeur_id, params) => {
    try {
        return await axios({
            method: 'put',
            url: endpoints.urlGetAmbassadeurById(ambassadeur_id),
            data: params
        });
    } catch (error) {
        console.error(error);
        return false;
    }
}

export default updateAmbassadeur;