import axios from "axios"
import endpoints from '../endpoints';

const postLoginContributeur = async ({ username, password }) => {
    try {
        return await axios({
            method: 'post',
            url: endpoints.urlPostLoginContributeur(),
            data: {
                "username": username,
                "password": password
            }
        });
    } catch (error) {
        return false;
    }
}

export default postLoginContributeur;