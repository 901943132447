import { useRef, useState } from "react";
import { postForgotPassword } from '../api/queries';
import isEmail from 'validator/lib/isEmail';

const ForgotPassword = () => {
    const inputEmail = useRef();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msgError, setMsgError] = useState('');

    const onSubmitForm = async () => {
        if (inputEmail.current.value !== "" && isEmail(inputEmail.current.value)) {
            setError(false);
            let body = { "email": inputEmail.current.value }
            try {
                let result = await postForgotPassword(body);
                if (result?.status === 200) setSuccess(true);
            } catch (error) {
                setSuccess(true); // Dire au user que si son email est correct il recevra sn lien de reset pwd sinn on ne lui dira rien
            }
        } else {
            setError(true);
            setMsgError('Le champs email est incorrect.');
        }
    }

    return (
        <div id="give-wrap" className="give-wrap" style={{ "position": "relative"}}>
            <div className="give-wrap-inner">
                <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                    <div className="summary entry-summary top-ecart col-md-7 d-flex" style={{"justifyContent": "center"}}>
                        <div id="give-form-62-wrap" className="give-form-wrap give-display-onpage" style={{"borderRadius": "5px"}}>
                            { success ? (
                                <>
                                    {/* message a afficher en cas de succes */}
                                    <div className="alert alert-success" role="alert">
                                        Un mail sera envoyé à votre adresse pour la réinitialisation de votre mot de passe.
                                    </div>
                                    {/* <div className="row" style={{"justifyContent": "center"}}>
                                        <div className="give-submit-button-wrap give-clearfix pull-right">
                                            <button onClick={gotoAccueilPage} className="give-submit give-btn">Aller à la page d'accueil</button>
                                        </div>
                                    </div> */}
                                </>) : (
                                    // Afficher le form au chargement de la page
                                    <>
                                         <h3>Réinitialisation de mot de passe</h3> <br/>
                                        <p className="form-row form-row-first form-row-responsive">
                                            <label className="give-label" htmlFor="give-first"> Veuillez saisir votre adresse mail *</label>
                                            <input ref={inputEmail} type="email" name="email" id="email" className="wpcf7-form-control wpcf7-text" />
                                        </p>
                                        {/* message notification */}
                                        { error && ( <div className="alert alert-danger" role="alert">
                                                { msgError }
                                            </div>)
                                        }

                                        <fieldset id="give_purchase_submit" className="give-donation-submit">
                                            <div className="give-submit-button-wrap give-clearfix pull-right">
                                                <button onClick={onSubmitForm} className="give-submit give-btn">Valider</button>
                                            </div>
                                        </fieldset>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ForgotPassword;