import {Link} from "react-router-dom";
import'./DisplayCardFrame.css';
const DisplayCardFrame = ({ data }) => {

    const showLabelStatut = () => {
        return data.cloture ? (<div className="give-card__label danger">Termine</div>) : (<div className="give-card__label success">En cours </div>);
    }

    const showButtonContribution = () => {                      
        // Si l'utilisateur n'est pas connecté ou authentifié
        return data.cloture  ? null : (
        <div className="give-submit-button-wrap give-clearfix my_custom_flex_center custom_contribuer_btn_position">
            <button onClick={()=>{window.top.location=window.location.protocol + '//' + window.location.host+"/contribution/"+data.slug }} className="give-submit give-btn">Contribuer</button>
        </div>
        );
    }

    const showLienContributionDetail = () => {
        // Si l'utilisateur n'est pas connecté ou authentifié
        return (<Link to={"/contribution/"+data.slug}>
            { data.titre }
        </Link>)
    }
    

    return (

        <div className="give-grid__item">
            <div className="give-card__progress top">
                <div className="give-goal-progress">
                    <div className="give-progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100">
                        <span style={{ "width": "100%" }}></span>
                    </div>
                    <div className="raised">
                        <div className="income">
                            <span className="label">Gain</span>
                            <span className="value">{ !data.nombreCouponsValides ? 0 : data.nombreCouponsValides }</span>
                        </div>
                        <div className="percentage">
                            { parseInt(data.progresValide) } <sup>%</sup>
                        </div>
                        <div className="goal">
                            <span className="label">Objectif</span>
                            <span className="value">{ data.nombreTotalCoupons }</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="give-card__body my_custom_flex_center_2" style={{position:"relative", height:"auto"}}>
                <div className="give-card__meta" style={{height: "auto", paddingBottom:"70px"}}>
                    <h3 className="give-card__title">
                        { showLienContributionDetail()}
                    </h3>
                    <h3 className="give-card__title style_parrain_marraine">
                        { 'Parrain/Marraine : '+data.parrainMarraine }
                    </h3>
                    <p className="give-card__text" >
                        { data.description }
                    </p>
                    {/* show button contribuer */}
                    {  showButtonContribution() }
                </div>
            </div>
            <div className="give-card__media">
                <div className="give-card__thumbnail">
                    <img width="850" height="550"
                        src={data.tImage !== undefined ? data.tImage.chemin : "" } className="attachment-gainlove-featured-image-large size-gainlove-featured-image-large wp-post-image"
                        alt={data.tImage !== undefined ? data.tImage.description : "" } loading="lazy" />
                </div>
                {/* libelle statut de la cagnotte */}
                { showLabelStatut() }
                <div className="opal-popup-wrapper">
                    <div className="opal-image-popup">
                        <div data-gainlove-id="61" data-toogle="gainlove-gallery">
                            <i className="opal-icon-image"></i>
                        </div>
                    </div>
                    <div className="opal-video-popup">
                        <div data-gainlove-id="61" data-toogle="gainlove-video"> </div>
                    </div>
                </div>
                <div className="give-card__progress bottom">
                    <div className="give-goal-progress">
                        <div className="give-progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100">
                            <span style={{ "width": "100%" }}></span>
                        </div>
                        <div className="raised">
                            <div className="income">
                                <span className="label">Current</span>
                                <span className="value">{ !data.nombreCouponsValides ? 0 : data.nombreCouponsValides }</span>
                            </div>
                            <div className="percentage">
                                148<sup>%</sup>
                            </div>
                            <div className="goal">
                                <span className="label">Target</span>
                                <span className="value">{ data.nombreTotalCoupons }</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DisplayCardFrame;