import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import React, {useRef, useState} from "react";
import updateContribution from "../api/queries/updateContribution";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import getCagnotteById from "../api/queries/getCagnotteById";
import updateCagnotte from "../api/queries/updateCagnotte";

const RejetValidationContribution = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const [motifRejetRequired, setMotifRejetRequired] = useState(false);
    const [motifRejetMsg, setMotifRejetMsg] = useState('');
    const [motifRejetStatut, setMotifRejetStatut] = useState(false);
    const axiosPrivate = useAxiosPrivate();

    const handleMotifRejet = (e) => {
        setMotifRejetMsg(e.target.value);

        if (e.target.value) {
            setMotifRejetRequired(true);
        } else {
            setMotifRejetRequired(false);
        }
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        try {

            let params = {
                paymentValide: 2,
                motifRejet: motifRejetMsg
            }
            props.setSuccess(true);
            props.setLoading(true);

            let updateContributionResponse = await updateContribution(props.contributionAValiderId, params, axiosPrivate);

            if (updateContributionResponse?.status === 200) {

                // Si l'on veut rejeter une contribution qui a été préalablement validée
                if(props.contributionCagnottePaymentValide === 1){
                    // Récupération de la cagnotte liée à la contribution
                    let responseGetCagnotteById = await getCagnotteById(props.contributionCagnotteId);

                    // Récupération du nombre de coupons validés au total pour ladite cagnotte
                    let responseGetCagnotteNombreCouponsValides = !responseGetCagnotteById?.data?.nombreCouponsValides ? 0 : responseGetCagnotteById?.data?.nombreCouponsValides;

                    // Récupération du nombre de coupons pour ladite cagnotte
                    let responseGetCagnotteNombreCoupons = !responseGetCagnotteById?.data?.nombreCoupons ? 0 : responseGetCagnotteById?.data?.nombreCoupons;

                    // Au nombre de coupons validés de la cagnotte actuelle, on retranche le nombre de coupon de la contribution courante
                    // pour avoir le nouveau nombre de coupons validés
                    let nouveauNombreCouponsValides = responseGetCagnotteNombreCouponsValides - parseInt(props.contributionNombreCoupon);

                    // Au nombre de coupons de la cagnotte actuelle, on retranche le nombre de coupon de la contribution courante
                    // pour avoir le nouveau nombre de coupons
                    let nouveauNombreCoupons = responseGetCagnotteNombreCoupons - parseInt(props.contributionNombreCoupon);

                    // Mise à jour du nouveau nombre de coupons validés et du nombre de coupon dans la cagnotte actuelle

                    if(responseGetCagnotteById?.data?.nombreTotalCoupons === nouveauNombreCouponsValides){
                        let updateDataCagnotte = {
                            nombreCouponsValides: nouveauNombreCouponsValides,
                            nombreCoupons: nouveauNombreCoupons,
                            cloture: true
                        }
                        await updateCagnotte(props.contributionCagnotteId, updateDataCagnotte)
                    } else {
                        let updateDataCagnotte = {
                            nombreCouponsValides: nouveauNombreCouponsValides,
                            nombreCoupons: nouveauNombreCoupons,
                            cloture: false
                        }
                        await updateCagnotte(props.contributionCagnotteId, updateDataCagnotte)
                    }
                }

                setMotifRejetStatut(true);
                props.setUpdateContributionStatutFromParent(true);
                // Enregistrement dans les session storage
                sessionStorage.setItem('succesMsgFromParent', 'La contribution a été rejetée avec succès!');
                // Redirection vers la liste des contributions
                // onGotoContributionAValider();
            }
            props.setLoading(false);
        } catch (err) {
            // console.error(err)
            if (!err?.response) {
                setErrMsg('Une erreur est survenue');
            } else {
                setErrMsg("L'enregistrement a echoué")
            }
            props.setLoading(false);
        }
    }

    return (
        <>
            <Button className={motifRejetStatut || props.updateContributionStatut || props.contributionCagnottePaymentRejete === 2 ? "hide" : "give-submit give-btn pull-right valider_contribution_details_for_validation_style mr-1"} onClick={handleShow}>
                Rejeter
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Motif de rejet de la contribution</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <div className="form-group">
                        <textarea
                            placeholder="Veuillez saisir le motif de rejet de la contribution"
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="2"
                            cols="1"
                            onChange={(e) => handleMotifRejet(e)}
                            required
                            readOnly={motifRejetStatut ? true : false}
                        ></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                            onClick={(e) => handleSubmit(e)}
                            disabled={!motifRejetRequired ? true : false}
                            className={motifRejetStatut ? "hide" : ""}
                    >
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RejetValidationContribution;