import {useRef, useState} from "react";
import postFeedback from "../../api/queries/postFeedback";
import {postContribution} from "../../api/queries";
import getCagnotteById from "../../api/queries/getCagnotteById";
import updateCagnotte from "../../api/queries/updateCagnotte";

const DedierContribution = (props) => {
    const [feedbackMsg, setFeedbackMsg] = useState('');
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const [feedbackRequired, setFeedbackRequired] = useState(false);
    const [sendFeedbackStatut, setSendFeedbackStatut] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const nombreCouponsDisponible = useRef();

    const handleFeedback = (e) => {
        setFeedbackMsg(e.target.value);

        if (e.target.value) {
            setFeedbackRequired(true);
        } else {
            setFeedbackRequired(false);
        }
    }

    const submitContribution = async (e) => {

        try {
            e.preventDefault();
            // Récupération de l'id du contributeur depuis les local storage
            let nombreCoupon = parseInt(props.contributionConfig?.nbCoupons);
            let modeRecuperationCoupon = props.contributionConfig?.modeRecuperation;
            let modePaiement = props.contributionConfig?.modePaiement;
            let contributeur_id_iri_string = "/api/contributeur/" + JSON.parse(sessionStorage.getItem('user_data'))?.id;
            let cagnotte_id_iri_string = "/api/cagnotte/" + props.cagnotteCurrent?.id;
            let ambassadeur_id_iri_string = "/api/administrateur/" + localStorage.getItem('ambassadeur_id');

            let contributionData = {
                paymentValide: 0,
                couponGenere: false,
                contributeur: contributeur_id_iri_string,
                cagnotte: cagnotte_id_iri_string,
                nombreCoupons: nombreCoupon,
                modePaiment: modePaiement,
                ambassadeur: ambassadeur_id_iri_string,
                modeRecuperation: modeRecuperationCoupon
            }

            let responsePostContribution = await postContribution(contributionData);

            if (responsePostContribution?.status === 201) {

                // Enregistrement de la dédicace
                if(feedbackRequired){
                    // Cas de la présence de dédicace
                    let paramsDedicace = {
                        feedbackMsg: feedbackMsg,
                        contributeur: contributeur_id_iri_string,
                        cagnotte: cagnotte_id_iri_string,
                        contribution: "/api/contribution/" + responsePostContribution?.data?.id
                    }

                    await postFeedback(paramsDedicace);

                    setFeedbackMsg('');
                    setSendFeedbackStatut(true);
                    setSubmitSuccess(false);
                    props.setStatutFeedback(true);
                    props.submitNext(e);
                } else {
                    // Cas de l'absence de dédicace
                    props.submitNext(e)
                }

                // Mémorisation de l'id de la contribution dans les local storage
                localStorage.setItem('contribution_id', responsePostContribution?.data?.id);
            }

        } catch (err) {
            console.error(err);
            setErrMsg('Une erreur est survenue');
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitSuccess(true);

        // Récupération puis incrémentation du nombre de coupons actuelle de la cagnotte courante
        let responseGetCagnotteById = await getCagnotteById(props.cagnotteCurrent?.id);
        let nouveauNombreCoupon = responseGetCagnotteById?.data?.nombreCoupons + parseInt(props.contributionConfig?.nbCoupons);
        let nbrCouponsDisponible = responseGetCagnotteById?.data?.nombreTotalCoupons - responseGetCagnotteById?.data?.nombreCoupons;

        // Stockage du nombre de coupon disponible dans un ref
        nombreCouponsDisponible.current = nbrCouponsDisponible

            if (nbrCouponsDisponible > 0 && nbrCouponsDisponible >= parseInt(props.contributionConfig?.nbCoupons)) {
                // MAJ du nombre de coupons de la cagnotte courante
                let updateDataCagnotte = {
                    nombreCoupons: nouveauNombreCoupon
                }
                await updateCagnotte(props.cagnotteCurrent?.id, updateDataCagnotte)

                // Enregistrement de la contribution
                await submitContribution(e);
            } else {
                setErrMsg('Veuillez saisir un nombre inférieur ou égal au nombre de coupons disponibles qui est ' + nombreCouponsDisponible.current);
                setSubmitSuccess(false);
            }
    }

    return (
        <div>
            {/* progress bar */}
            <div className="progress-segment">
                <div className="progresso">
                    <div className="step-name etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero" style={{color: "#000"}}>1</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p>Choisir une cagnotte <br/>de collecte en cours</p>
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso">
                    <div className="step-name etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero">2</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p>Choisir le nombre de coupons et<br/> l’ambassadeur de la dignité
                            </p>
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso">
                    <div className="step-name etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero">3</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p>S'identifier, contribuer et<br/> récupérez vos coupons</p>
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso-active">
                    <div className="step-name-active etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero-active">4</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p style={{color: "#000"}}>Dédier la contribution <br/>à une âme chère</p>
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso-active-mobile">
                    <div className="step-name-active etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero-active">4</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            Dédier la contribution à une âme chère
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

            </div>

            <div className="row my_custom_flex_center_col">
                <div className="col-12 col-md-9">
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>



                    <div className="form-group" style={{marginTop:"40px"}}>
                        <label htmlFor="exampleFormControlTextarea1">Dédier la cagnotte à une âme chère</label>
                        <textarea
                            placeholder="Veuillez saisir votre dédicace"
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="10"
                            onChange={(e) => handleFeedback(e)}
                            required
                        ></textarea>
                    </div>
                </div>
                <br/>
            </div>

            <div
                id="retour_feedback"
                className="pull-left">
                <button
                    onClick={(e) => props.submitPrevious(e)}
                    className="give-submit give-btn valider_contribution_details_for_validation_style"
                >Retour
                </button>
            </div>
            <div className="pull-right" role="group">
                <button
                    id="envoyer_feedback"
                    type="button"
                    onClick={ (e) => handleSubmit(e) }
                    className={sendFeedbackStatut ? "hide" : "give-submit give-btn pull-right valider_contribution_details_for_validation_style"}
                    disabled={submitSuccess ? true : false}
                >
                    Suivant
                </button>
            </div>

            <div className="new_block_passer_cette_etape" style={{display: "none"}}>
                <div style={{width: "167px"}}>
                    <button
                        onClick={(e) => props.submitPrevious(e)}
                        className="give-submit give-btn pull-left valider_contribution_details_for_validation_style"
                    >Retour
                    </button>
                    <button
                        type="button"
                        onClick={(e) => handleSubmit(e)}
                        className={sendFeedbackStatut ? "hide" : "give-submit give-btn pull-right valider_contribution_details_for_validation_style"}
                        disabled={!feedbackRequired ? true : false}
                    > Envoyer
                    </button>
                </div>
                {/* <div className="mt-3">
                    <button onClick={(e) => props.submitNext(e)}
                        className={sendFeedbackStatut ? "hide" : "give-submit give-btn valider_contribution_details_for_validation_style"}
                    >
                        Passer cette étape
                    </button>
                </div> */}
            </div>
        </div>
    )
}

export default DedierContribution;