import React, {useRef, useState, forwardRef, useImperativeHandle } from "react";
import { getContributeurByEmail, postContributeurAnonyme } from "../api/queries";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import validator from 'validator';
import LoadingSpinner from '../components/LoadingSpinner';

const InscriptionContributeurAnonyme = forwardRef((props, ref) => {
    const [loading, setLoading] = useState(false);
    const errRef = useRef();
    const nomContributeurAnonymeRef = useRef();
    const prenomContributeurAnonymeRef = useRef();
    const emailContributeurAnonymeRef = useRef();

    const [nomContributeurAnonyme, setNomContributeurAnonyme] = useState('');
    const [prenomContributeurAnonyme, setPrenomContributeurAnonyme] = useState('');
    const [emailContributeurAnonyme, setEmailContributeurAnonyme] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);
    const [showRecuperation, setShowRecuperation] = useState(false);
    const [msgNon, setMsgNon] = useState(false);
    const [stepForm, setStepForm] = useState(1);


    const handleNomContributeurAnonyme = (e) => {
        setNomContributeurAnonyme(e.target.value);
    }

    const handlePrenomContributeurAnonyme = (e) => {
        setPrenomContributeurAnonyme(e.target.value);
    }

    const handleEmailContributeurAnonyme = (e) => {
        var email = e.target.value
        setEmailContributeurAnonyme(email);

        if (validator.isEmail(email)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }

    const handleModePaiement = async (e) => {
        if (e.target.value === "Virement") {
            setShowRecuperation(true);
            props.config.modeRecuperation = "";
            props.config.modePaiement = e.target.value;
            props.activeButtonNext(false); // on grise le btn Suivant
        } else {
            setShowRecuperation(false);
            localStorage.setItem('modeRecuperationCoupon', "");
            props.activeButtonNext(true); // on active le btn Suivant
            props.config.modePaiement = e.target.value;
        }

        if (e.target.value === "") {
            props.activeButtonNext(false); // on grise le btn Suivant
        }
        localStorage.setItem('modePaiement', e.target.value);
    }

    const handleModeRecuperationCoupon = async (e) => {
        localStorage.setItem('modeRecuperationCoupon', e.target.value);
        props.config.modeRecuperation = e.target.value;
        props.activeButtonNext(true); // on active le btn Suivant
        if (e.target.value === "") {
            props.activeButtonNext(false); // on grise le btn Suivant
        }
    }

    useImperativeHandle(ref, () => ({

        async handleSubmitt (e) {
            e.preventDefault();

            if (stepForm === 1 && nomContributeurAnonyme !=="" && prenomContributeurAnonyme !== "" && validEmail) {

                setMsgNon(false);
                try {
                    setLoading(true);
                    let params = {
                        nomContributeurAnonyme: nomContributeurAnonyme,
                        prenomContributeurAnonyme: prenomContributeurAnonyme,
                        emailContributeurAnonyme: emailContributeurAnonyme,
                    }
                    // Récupération du contributeur anonyme à partir de son adresse e-mail
                    let result = await getContributeurByEmail(emailContributeurAnonyme);
        
                    // Si le contributeur anonyme existe déja dans la BDD on le l'enregistre plus
                    if (result?.data?.["hydra:member"].length >= 1) {
                        // Enregistrement des données du contributeur dans les local storage du navigateur
                        sessionStorage.setItem('user_data', JSON.stringify(result.data?.["hydra:member"][0]));
                    } else {
                        // Enregistrement du contributeur anonyme en BDD s'il n'existe pas déja
                        await postContributeurAnonyme(params);
                        let resultGetContributeurByEmail = await getContributeurByEmail(emailContributeurAnonyme);
                        // Enregistrement des données du contributeur dans les local storage du navigateur
                        sessionStorage.setItem('user_data', JSON.stringify(resultGetContributeurByEmail?.data?.["hydra:member"][0]));
                    }

                    // props.successContributeurAnonymeBis();
                    //clear state and controlled inputs
                    setNomContributeurAnonyme('');
                    setPrenomContributeurAnonyme('');
                    setEmailContributeurAnonyme('');
                    setLoading(false);
                    setStepForm(2);
                } catch (err) {
                    console.log(err);
                    if (!err?.response) {
                        setErrMsg('Une erreur est survenue');
                    } else if (err.response?.status === 409) {
                        setErrMsg("Ce nom d'utilisateur existe déjà");
                    } else {
                        setErrMsg("L'enregistrement a echoué")
                    }
                    setLoading(false);
                }
            } else if(stepForm === 2 && props.config.modePaiement !== "") {
                // || (showRecuperation && props.config.modeRecuperation !== "")
                // Aller a la page suivante
                props.onNextFunction(e);
            } else {
                setMsgNon(true);
            }
        }
    }))

    return (
        <>
            {loading ? (
                <div style={{marginTop:"80px", marginBottom:"80px"}}>
                    <LoadingSpinner/>
                </div>
            ) : (
                <div id="give-wrap" className="give-wrap my_custom_flex_center_col mt-3">
                    <div className="give-wrap-inner">
                        <div id="give-form-62-content"
                             className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                            <div className="summary entry-summary">
                                <div id="give-form-62-wrap" className="give-display-onpage">
                                    { msgNon &&
                                        <div className="alert alert-danger" role="alert">
                                            Assurez-vous d'avoir renseigné correctement le formulaire.
                                        </div>
                                    }
                                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

                                    { stepForm === 1 && 
                                        <>
                                            {/* Nom et prenom */}
                                            <div id="give-first-name-wrap" className="form-row form-row-first form-row-responsive">
                                                <label className="give-label" htmlFor="give-first"> Nom <span className="give-required-indicator"> *</span></label>
                                                <input className="give-input required" type="text" id="nom-contributeur-anonyme"
                                                    name="nom-contributeur-anonyme"
                                                    onChange={(e) => handleNomContributeurAnonyme(e)}
                                                    value={nomContributeurAnonyme} ref={nomContributeurAnonymeRef}
                                                    aria-required="true"
                                                    aria-describedby="nom-contributeur-anonyme-note"
                                                    required
                                                />
                                            </div>
                                            <div id="give-first-name-wrap" className="form-row form-row-last form-row-responsive">
                                                <label className="give-label" htmlFor="give-first"> Prénom <span className="give-required-indicator"> *</span></label>
                                                <input className="give-input required" type="text"
                                                    id="prenom-contributeur-anonyme"
                                                    name="prenom-contributeur-anonyme"
                                                    onChange={(e) => handlePrenomContributeurAnonyme(e)}
                                                    value={prenomContributeurAnonyme} ref={prenomContributeurAnonymeRef}
                                                    aria-required="true" aria-describedby="prenom-contributeur-anonyme-note"
                                                    required
                                                />
                                            </div>
                                            {/* Email */}
                                            <div id="give-email-wrap" className="form-row form-row-wide">
                                                <label className="give-label" htmlFor="give-email"> Email <span className="give-required-indicator"> *</span></label>
                                                <input
                                                    className="give-input required"
                                                    type="email"
                                                    id="email-contributeur-anonyme"
                                                    name="email-contributeur-anonyme"
                                                    onChange={(e) => handleEmailContributeurAnonyme(e)}
                                                    value={emailContributeurAnonyme}
                                                    ref={emailContributeurAnonymeRef}
                                                    aria-required="true"
                                                    aria-describedby="emailnote"
                                                    onFocus={() => setEmailFocus(true)}
                                                    onBlur={() => setEmailFocus(false)}
                                                    required
                                                />
                                                <p id="emailnote"
                                                className={emailFocus && !validEmail ? "instructions_bis" : "offscreen"}>
                                                    <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;<span style={{
                                                    fontWeight: 'bold',
                                                    color: 'red',
                                                }}>Entrer un e-mail valide</span>
                                                </p>
                                            </div>
                                        </>
                                    }

                                    { stepForm === 2 && 
                                        <fieldset id="give_checkout_user_info col-md-2">
                                            {/* champ mode paiement et ses derivées */}
                                            {/* Choix du mode de paiement */}
                                            <div id="give-firgrrst-name-wrap" className="form-row form-row-wide">
                                                <label className="give-label" htmlFor="give-last"> Mode de paiement
                                                    <span className="give-required-indicator"> *</span>
                                                </label>
                                                <select id="modepaiement" name="modepaiement" className="give-input required"
                                                        onChange={(e) => handleModePaiement(e)} aria-required="true"
                                                        aria-describedby="modepaiementnote">
                                                    <option value="">Sélectionner le mode de paiement</option>
                                                    <option value="Virement">Virement</option>
                                                    <option value="Chèque">Chèque</option>
                                                    <option value="Espèce">Espèce</option>
                                                </select>
                                            </div>

                                            {/* Choix du mode de récupération du coupon; afficher que si le choix est un virement */}
                                            {showRecuperation &&
                                            <p id="give-last-name-wrap" className="form-row form-row-wide">
                                                <label className="give-label" htmlFor="give-last"> Mode de récuperation du coupon
                                                    <span className="give-required-indicator"> *</span>
                                                </label>
                                                <select id="moderecuperationcoupon" name="moderecuperationcoupon"
                                                        className="give-input required"
                                                        onChange={(e) => handleModeRecuperationCoupon(e)} aria-required="true"
                                                        aria-describedby="moderecuperationcouponnote">
                                                    <option value="">Sélectionner le mode de recupération</option>
                                                    <option value="En main propre">En main propre</option>
                                                    <option value="E-mail">E-mail</option>
                                                </select>
                                            </p>
                                            }
                                        </fieldset>
                                    }
                                    <p style={{color: "crimson"}}> Les champs marqués par (*) sont obligatoires</p>
                                </div>
                                {/* <button
                                    onClick={(event) => handleSubmit(event)}
                                    style={{fontSize: "0.875rem"}}
                                    // className="btn btn-primary pull-right"
                                    className="btn pull-right btn-primary"
                                    disabled={!nomContributeurAnonymeRequired || !validEmail || !prenomContributeurAnonymeRequired || !emailContributeurAnonymeRequired ? true : false}
                                > Enregistrer </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    )
})

export default InscriptionContributeurAnonyme