import '../Contribution.css';
import { getContributionsByContributeur } from '../api/queries';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faShareAlt, faSearch, faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons'
import LoadingSpinner from '../components/LoadingSpinner';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
const baseURL = process.env.REACT_APP_BASEURL;

const ContributeurContributionsCagnottes = () => {
    const axiosPrivate = useAxiosPrivate();
    let lastIndex = baseURL.lastIndexOf("/");
    var baseURLIndex = baseURL.slice(0, lastIndex + 1);
    const [loading, setLoading] = useState(false);
    const { SearchBar } = Search;
    const navigate = useNavigate();
    let [contribution, setContributions] = useState([]);
    const location = useLocation();
    const [columns] = useState([{
        dataField: 'cagnotte.titre',
        text: 'LIBELLE CAGNOTTE'
    }, {
        dataField: 'dateContribution',
        text: 'DATE DE LA CONTRIBUTION',
        formatter: dateFormatter,
        sort: true,
        sortFunc: (a, b, order) => {
            if (order === "asc") {
                return Date.parse(a) - Date.parse(b);
            } else if (order === "desc") {
                return Date.parse(b) - Date.parse(a);
            }
        }
    }, {
        dataField: 'modePaiment',
        text: 'MODE DE PAIEMENT'
    }, {
        dataField: "nombreCoupons",
        text: 'TOTAL COUPONS',
        formatter: totalCouponsFormatter,
        sort: true
    }, {
        dataField: 'cagnotte.valide',
        text: 'STATUT DE LA CAGNOTTE',
        formatter: statusFormatter
    }, {
        dataField: '',
        text: 'ACTIONS',
        formatter: showMenus
    }
    ]);

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            De {from} à {to} sur {size} résultat(s)
        </span>
    );

    const [options] = useState({
        pageStartIndex: 1,
        paginationSize: 5,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: false, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Retour',
        nextPageText: 'Suivant',
        lastPageText: 'Dernier',
        nextPageTitle: 'Première page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Page suivante',
        lastPageTitle: 'Dernière page',
        paginationTotalRenderer: customTotal,
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '30', value: 30
        }, {
            text: '50', value: 50
        }, {
            text: 'Tout', value: contribution.length
        }] // A numeric array is also available. the purpose of above example is custom the text
    });

    const sortFunc = (a, b, order, dataField) => {
        if (order === 'asc') {
            return b - a;
        }
        return a - b; // desc
    }

    const sortOption = {
        // No need to configure sortFunc per column
        sortFunc: sortFunc,
        // No need to configure sortCaret per column
        sortCaret: (order, column) => {
            if (!order) return (<span> <FontAwesomeIcon icon={faLongArrowAltUp} /><FontAwesomeIcon icon={faLongArrowAltDown} /> </span>);
            else if (order === 'asc') return (<span>&nbsp;<FontAwesomeIcon icon={faLongArrowAltUp} /><font style={{ color: "#9C14FC" }}><FontAwesomeIcon icon={faLongArrowAltDown} /></font></span>);
            else if (order === 'desc') return (<span>&nbsp;<font style={{ color: "#9C14FC" }}><FontAwesomeIcon icon={faLongArrowAltUp} /></font><FontAwesomeIcon icon={faLongArrowAltDown} /></span>);
            return null;
        }
    };

    function dateFormatter(cell) {
        return (
            <span> {cell} </span>
        );
    }

    function statusFormatter(cell, row) {
        if (cell) {
            return <span className="badge badge-success">Clôturée</span>;
        }
        return (
            <button type="button" className="btn btn-light">
                En cours <span className="badge badge-warning">{row?.cagnotte?.progresValide.toFixed(2)}%</span>
            </button>
        )
    }

    function totalCouponsFormatter(cell) {
        return cell;
    }

    function onGotoContribution(contribution_a_afficher_id, slug) {
        // Transmission de données à la page
        let datas = {
            contributionToShow: contribution_a_afficher_id,
            urlPath: location.pathname
        }
        navigate(`/contributions/contribution-detail/${slug}`, { state: datas });
    }

    function showMenus(cell, row) {
        return (
            <>
                <button title="Aller à la cagnotte" style={{ "borderRadius": "0.5rem" }} onClick={() => onGotoContribution(row.id, row?.cagnotte?.slug)} type="button" className="btn btn-danger btn-sm">
                    <FontAwesomeIcon icon={faSearch} />
                </button> &nbsp;
                {!row.cagnotte.valide &&
                    <a style={{ "color": "white", "borderRadius": "0.5rem" }} title="Partager la cagnotte" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href} type="button" className="btn btn-primary">
                        <FontAwesomeIcon icon={faShareAlt} />
                    </a>
                }  &nbsp;
{ row.paymentValide === 1 &&
                <a href={baseURLIndex + 'coupon/' + row.id} alt="Télécharger le coupon" rel="noopener noreferrer" style={{ "borderRadius": "0.5rem", color: "white" }} type="button" target="_blank" className="btn btn-secondary btn-sm">
                    <FontAwesomeIcon icon={faDownload} />
                </a>}

            </>
        );
    }

    useEffect(() => {
        let connected = Number.isInteger(JSON.parse((sessionStorage.getItem('user_data')))?.id);
        if (connected) {
            const loadContribution = async (id_contributeur) => {
                setLoading(true);
                const resp = await getContributionsByContributeur(id_contributeur, axiosPrivate, location, navigate);
                setContributions(resp?.["hydra:member"].reverse());
                setLoading(false);
            }
            loadContribution(JSON.parse((sessionStorage.getItem('user_data')))?.id);
        }
    }, [axiosPrivate, location, navigate])

    return (
        <div id="content" className="site-content">
            <div className="wrap top-ecart">
                <div id="primary" className="content-area">

                    {loading === false ? (
                        <div id="give-wrap" className="give-wrap top-ecart" style={{ "position": "relative" }}>
                            <div className="give-wrap-inner">
                                <div id="give-form-62-content"
                                    className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">

                                    <div className="summary entry-summary">

                                        <div className="card mb-5 custom_card_style_1">
                                            <h5 className="card-header text-center custom_card_style_2">
                                                Mes contributions</h5>
                                            <div className="card-body">
                                                <div id="give-form-62-wrap" className="give-display-onpage">

                                                    {contribution?.length > 0 ?
                                                        <ToolkitProvider
                                                            keyField="id"
                                                            data={contribution}
                                                            columns={columns}
                                                            search={{ defaultSearch: '', searchFormatted: true }}
                                                        >
                                                            {
                                                                props => (
                                                                    <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                                                        <div style={{ paddingRight: "0px" }} className='col-md-4 pull-right'>
                                                                            <SearchBar style={{ "borderRadius": "0.5rem" }} placeholder="Rechercher" {...props.searchProps} />
                                                                        </div>

                                                                        <hr />
                                                                        <BootstrapTable
                                                                            {...props.baseProps}
                                                                            pagination={paginationFactory(options)}
                                                                            sort={sortOption}
                                                                            classes="fix-responsive-width"
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                        </ToolkitProvider> : "Vous n'avez aucune contribution."
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    ) : (
                        <LoadingSpinner />
                    )
                    }
                </div>
            </div>
        </div>
    )
}

export default ContributeurContributionsCagnottes;