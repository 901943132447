import axios from "axios"
import endpoints from '../endpoints';

const getAdministrateurByEmail = async (email) => {
    try {
        return await axios({
            method: 'get',
            url: endpoints.urlGetAdministrateurByEmail(email)
        });
    } catch (error) {
        console.error(error);
        return false;
    }
}

export default getAdministrateurByEmail;