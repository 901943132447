import endpoints from '../endpoints';
const controller = new AbortController();

const getDonataireByAmbassadeur = async (ambassadeur_id, axiosPrivate, location, navigate) => {
    try {
        const response = await axiosPrivate.get(endpoints.urlGetDonataireByAmbassadeur(ambassadeur_id), {
            signal: controller.signal
        });
        return response.data;
    } catch (err) {
        if (err?.response?.status === 403 || err?.response?.status === 401) {
            navigate('/auth', {state: {from: location}, replace: true});
        }
    }
}

export default getDonataireByAmbassadeur;