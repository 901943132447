import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { postContributeurConfirmResetPwd } from '../api/queries';

const ContributeurPasswordReset = () => {
    const inputPwd = useRef();
    const inputPwd2 = useRef();
    const params = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msgError, setMsgError] = useState('');

    const onSubmitForm = async () => {
        if (inputPwd.current.value !== "" && inputPwd2.current.value !== "") {
            if(inputPwd.current.value !== inputPwd2.current.value) {
                setError(true); setMsgError('Les mots de passe ne sont pas identiques.');
                return;
            }

            setError(false);
            let body = {"new_password": inputPwd.current.value, "key": params?.key }
            try {
                let result = await postContributeurConfirmResetPwd(body);
                if (result?.status === 200) setSuccess(true);
            } catch (error) {
                setError(true);
                setMsgError("Impossible d'effectuer cette opération.");
            }
        } else {
            setError(true);
            setMsgError('Tous les champs sont obligatoires.');
        }
    }

    const gotoLoginPage = () => {
        navigate("/authentification");
    }

    return (
        <div id="give-wrap" className="give-wrap" style={{ "position": "relative"}}>
            <div className="give-wrap-inner">
                <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                    <div className="summary entry-summary top-ecart col-md-7 d-flex" style={{"justifyContent": "center"}}>
                        <div id="give-form-62-wrap" className="give-form-wrap give-display-onpage">
                            { success ? (
                                <>
                                    {/* message a afficher en cas de succes */}
                                    <div className="alert alert-success" role="alert">
                                        Votre mot de passe à été changé avec succès
                                    </div>
                                    <div className="row" style={{"justifyContent": "center"}}>
                                        <div className="give-submit-button-wrap give-clearfix pull-right">
                                            <button onClick={gotoLoginPage} className="give-submit give-btn">Se connecter</button>
                                        </div>
                                    </div>
                                </>) : (
                                    // Afficher le form au chargement de la page
                                    <>
                                        <p className="form-row form-row-first form-row-responsive">
                                            <label className="give-label" htmlFor="give-first"> Mot de passe *</label>
                                            <input ref={inputPwd} type="password" name="pwd" id="pwd" size="40" className="wpcf7-form-control wpcf7-text" />
                                        </p>
                                        <p className="form-row form-row-first form-row-responsive">
                                            <label className="give-label" htmlFor="give-first"> Confirmation du mot de passe *</label>
                                            <input ref={inputPwd2} type="password" name="pwd2" id="pwd2" size="40" className="wpcf7-form-control wpcf7-text" />
                                        </p>
                                        {/* message notification */}
                                        { error && ( <div className="alert alert-danger" role="alert">
                                                { msgError }
                                            </div>)
                                        }

                                        <fieldset id="give_purchase_submit" className="give-donation-submit">
                                            <div className="give-submit-button-wrap give-clearfix pull-right">
                                                <button onClick={onSubmitForm} className="give-submit give-btn">Valider</button>
                                            </div>
                                        </fieldset>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ContributeurPasswordReset;