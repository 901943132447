import { Link } from "react-router-dom";
import logoImg from '../assets/img/logo-darib7alnass.png';

const Footer = () => {
    return (

        <footer id="colophon" className="site-footer">
            <div className="wrap">
                <div className="container">
                    <div data-elementor-type="wp-post" data-elementor-id="6100" className="elementor elementor-6100"
                        data-elementor-settings="[]">
                        <div className="elementor-section-wrap">
                            <section className="elementor-section elementor-top-section elementor-element elementor-element-8debcf7 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="8debcf7" data-element_type="section"
                                data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                                <div className="elementor-container elementor-column-gap-no">
                                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-5feaca8"
                                        data-id="5feaca8" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div className="elementor-element elementor-element-eb93271 elementor-widget elementor-widget-opal-site-logo elementor-widget-image"
                                                data-id="eb93271" data-element_type="widget" data-widget_type="opal-site-logo.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-image">
                                                        <Link to="/" className="elementor-item">
                                                            <img width="318" height="260" src={logoImg} className="attachment-full size-full" alt="" loading="lazy"
                                                                    srcSet={logoImg} sizes="(max-width: 318px) 100vw, 318px" /> 
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="elementor-element elementor-element-78bfe94 elementor-widget elementor-widget-text-editor"
                                                data-id="78bfe94" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                        <p>L'accès à un logement décent est un droit de la constitution du Maroc qui garantit la dignité et participe dans la cohésion sociale des citoyens.
                                                        </p>
                                                        <p> « Dari B7al Nass » mobilise son collectif pour faciliter cet accès aux familles. </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="elementor-element elementor-element-5c58d0f elementor-mobile-align-center elementor-button-primary elementor-widget elementor-widget-button btn-contribuer-footer"
                                                data-id="5c58d0f" data-element_type="widget" data-widget_type="button.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-button-wrapper ">
                                                        <Link to="/authentification" className="elementor-button-link elementor-button elementor-size-md" role="button ">
                                                            <span className="elementor-button-content-wrapper">
                                                                <span className="elementor-button-icon elementor-align-icon-left">
                                                                    <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
                                                                </span>
                                                                <span className="elementor-button-text">Contribuer maintenant</span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6a6efb7"
                                        data-id="6a6efb7" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <section
                                                className="elementor-section elementor-inner-section elementor-element elementor-element-c1c2d71 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="c1c2d71" data-element_type="section">
                                                <div className="elementor-container elementor-column-gap-no">
                                                    <div
                                                        className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-1623547"
                                                        data-id="1623547" data-element_type="column">
                                                        <div className="elementor-widget-wrap">
                                                        </div>
                                                    </div>
                                                    <div className="elementor-column elementor-col-40 elementor-inner-column elementor-element elementor-element-41a1437 btn-contribuer-footer"
                                                        data-id="eb029b3" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                                className="elementor-element elementor-element-cd4d1d3 elementor-mobile-align-center elementor-widget elementor-widget-heading"
                                                                data-id="cd4d1d3" data-element_type="widget" data-widget_type="heading.default">
                                                                <div className="elementor-widget-container">
                                                                    <h4 className="elementor-heading-title elementor-size-default">
                                                                    Liens rapides</h4>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-433432e elementor-nav-menu__align-left elementor-nav-menu-mobile__align-center elementor-nav-menu--indicator-none elementor-widget elementor-widget-opal-nav-menu"
                                                                data-id="433432e" data-element_type="widget"
                                                                data-settings="{&quot;layout&quot;:&quot;vertical&quot;}"
                                                                data-widget_type="opal-nav-menu.default">
                                                                <div className="elementor-widget-container">
                                                                    <nav data-submenusminwidth="50" datasubmenusmaxwidth="500"
                                                                        className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-none">
                                                                        <ul id="menu-footer-about" className="elementor-nav-menu sm-vertical">
                                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4930">
                                                                                <a href="https://n3ichob7alnass.intelcia-solutions.com/" className="elementor-item">Accueil</a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4927">
                                                                                <a href="https://n3ichob7alnass.intelcia-solutions.com/a-propos/" className="elementor-item">À propos</a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4930">
                                                                                <Link to="/" className="elementor-item">Cagnottes</Link>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4928">
                                                                                <a href="https://n3ichob7alnass.intelcia-solutions.com/media/" className="elementor-item">Média</a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4929">
                                                                                <a href="https://n3ichob7alnass.intelcia-solutions.com/contact/" className="elementor-item">Contact</a>
                                                                            </li>
                                                                        </ul>
                                                                    </nav>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="elementor-column elementor-col-40 elementor-inner-column elementor-element elementor-element-41a1437"
                                                        data-id="41a1437" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-e2d21b4 elementor-mobile-align-center elementor-widget elementor-widget-heading"
                                                                data-id="e2d21b4" data-element_type="widget" data-widget_type="heading.default">
                                                                <div className="elementor-widget-container">
                                                                    <h4 className="elementor-heading-title elementor-size-default">
                                                                        Nous rejoindre</h4>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-f202037 elementor-social-custom elementor-shape-rounded elementor-widget elementor-widget-social-icons"
                                                                data-id="f202037" data-element_type="widget" data-widget_type="social-icons.default" 
                                                                data-settings="{&quot;layout&quot;:&quot;vertical&quot;}">
                                                                <div className="elementor-widget-container m-0">
                                                                    <div className="elementor-social-icons-wrapper">
                                                                        <a className="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-animation-grow custom_display_social_icon_footer"
                                                                           href="https://www.instagram.com/darib7alnass/"
                                                                           rel="noreferrer" target="_blank">
                                                                            <span
                                                                                className="elementor-screen-only">Instagram</span>
                                                                            <i className="fa fa-instagram"></i>
                                                                        </a>
                                                                        <a className="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-animation-grow custom_display_social_icon_footer"
                                                                           href="https://www.facebook.com/DariB7alNass/"
                                                                           rel="noreferrer" target="_blank">
                                                                            <span
                                                                                className="elementor-screen-only">Facebook</span>
                                                                            <i className="fa fa-facebook"></i>
                                                                        </a>
                                                                        <a className="elementor-icon elementor-social-icon elementor-social-icon-whatsapp elementor-animation-grow"
                                                                           href="https://wa.me/212652234748"
                                                                           rel="noreferrer" target="_blank">
                                                                            <span
                                                                                className="elementor-screen-only">Whatsapp</span>
                                                                            <i className="fa fa-whatsapp"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-f8efd59"
                                        data-id="f8efd59" data-element_type="column">
                                        <div className="elementor-widget-wrap">
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section
                                className="elementor-section elementor-top-section elementor-element elementor-element-0b4cce4 elementor-section-content-middle elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="0b4cce4" data-element_type="section"
                                data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                                <div className="elementor-container elementor-column-gap-no">
                                    <div
                                        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-aabf82c"
                                        data-id="aabf82c" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-398c5d0 elementor-widget elementor-widget-copyright"
                                                data-id="398c5d0" data-element_type="widget"
                                                data-settings="{&quot;align_mobile&quot;:&quot;center&quot;}"
                                                data-widget_type="copyright.default">
                                                <div className="elementor-widget-container">
                                                    <div className="hfe-copyright-wrapper">
                                                        <span>Copyright © 2022 #N3ichoB7alNass. Tous droits
                                                            réservés</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-47ccd9c"
                                        data-id="47ccd9c" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-709c0e4 elementor-widget elementor-widget-text-editor"
                                                data-id="709c0e4" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                        <p>À la mémoire de Amine Benslimane 1970-2019</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer;