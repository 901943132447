import axios from "axios"
import endpoints from '../endpoints';

const postContributeurConfirmResetPwd = async (params) => {
    try {
        return await axios({
            method: 'post',
            url: endpoints.urlContributeurConfirmResetPassword(),
            data: params
        });
    } catch (error) {
        console.error(error);
        return false;
    }
}

export default postContributeurConfirmResetPwd;