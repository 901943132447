import endpoints from '../endpoints';

const updateContribution = async (contribution_id, params, axiosPrivate) => {
    try {
        return await axiosPrivate.put(endpoints.urlgetContributionById(contribution_id), {
            paymentValide: params?.paymentValide,
            motifRejet: params?.motifRejet
        });
    } catch (error) {
        console.error(error);
        return false;
    }
}

export default updateContribution;