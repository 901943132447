import FacebookLogin from "react-facebook-login"
import { useNavigate, useOutletContext } from "react-router-dom";
import {getContributeurByEmail, postContributeur, postLoginContributeur} from "../api/queries";
import { SocialIcon } from 'react-social-icons';

const Facebook = () => {
    const [connected, setConnected] = useOutletContext();
    const navigate = useNavigate();
    const saveToken = (params) => {
        sessionStorage.setItem('token', JSON.stringify(params?.token));
        sessionStorage.setItem('isLogged', true);
        sessionStorage.setItem('login', JSON.stringify(params?.data?.login));
        sessionStorage.setItem('email', JSON.stringify(params?.data?.email));
        sessionStorage.setItem('user_data', JSON.stringify(params?.data));
    };

    const responseFacebook = async (response) => {

            try {
                // Quand l'utilisateur est authentifié via Facebook il n'ya pas de status dans la reponse
                if(typeof response?.status === 'undefined') {

                    sessionStorage.setItem('fb_user', JSON.stringify(response));

                    // On vérifie si un contributeur possédant la même adresse e-mail existe déja dans la BD
                    let result = await getContributeurByEmail(response.email);

                    if (result?.data?.["hydra:member"].length === 0) {
                        // Sinon on l'enregistre dans la base de données

                        let paramsFbUser = {
                            login: response.email,
                            roles: ["CONTRIBUTEUR_INSCRIT"],
                            password: response.userID,
                            nomContributeur: response.name,
                            prenomContributeur: '',
                            emailContributeur: response.email,
                            telContributeur: '',
                            paysContributeur: '',
                            villeContributeur: null,
                            modeInscription: 'FACEBOOK'
                        }

                        await postContributeur(paramsFbUser);

                        let paramsFbUserLogin = {
                            username: response.email,
                            password: response.userID,
                        }

                        const responsePostLogin = await postLoginContributeur(paramsFbUserLogin);

                        saveToken(responsePostLogin?.data);
                        // Définition de la connexion
                        setConnected(true);

                    } else {

                        // On proccède à l'authentification JWT

                        let paramsFbUserLogin = {
                            username: response.email,
                            password: response.userID,
                        }

                        const responsePostLogin = await postLoginContributeur(paramsFbUserLogin);

                        saveToken(responsePostLogin?.data);
                        // Définition de la connexion
                        setConnected(true);
                    }
                }
            } catch (err) {
                console.log(err)
                return false;
            }
    }

    function handleFbLogin(){
        let fbContent

        if(connected === true){
            navigate("/");
        } else {
            fbContent = (
                <FacebookLogin
                    appId="484196906725738"
                    autoLoad={true}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    cssClass="rs_login_button_style"
                    textButton=""
                    icon={<SocialIcon network="facebook" style={{ height: 40, width: 40 }}/>}
                />
            )
        }

        return (
            fbContent
        )
    }

    return (
        <>
            {
                handleFbLogin()
            }
        </>
    )
}

export default Facebook;
