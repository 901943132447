import endpoints from '../endpoints';
const controller = new AbortController();

const deleteDisponibiliteAmbassadeur = async (id_ambassadeur, axiosPrivate, location, navigate) => {

    try {
        const response = await axiosPrivate.delete(endpoints.urlDeleteDisponibiliteAmbassadeur(id_ambassadeur), {
            signal: controller.signal
        });
        return response.data;
    } catch (err) {
        if (err?.response?.status === 403 || err?.response?.status === 401) {
            navigate('/auth', {state: {from: location}, replace: true});
        }
    }
}

export default deleteDisponibiliteAmbassadeur;