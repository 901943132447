import endpoints from '../endpoints';
const controller = new AbortController();

const getContributionById = async (contribution_id, axiosPrivate, location, navigate) => {

    try {
        return await axiosPrivate.get(endpoints.urlgetContributionById(contribution_id), {
            signal: controller.signal
        });
    } catch (err) {
        if (err?.response?.status === 403 || err?.response?.status === 401) {
            navigate('/auth', {state: {from: location}, replace: true});
        }
    }
}

export default getContributionById;