import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import {useState} from "react";
import InscriptionContributeur from "../../pages/InscriptionContributeur";

const InscriptionContributeurModal = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function handleBoutonInscrire() {
        let isLoggedValue = JSON.parse(sessionStorage.getItem('isLogged'))
        if(isLoggedValue === false || isLoggedValue === null){
            if(props.locationPathName === '/authentification'){
                // Si le contributeur n'est pas authentifié on affiche le bouton s'inscrire
                return(
                    <Button className="btn btn-primary btn-block w-100 mt-1" onClick={handleShow}>
                        S'inscrire
                    </Button>
                )
            } else {
                // Si le contributeur n'est pas authentifié on affiche le bouton s'inscrire
                return(
                    <Button className="btn-link" onClick={handleShow}>
                        S'inscrire
                    </Button>
                )
            }
        }
    }

    return (
        <>
            {
                handleBoutonInscrire()
            }

            <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>Création du compte contributeur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InscriptionContributeur
                        setShowFormAfterInscrireFunction={props.setShowFormAfterInscrireFunc}
                        successContributeurInscritBis2={props.successContributeurInscritBis}
                        villes={props.villes}
                        locationPathName={props.locationPathName}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default InscriptionContributeurModal;