import {useEffect, useRef, useState } from "react";
import getFeedback from "../../api/queries/getFeedback";
import updateFeedback from "../../api/queries/updateFeedback";
import {useNavigate, useLocation} from "react-router-dom";
import RejetValidationFeedback from "../RejetValidationFeedback";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const FeedbackFormValidation = () => {
    const [feedbackMsg, setFeedbackMsg] = useState('');
    const [feedbackAValiderId, setfeedbackAValiderId] = useState('');
    const [contributeurNom, setContributeurNom] = useState('');
    const [contributeurPrenom, setContributeurPrenom] = useState('');
    const [cagnotteTitre, setCagnotteTitre] = useState('');
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const [succesMsg, setSuccesMsg] = useState('');
    const [updateFeedbackStatut, setUpdateFeedbackStatut] = useState(false);
    const [updateFeedbackStatutFromParent, setUpdateFeedbackStatutFromParent] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const [feedbackValide, setFeedbackValide] = useState(1);
    const [feedbackRejete, setFeedbackRejete] = useState(2);

    const getFeedbackData = async () => {
        try {
            let feedback_a_valider_id = localStorage.getItem('feedback_a_valider_id');
            setfeedbackAValiderId(feedback_a_valider_id);
            setContributeurNom(localStorage.getItem('contributeur_nom'));
            setContributeurPrenom(localStorage.getItem('contributeur_prenom'));
            setCagnotteTitre(localStorage.getItem('cagnotte_titre'));
            let currentFeedback = await getFeedback(feedback_a_valider_id, axiosPrivate, location, navigate);
            setFeedbackMsg(currentFeedback?.message);
            setFeedbackValide(currentFeedback?.valide);
            setFeedbackRejete(currentFeedback?.valide);
        } catch (err) {
            console.log(err)
            if (!err?.response) {
                setErrMsg('Une erreur est survenue');
            }
        }
    }

    useEffect(() => {
        getFeedbackData();
    })

    const handleSubmit = async (e) => {

        e.preventDefault();
        setSubmitSuccess(true);
        try {

            let params = {
                valide: 1
            }

            let updateFeedbackResponse = await updateFeedback(feedbackAValiderId, params, axiosPrivate, location, navigate);

            if (updateFeedbackResponse?.status === 200) {
                setUpdateFeedbackStatut(true);
            }

            //clear state and controlled inputs
            setFeedbackMsg('');
            setSuccesMsg('La dédicace a été validé avec succès!');
            sessionStorage.setItem('feedbackSuccesMsgFromParent', 'La dédicace a été validé avec succès!');
            setSubmitSuccess(false);
            onGotoFeedbackAValider();

        } catch (err) {
            console.error(err)
            if (!err?.response) {
                setErrMsg('Une erreur est survenue');
            } else {
                setErrMsg("L'enregistrement a echoué")
            }
        }
    }

    function onGotoFeedbackAValider() {
        navigate(`/contributions/validation-feedbacks`);
    }

    return (

        <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
            <div
                className={succesMsg ? "alert alert-success alert-dismissable" : "offscreen"}>
                <button aria-hidden="true" data-dismiss="alert" className="close"
                        type="button">
                </button>
                {succesMsg}
            </div>
            <div
                className={updateFeedbackStatutFromParent ? "alert alert-success" : "offscreen"}>
                Dédicace rejetée avec succès
            </div>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <div className="give-wrap-inner">
                <div id="give-form-62-content"
                     className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">

                    <div className="summary entry-summary">
                        {/* form contribution */}
                        <div id="give-form-62-wrap" className="give-form-wrap give-display-onpage"
                             style={{paddingBottom: "80px"}}>
                            <form name="give-forrem-62-1" id="give-forrem-62-1"
                                  className="give-form give-form-62 give-form-type-multi">
                                <h1>{cagnotteTitre}</h1>
                                <div className="form-group">
                                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}
                                       aria-live="assertive">{errMsg}</p>
                                    <label htmlFor="exampleFormControlTextarea1">Dédicace du contributeur {contributeurNom + " " + contributeurPrenom}</label>
                                    <p>{feedbackMsg}</p>
                                </div>
                                <button
                                    id="return_to_feedback_list_a_valider"
                                    name="return_to_feedback_list_a_valider"
                                    onClick={() => onGotoFeedbackAValider()}
                                    type="button"
                                    className="give-submit give-btn pull-left valider_contribution_details_for_validation_style"
                                >Retour
                                </button>
                                <div className="pull-right" role="group">
                                    <button
                                        type="button"
                                        onClick={(e) => handleSubmit(e)}
                                        className={updateFeedbackStatut || updateFeedbackStatutFromParent || feedbackValide === 1 ? "hide" : "give-submit give-btn pull-right valider_contribution_details_for_validation_style"}
                                        disabled={submitSuccess ? true : false}
                                    > Valider
                                    </button>
                                    <RejetValidationFeedback feedbackAValiderId={feedbackAValiderId}
                                                             updateFeedbackStatut={updateFeedbackStatut}
                                                             setUpdateFeedbackStatutFromParent={setUpdateFeedbackStatutFromParent}
                                                             feedbackRejete={feedbackRejete}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedbackFormValidation;