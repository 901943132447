import {useState, useEffect} from "react";
import {getParticipationAmbassadeurCagnotte} from '../../api/queries';
import './Progress.css'

const FirstItem = (props) => {

    const [listAmbassadeur, setListAmbassadeur] = useState([]);
    const [valeurMontant, setValeurMontant] = useState(250);
    const [nombreCouponRequired, setNombreCouponRequired] = useState(true);
    const [ambassadeurRequired, setAmbassadeurRequired] = useState(false);

    const [selectAmbassadeur, setSelectAmbassadeur] = useState(props.config.ambassadeur)

    const handleCalculMontant = (e) => {
        props.config.nbCoupons = e.target.value;
        setValeurMontant(250 * e.target.value);
    }

    function handleNombreCoupon(e) {
        if (e.target.value === "") {
            setNombreCouponRequired(false);
        } else {
            localStorage.setItem('nombreCoupon', e.target.value);
            setNombreCouponRequired(true);
            handleCalculMontant(e);
        }
    }

    const handleAmbassadeur = async (e) => {
        if (e.target.value === "") {
            setAmbassadeurRequired(false);
            setSelectAmbassadeur(e.target.value);
        } else {
            setSelectAmbassadeur(e.target.value);
            props.config.ambassadeur = e.target.value;
            localStorage.setItem('ambassadeur_id', e.target.value);
            setAmbassadeurRequired(true);
        }
    }

    const renderListAmbassadeur = () => {
        return listAmbassadeur.map((el, index) => (
            <option key={index} value={el.ambassadeur.id}>{el.ambassadeur.nom + " " + el.ambassadeur.prenom + " ( " + el.ambassadeur.rVille?.nomVille + " ) "}</option>));
    }

    useEffect(() => {
        let isMounted = true;
        const loadPage = async () => {
            try{
                if(isMounted && props.cagnotteCurrent.id){
                    const listAmbass = await getParticipationAmbassadeurCagnotte(props.cagnotteCurrent.id);
                    setListAmbassadeur(listAmbass?.["hydra:member"]);
                    props.config.ambassadeur !== "" ? setAmbassadeurRequired(true) : setAmbassadeurRequired(false);
                }
            } catch(err){
                console.log(err);
            }
        }

        loadPage();

        return () => (isMounted = false)

    }, [props.cagnotteCurrent]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id="give_purchase_form_wrap">
            {/* pogress bar */}
            <div className="progress-segment">
                <div className="progresso">
                    <div className="step-name etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero" style={{color: "#000"}} >1</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p >Choisir une cagnotte <br/>de collecte en cours</p>
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso-active">
                    <div className="step-name-active etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero-active">2</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p style={{color: "#000"}}> Choisir le nombre de coupons<br/> et l’ambassadeur de la dignité</p>
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso-active-mobile">
                    <div className="step-name-active etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero-active">2</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            Choisir le nombre de coupons et l’ambassadeur de la dignité
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso">
                    <div className="step-name etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero">3</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p >S'identifier, contribuer et<br/> récupérez vos coupons</p>
                        </div>
                    </div>
                    <div className="item"></div>
                </div>

                <div className="progresso">
                    <div className="step-name etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero">4</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p >Dédier la contribution<br/>à une âme chère</p>
                        </div>
                    </div>
                    <div className="item"></div>
                </div>
            </div>

            <div className="col-md-8 d-flex mx-auto my_custom_flex_center" style={{"background": "white"}}>
                <div className="my_custom_flex_center_col">
                    <fieldset id="give_checkout_user_info" className="col-md-7 mt-5">
                        {/*<legend>Contribution à la cagnotte</legend>*/}
                        {/* Choix du nombre de coupons */}
                        <div id="give-first-name-wrap" className="form-row form-row-wide">
                            <label className="give-label" htmlFor="give-first"> Nombre de coupons (250 DH / Unité)
                                <span className="give-required-indicator"> *</span>
                            </label>
                            <input defaultValue={props.config.nbCoupons} className="give-input" type="number"
                                   id="quantity" name="quantity" min="1"
                                   onChange={(e) => handleNombreCoupon(e)} aria-required="true"
                                   aria-describedby="quantitenote"
                            />
                        </div>

                        {/* Choix de l’ambassadeur de la dignité */}
                        <div id="give-last-name-wrap" className="form-row form-row-wide">
                            <label className="give-label" htmlFor="give-last"> Ambassadeur de la dignité
                                <span className="give-required-indicator"> *</span>
                            </label>
                            <select id="ambassadeur" name="ambassadeur" className="give-input required"
                                    value={selectAmbassadeur}
                                    onChange={(e) => handleAmbassadeur(e)} aria-required="true"
                                    aria-describedby="ambassadeurnote"
                            >
                                <option value="">Sélectionner l'ambassadeur de la dignité</option>
                                {renderListAmbassadeur()}
                            </select>
                        </div>
                        <p style={{color: "crimson", "marginBottom": "5px", "marginTop": "-10px", fontSize:"70%"}}> Les champs marqués par (*) sont
                            obligatoires</p>
                        <p id="give-final-total-wrap" className="form-wrap">
                            <span className="give-donation-total-label"> Total de la contribution : </span>
                            <span className="give-final-total-amount" data-total="100.00"> {valeurMontant} DH </span>
                        </p>
                    </fieldset>
                </div>
            </div>

            <fieldset id="give_purchase_submit" className="give-donation-submit">
                <div className="give-submit-button-wrap give-clearfix pull-right">
                    <button disabled={!nombreCouponRequired || !ambassadeurRequired ? true : false}
                            onClick={(e) => props.submitNext(e)} className="give-submit give-btn">Suivant
                    </button>
                </div>
            </fieldset>
        </div>
    )
}

export default FirstItem;
