import {useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { getParticipationCagnotteByAdministrateur } from '../api/queries';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const ParticipationAmbassadeurCagnottes = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    let isLogged = JSON.parse(sessionStorage.getItem('isLogged')) ?? null;
    let [listeParticipationCagnotte, setListeParticipationCagnotte] = useState([]);
    const {SearchBar} = Search;
    const [columns] = useState([{
        dataField: 'cagnotte.titre',
        text: 'LIBELLE CAGNOTTE'
    },{
        dataField: 'cagnotte.dateDebutCagnotte',
        text: 'DATE DEBUT'
    },{
        dataField: 'cagnotte.dateFinCagnotte',
        text: 'DATE FIN'
    }, {
        dataField: 'cagnotte.cloture',
        text: 'STATUT DE LA CAGNOTTE',
        formatter: statusFormatter
    },
    ]);

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            De {from} à {to} sur {size} résultat(s)
        </span>
    );

    function statusFormatter(cell, row) {
        if (cell) {
            return <span className="badge badge-success">Clôturée</span>;
        }
        return (
            <button type="button" className="btn btn-light">
                En cours <span className="badge badge-warning"> { row?.cagnotte?.progresValide.toFixed(2) } %</span>
            </button>
        )        
    }

    const [options] = useState({
        pageStartIndex: 1,
        paginationSize: 5,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: false, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
        firstPageText: 'Premier',
        prePageText: 'Retour',
        nextPageText: 'Suivant',
        lastPageText: 'Dernier',
        nextPageTitle: 'Première page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Page suivante',
        lastPageTitle: 'Dernière page',
        paginationTotalRenderer: customTotal,
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '30', value: 30
        }, {
            text: '50', value: 50
        }, {
            text: 'Tout', value: listeParticipationCagnotte.length
        }] // A numeric array is also available. the purpose of above example is custom the text
    });

    useEffect(() => {
        const loadData = async () => {
            if (isLogged) {
                // On récupère son rôle
                let user_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles
                // Si la personne connecté est un ambassadeur
                if (user_role?.includes("ROLE_AMBASSADEUR")) {
                    let user_id = JSON.parse(sessionStorage.getItem('user_data'))?.id;
                    // Affichage de la liste des contributions à valider
                    const resp = await getParticipationCagnotteByAdministrateur(user_id, axiosPrivate, location, navigate);
                    setListeParticipationCagnotte(resp?.["hydra:member"]);
                }
            }
        }

        loadData();
    }, [isLogged, axiosPrivate, location, navigate])

    return(
        <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
            <div className="give-wrap-inner">
                <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">

                    <div className="summary entry-summary">
                        <div className="card mb-5 custom_card_style_1">
                            <h5 className="card-header text-center custom_card_style_2"> Mes participations</h5>
                            <div className="card-body">                                  
                                { listeParticipationCagnotte?.length > 0 ?
                                    <ToolkitProvider keyField="id"
                                        data={listeParticipationCagnotte}
                                        columns={columns} search={{defaultSearch: '', searchFormatted: true}}
                                    >
                                        {
                                            props => (
                                                <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                                    <div style={{paddingRight: "0px"}} className='col-md-4 pull-right'>
                                                        <SearchBar style={{"borderRadius": "0.5rem"}} placeholder="Rechercher" {...props.searchProps} />
                                                    </div>

                                                    <hr/>
                                                    <BootstrapTable {...props.baseProps} pagination={paginationFactory(options)} classes="fix-responsive-width" />
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider> : "Aucune participation."
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ParticipationAmbassadeurCagnottes;