import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'

function ProgressBarHorizontal(props) {

    return (
        <ProgressBar now={parseInt(props.cagnotteCurrent.progresValide)} label={`${parseInt(props.cagnotteCurrent.progresValide)}%`} />
    );
}

export default ProgressBarHorizontal;