import React from 'react'
import { useEffect, useState } from "react";
import getStatistiques from '../../api/queries/getStatistiques'
import './Statistiques.css';

const Statistiques = () => {
    let [statistiques, setStatistiques] = useState([]);
    useEffect(() => {
        const getListe = async () => {
            const resp = await getStatistiques();
            setStatistiques(resp);
            console.log(resp);
        }
        getListe();

    }, [])
    return (

        <div className="elementor-element elementor-element-56a85d1 elementor-widget elementor-widget-shortcode body" data-id="56a85d1" data-element_type="widget" data-widget_type="shortcode.default">
            <div className="elementor-widget-container">
                <div className="elementor-shortcode">
                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-448094f elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                        data-id="448094" data-element_type="section">
                        <div className="elementor-container elementor-column-gap-no">

                            <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-5b7a130 stat"
                                data-id="5b7a130" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated marg"
                                >
                                    <div className="elementor-element elementor-element-53f6e9c elementor-position-left elementor-vertical-align-middle elementor-widget-mobile__width-initial elementor-view-default icon-box-default elementor-widget elementor-widget-icon-box"
                                        data-id="53f6e9c" data-element_type="widget" data-widget_type="icon-box.default">
                                        <div className="elementor-widget-container" style={{ margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}>
                                            <div className="elementor-icon-box-wrapper"
                                                style={{ minHeight: "0px", backgroundColor: "#FFFFFF", borderRadius: "20px 20px 20px 20px", padding: "15px 20px 25px 20px", textAlign: "left" }}>
                                                <div className="elementor-icon-box-icon" style={{ marginRight: "11px" }}>
                                                    <span className="elementor-icon elementor-animation-">
                                                        <i className="opal-icon- opal-icon-family opal-custom" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                                <div className="elementor-icon-box-content">
                                                    <h3 className="elementor-icon-box-title" style={{ fontSize: "40px", marginBottom: "10px" }}>
                                                        <span>{statistiques.nbCagnottes}</span>
                                                    </h3>
                                                    <p className="elementor-icon-box-description"><span style={{ fontSize: "1rem" }}>Rêves réalisés</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-e5ff80b stat"
                                data-id="e5fgf80b" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated marg"
                                >
                                    <div className="elementor-element elementor-element-91e1bbe elementor-position-left elementor-vertical-align-middle elementor-widget-mobile__width-initial elementor-view-default icon-box-default elementor-widget elementor-widget-icon-box"
                                        data-id="91e1bbe" data-element_type="widget" data-widget_type="icon-box.default">
                                        <div className="elementor-widget-container" style={{ margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}>
                                            <div className="elementor-icon-box-wrapper"
                                                style={{ minHeight: "0px", backgroundColor: "#FFFFFF", borderRadius: "20px 20px 20px 20px", padding: "15px 20px 25px 20px", textAlign: "left" }}>
                                                <div className="elementor-icon-box-icon" style={{ marginRight: "11px" }}>
                                                    <span className="elementor-icon elementor-animation-">
                                                        <i className="opal-icon- opal-icon-users opal-custom" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                                <div className="elementor-icon-box-content">
                                                    <h3 className="elementor-icon-box-title" style={{ fontSize: "40px", marginBottom: "10px" }}>
                                                        <span>{statistiques.nbContributeurs}</span>
                                                    </h3>
                                                    <p className="elementor-icon-box-description">
                                                        <span style={{ fontSize: "1rem" }}>Contributeurs<br /><br /></span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-76bbc7b stat"
                                data-id="76bbc7b" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated marg"
                                >
                                    <div className="elementor-element elementor-element-3c89999 elementor-position-left elementor-vertical-align-middle elementor-widget-mobile__width-initial elementor-view-default icon-box-default elementor-widget elementor-widget-icon-box"
                                        data-id="3c89999" data-element_type="widget" data-widget_type="icon-box.default">
                                        <div className="elementor-widget-container" style={{ margin: "0px 0px 0px 0px", padding: " 0px 0px 0px 0px" }}>
                                            <div className="elementor-icon-box-wrapper"
                                                style={{ minHeight: "0px", backgroundColor: "#FFFFFF", borderRadius: "20px 20px 20px 20px", padding: "15px 20px 25px 20px", textAlign: "left" }}>
                                                <div className="elementor-icon-box-icon" style={{ marginRight: "11px" }}>
                                                    <span className="elementor-icon elementor-animation-">
                                                        <i className="opal-icon- opal-icon-68k opal-custom" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                                <div className="elementor-icon-box-content">
                                                    <h3 className="elementor-icon-box-title" style={{ fontSize: "40px", marginBottom: "10px" }}>
                                                        <span>{statistiques.nbBeneficiaire}</span>
                                                    </h3>
                                                    <p className="elementor-icon-box-description"><span style={{ fontSize: "1rem" }}>Familles
                                                        bénéficiaires</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-5b7a130 stat"
                                data-id="5b7a130" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated marg"
                                >
                                    <div className="elementor-element elementor-element-53f6e9c elementor-position-left elementor-vertical-align-middle elementor-widget-mobile__width-initial elementor-view-default icon-box-default elementor-widget elementor-widget-icon-box"
                                        data-id="53f6e9c" data-element_type="widget" data-widget_type="icon-box.default">
                                        <div className="elementor-widget-container" style={{ margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}>
                                            <div className="elementor-icon-box-wrapper"
                                                style={{ minHeight: "0px", backgroundColor: "#FFFFFF", borderRadius: "20px 20px 20px 20px", padding: "15px 20px 25px 20px", textAlign: "left" }}>
                                                <div className="elementor-icon-box-icon" style={{ marginRight: "11px" }}>
                                                    <span className="elementor-icon elementor-animation-">
                                                        <i className="opal-icon- opal-icon-family opal-custom" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                                <div className="elementor-icon-box-content">
                                                    <h3 className="elementor-icon-box-title" style={{ fontSize: "40px", marginBottom: "10px" }}>
                                                        <span>{statistiques.nbEnfantBeneficiaire}</span>
                                                    </h3>
                                                    <p className="elementor-icon-box-description"><span style={{ fontSize: "1rem" }}>Enfants
                                                        bénéficiaires</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-87b27e9 stat"
                                data-id="87b27e9" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated marg"
                                >
                                    <div className="elementor-element elementor-element-caf8efb elementor-position-left elementor-vertical-align-middle elementor-widget-mobile__width-initial elementor-view-default icon-box-default elementor-widget elementor-widget-icon-box"
                                        data-id="caf8efb" data-element_type="widget" data-widget_type="icon-box.default">
                                        <div className="elementor-widget-container" style={{ margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}>
                                            <div className="elementor-icon-box-wrapper"
                                                style={{ minHeight: "0px", backgroundColor: "#FFFFFF", borderRadius: "20px 20px 20px 20px", padding: "15px 20px 25px 20px", textAlign: "left" }}>
                                                <div className="elementor-icon-box-svg">
                                                    <img style={{ height: "50px", width: "60px" }} src="http://n3ichob7alnass.intelcia-solutions.com/wp-content/uploads/2022/07/family-plus.png" alt="" />
                                                </div>
                                                <div className="elementor-icon-box-content">
                                                    <h3 className="elementor-icon-box-title" style={{ fontSize: "40px", marginBottom: "10px" }}>
                                                        <span>{statistiques.nbBeneficiaireAttente}</span>
                                                    </h3>
                                                    <p className="elementor-icon-box-description">Familles en attente<br /></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </div>

    )
}

export default Statistiques