import {useNavigate, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import getFeedbacksCagnotteByAmbassadeur from "../api/queries/getFeedbacksCagnotteByAmbassadeur";
import paginationFactory from "react-bootstrap-table2-paginator";
import '../Contribution.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const ValidationFeedbacks = () => {

    let isLogged = JSON.parse(sessionStorage.getItem('isLogged')) ?? null;
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    let [feedbackSuccesMsgFromParent] = useState(sessionStorage.getItem('feedbackSuccesMsgFromParent') ?? null);
    let [feedbackRejetMsgFromParent] = useState(sessionStorage.getItem('feedbackRejetMsgFromParent') ?? null);
    let [listeFeedbacksCagnotteByAmbassadeur, setListeFeedbacksCagnotteByAmbassadeur] = useState([]);

    const {SearchBar} = Search;
    const navigate = useNavigate();

    const [columns] = useState([{
        dataField: 'cagnotte.titre',
        text: 'LIBELLE CAGNOTTE'
    }, {
        dataField: 'contributeur.nom',
        text: 'CONTRIBUTEUR',
        sort: true
    }, {
        dataField: 'dateCreation',
        text: 'DATE CREATION',
        formatter: dateFormatter
    }
    , {
        dataField: 'valide',
        text: 'STATUT',
            formatter: statusFormatter
    }, {
        dataField: '',
        text: 'ACTIONS',
        formatter: showMenus
    }
    ]);

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
      De {from} à {to} sur {size} résultat(s)
    </span>
    );

    const [options] = useState({
        pageStartIndex: 1,
        paginationSize: 5,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: false, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Retour',
        nextPageText: 'Suivant',
        lastPageText: 'Dernier',
        nextPageTitle: 'Première page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Page suivante',
        lastPageTitle: 'Dernière page',
        paginationTotalRenderer: customTotal,
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '30', value: 30
        }, {
            text: '50', value: 50
        }, {
            text: 'Tout', value: listeFeedbacksCagnotteByAmbassadeur?.length
        }] // A numeric array is also available. the purpose of above example is custom the text
    });

    function dateFormatter(cell, row) {
        return (
            <span> {cell} </span>
        );
    }

    function statusFormatter(cell) {
        if (!cell) {
            return <span className="badge badge-warning">validation en cours</span>;
        } else {

            if (cell === 2) {
                return <span className="badge badge-danger">rejeté</span>;
            }

            return <span className="badge badge-success">validé</span>;
        }
    }

    function onGotoFeedback(feedback_a_valider_id, contributeur_nom, contributeur_prenom, cagnotte_titre) {
        localStorage.setItem('feedback_a_valider_id', feedback_a_valider_id);
        localStorage.setItem('contributeur_nom', contributeur_nom);
        localStorage.setItem('contributeur_prenom', contributeur_prenom);
        localStorage.setItem('cagnotte_titre', cagnotte_titre);
        navigate(`/contributions/feedbacks-a-valider`);
    }

    function showMenus(cell, row) {
        return (
            <>
                <button title="Détail de la dédicace"
                        onClick={() => onGotoFeedback(row.id, row.contributeur.nom, row.contributeur.prenom, row.cagnotte.titre)}
                        type="button"
                        className="btn btn-info btn-sm">
                    <FontAwesomeIcon icon={faSearch}/>
                </button>
            </>
        );
    }

    useEffect(() => {

        const getListeFeedbacksCagnotteByAmbassadeur = async () => {

            // Si une personne est connecté
            if(isLogged === true) {

                // On récupère le rôle de l'utilisateur actuellement connecté
                let user_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles

                // Si la personne connecté est un ambassadeur
                if (user_role?.includes("ROLE_AMBASSADEUR")) {
                    // On récupère son id
                    let user_id = JSON.parse(sessionStorage.getItem('user_data'))?.id;

                    // Affichage de la liste des feedbacks des cagnottes par ambassadeur
                    const resp = await getFeedbacksCagnotteByAmbassadeur(user_id, axiosPrivate, location, navigate);
                    setListeFeedbacksCagnotteByAmbassadeur(resp?.["hydra:member"]);
                }
            }
        }

        getListeFeedbacksCagnotteByAmbassadeur();

        // Si le message de sucess de validation de la contribution existe, le supprimé des sessions storage
        if(feedbackSuccesMsgFromParent){
            sessionStorage.removeItem('feedbackSuccesMsgFromParent');
        }

        if(feedbackRejetMsgFromParent){
            sessionStorage.removeItem('feedbackRejetMsgFromParent');
        }

    }, [isLogged, axiosPrivate, location, navigate, feedbackSuccesMsgFromParent, feedbackRejetMsgFromParent])

    return (
        <div id="content" className="site-content">
            <div className="wrap top-ecart">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <article id="post-1652" className="post-1652 page type-page status-publish hentry">
                                <div className="entry-content">
                                    <div id="give-form-62-wrap" className="give-form-wrap give-display-onpage">
                                        <div
                                            className={feedbackSuccesMsgFromParent ? "alert alert-success alert-dismissable" : "offscreen"}>
                                            {feedbackSuccesMsgFromParent}
                                        </div>
                                        <div
                                            className={feedbackRejetMsgFromParent ? "alert alert-success" : "offscreen"}>
                                            {feedbackRejetMsgFromParent}
                                        </div>

                                        <div className="card mb-5 custom_card_style_1">
                                            <h5 className="card-header text-center custom_card_style_2">
                                                Liste des dédicaces à valider</h5>
                                            <div className="card-body">
                                                {listeFeedbacksCagnotteByAmbassadeur?.length > 0 ?
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        data={listeFeedbacksCagnotteByAmbassadeur}
                                                        columns={columns}
                                                        search={{defaultSearch: '', searchFormatted: true}}
                                                    >
                                                        {
                                                            props => (
                                                                <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                                                    <div className='col-md-4 pull-right'>
                                                                        <SearchBar style={{"borderRadius": "0.5rem"}} placeholder="Rechercher" {...props.searchProps} />
                                                                    </div>

                                                                    <hr/>
                                                                    <BootstrapTable
                                                                        {...props.baseProps}
                                                                        pagination={paginationFactory(options)}
                                                                        bordered={ false }
                                                                        classes="fix-responsive-width"
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </ToolkitProvider> : "Aucune dédicace à valider."
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </article>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default ValidationFeedbacks;