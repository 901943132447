import endpoints from '../endpoints';
const controller = new AbortController();

const deleteAmbassadeur = async (ambassadeur_id, axiosPrivate, location, navigate) => {

    try {
        return await axiosPrivate.delete(endpoints.urlGetAmbassadeurById(ambassadeur_id), {
            signal: controller.signal
        });
    } catch (err) {
        if (err?.response?.status === 403 || err?.response?.status === 401) {
            navigate('/auth', {state: {from: location}, replace: true});
        }
    }
}

export default deleteAmbassadeur;