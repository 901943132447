import axios from "axios"
import endpoints from '../endpoints'

const getFourCagnottes = async (page = 1, status = 1) => {
    try {
        const response = await axios({
            method: 'get',
            url: endpoints.urlGetFourCagnottes(page, status)
        });
        return response.data;

    } catch (error) {
        console.error(error);
        return false;
    }
}

export default getFourCagnottes;