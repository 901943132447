import { useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext, Link} from 'react-router-dom';

import DisplayCard from '../components/DisplayCard';
import { getCagnottes, getThemes, getContributeurByEmail, getCagnottesProgrammees } from '../api/queries';
import LoadingSpinner from '../components/LoadingSpinner';

const Cagnottes = () => {
    const navigate = useNavigate();
    const [connected] = useOutletContext();
    const [loading, setLoading] = useState(false);
    let [listCagnotteNotCloturee, setListCagnotteNotCloturee] = useState([]);
    let [listCagnotteCloturee, setListCagnotteCloturee] = useState([]);
    let [listCagnotteNotClotureeByAmbassadeur, setListCagnotteNotClotureeByAmbassadeur] = useState([]);
    let [listCagnotteClotureeByAmbassadeur, setListCagnotteClotureeByAmbassadeur] = useState([]);
    let [listCagnotteProgrammeesByAmbassadeur, setListCagnotteProgrammeesByAmbassadeur] = useState([]);
    let [roleAmbassadeur, setRoleAmbassadeur] = useState(false);
    let [listThemes, setListThemes] = useState([]);
    let [themeValue, setThemevalue] = useState("");
    let [statutValue, setStatutValue] = useState("");
    let [dateStart, setDateStart] = useState("");
    let [dateEnd, setDateEnd] = useState("");
    let [aucuneCagnotteEnCours, setAucuneCagnotteEnCours] = useState(false);
    let [aucuneCagnotteCloturees, setAucuneCagnotteCloturees] = useState(false);
    let [aucuneCagnotteProgrammees, setAucuneCagnotteProgrammees] = useState(false);
    const [isValidForm, setIsValidForm] = useState("");
    const inputDateInf = useRef("")
    const inputDateFin = useRef("")

    const renderDisplayComponent = (data) => {
        return data?.map((el) => <DisplayCard data={el} key={el.id}/>)
    }

    const renderListThemes = () => {
            return listThemes?.map((el, index) => (<option key={index} value={el.id}>{el.libelle}</option>));
    }

    const handleChangeTheme = (e) => { setThemevalue(e.target.value) }
    const handleChangeStatut = (e) => { setStatutValue(e.target.value) }
    const handleChangeDateStart = () => { setDateStart(inputDateInf.current.value) }
    const handleChangeDateEnd = () => { setDateEnd(inputDateFin.current.value) }

    const errorMessageForm = () => {
        return (<div className="alert alert-danger" role="alert"> Veuillez selectionner un critère de recherche </div>)
    }

    const voirPlusListCagnotteNotClotureeByAmbassadeur = (listCagnotteNotClotureeByAmbassadeurParam) => {
        return listCagnotteNotClotureeByAmbassadeurParam?.length > 0 ? (
            <div className="give-submit-button-wrap give-clearfix pull-right">
                <Link to="/cagnottes-en-cours" className="give-submit give-btn">Voir plus</Link>
            </div>) : null
    }

    const voirPlusListCagnotteNotCloturee = (listCagnotteNotClotureeParam) => {
        return listCagnotteNotClotureeParam?.length > 0 ? (
            <div className="give-submit-button-wrap give-clearfix pull-right">
                <Link to="/cagnottes-en-cours" className="give-submit give-btn">Voir plus</Link>
            </div>) : null
    }

    const voirPlusListCagnotteClotureeByAmbassadeur = (listCagnotteClotureeByAmbassadeurParam) => {
        return listCagnotteClotureeByAmbassadeurParam?.length > 0 ? (
            <div className="give-submit-button-wrap give-clearfix pull-right">
                <Link to="/cagnottes-cloturees" className="give-submit give-btn">Voir plus</Link>
            </div>
        ) : null
    }

    const voirPlusListCagnotteProgrammeeByAmbassadeur = (listCagnotteProgrammeesByAmbassadeurParam) => {
        return listCagnotteProgrammeesByAmbassadeurParam?.length > 0 ? (
            <div className="give-submit-button-wrap give-clearfix pull-right">
                <Link to="/cagnottes-programmees" className="give-submit give-btn">Voir plus</Link>
            </div>
        ) : null
    }

    const voirPlusListCagnotteCloturee = (listCagnotteClotureeParam) => {
        return listCagnotteClotureeParam?.length > 0 ? (
            <div className="give-submit-button-wrap give-clearfix pull-right">
                <Link to="/cagnottes-cloturees" className="give-submit give-btn">Voir plus</Link>
            </div>
        ) : null
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (themeValue === "" && statutValue === "" && inputDateInf.current.value === "" && inputDateFin.current.value === "") {
            setIsValidForm(false);
            return;
        }
        setIsValidForm(true);

        let addUrl = '?idTheme=TID&statut=SID&dateDebut=DD&dateFin=DF';
        addUrl = themeValue !== "" ? addUrl.replace('TID', themeValue) : addUrl.replace('idTheme=TID&', '')
        addUrl = statutValue !== "" ? addUrl.replace('SID', statutValue) : addUrl.replace('statut=SID&', '')
        addUrl = dateStart !== "" ? addUrl.replace('DD', dateStart) : addUrl.replace('&dateDebut=DD', '')
        addUrl = dateEnd !== "" ? addUrl.replace('DF', dateEnd) : addUrl.replace('&dateFin=DF', '')

        let datas = {
            url: addUrl,
            theme: themeValue || "",
            statut: statutValue || ""
        }

        navigate('/resultats-de-recherche', { state: datas} );
    }

    useEffect(() => {
        const loadDataInitiate = async () => {
            setLoading(true);
            // En cas d'authentification
            if(connected === true){
                try {
                    // Si la personne connecté est un utilisateur des réseaux sociaux
                    // On récupère son utilisateur correspondant en base de donnée
                    let contributeurFromRs = await getContributeurByEmail(JSON.parse(sessionStorage.getItem('email')));

                    if(contributeurFromRs?.data?.["hydra:member"][0] !== undefined){
                        let user_data = contributeurFromRs?.data?.["hydra:member"][0];
                        sessionStorage.setItem('user_data', JSON.stringify(user_data));
                    }
                } catch (err) {
                    console.log(err)
                }

                // Récupération du role et de l'id de l'utilisateur connecté
                let user_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles
                // let user_id = JSON.parse(sessionStorage.getItem('user_data'))?.id

                // Si la personne connecté est un ambassadeur
                // On récupère les cagnottes (en cours et cloturées) qui le concerne
                if (user_role?.includes("ROLE_AMBASSADEUR")) {
                    try {
                        // let listCagnotteNotClotureeByAmbassadeurResponse = await getCagnottesByAmbassadeur(1, 0, user_id);
                        // let listCagnotteClotureeByAmbassadeurResponse = await getCagnottesByAmbassadeur(1, 1, user_id);

                        let listCagnotteNotClotureeByAmbassadeurResponse = await getCagnottes(1, 0);
                        let listCagnotteClotureeByAmbassadeurResponse = await getCagnottes(1, 1);
                        let listCagnotteProgrammeesByAmbassadeurResponse = await getCagnottesProgrammees(1, 1);

                        if (listCagnotteClotureeByAmbassadeurResponse?.["hydra:member"].length === 0) {
                            setAucuneCagnotteCloturees(true);
                        }
                        if (listCagnotteNotClotureeByAmbassadeurResponse?.["hydra:member"].length === 0) {
                            setAucuneCagnotteEnCours(true);
                        }

                        if (listCagnotteProgrammeesByAmbassadeurResponse?.["hydra:member"].length === 0) {
                            setAucuneCagnotteProgrammees(true);
                        }

                        setListCagnotteNotClotureeByAmbassadeur(listCagnotteNotClotureeByAmbassadeurResponse?.["hydra:member"]);
                        setListCagnotteClotureeByAmbassadeur(listCagnotteClotureeByAmbassadeurResponse?.["hydra:member"]);
                        setListCagnotteProgrammeesByAmbassadeur(listCagnotteProgrammeesByAmbassadeurResponse?.["hydra:member"]);
                        setRoleAmbassadeur(true)
                    } catch (err) {
                        console.log(err)
                    }
                }

                // Si la personne connecté est un contributeur
                // On récupère les cagnottes (en cours et cloturées) qui le concerne
                if (user_role?.includes("CONTRIBUTEUR_INSCRIT") || user_role?.includes("ROLE_CONTRIBUTEUR")) {
                    try {
                        let listNotCloturee = await getCagnottes(1, 0);
                        let listCloturee = await getCagnottes(1, 1);
                        let listthems = await getThemes();

                        if (listCloturee?.["hydra:member"].length === 0) {
                            setAucuneCagnotteCloturees(true);
                        }
                        if (listNotCloturee?.["hydra:member"].length === 0) {
                            setAucuneCagnotteEnCours(true);
                        }

                        setListCagnotteCloturee(listCloturee?.["hydra:member"]);
                        setListCagnotteNotCloturee(listNotCloturee?.["hydra:member"]);
                        setListThemes(listthems?.["hydra:member"]);
                        setRoleAmbassadeur(false)
                    } catch (err) {
                        console.log(err)
                    }
                }

                if (user_role?.includes("ROLE_ADMIN")) {
                    navigate("/cagnottes/administration");
                }
            } else {
                // Cas des utilisateurs non authentifiés

                try {
                    // liste de toutes les cagnottes
                    let listNotCloturee = await getCagnottes(1, 0);
                    let listCloturee = await getCagnottes(1, 1);
                    let listthems = await getThemes();

                    if (listCloturee?.["hydra:member"].length === 0) {
                        setAucuneCagnotteCloturees(true);
                    }
                    if (listNotCloturee?.["hydra:member"].length === 0) {
                        setAucuneCagnotteEnCours(true);
                    }

                    setListCagnotteCloturee(listCloturee?.["hydra:member"]);
                    setListCagnotteNotCloturee(listNotCloturee?.["hydra:member"]);
                    setListThemes(listthems?.["hydra:member"]);
                    setRoleAmbassadeur(false)
                } catch (err) {
                    console.log(err)
                }
            }
            setLoading(false);
        }

        loadDataInitiate();
    }, [connected, navigate]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div id="give-wrap" className="give-wrap" style={{"position": "relative"}}>
                <div className="give-wrap-inner">
                    <div id="give-form-62-content"
                         className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                        <div className="summary entry-summary top-ecart" style={{"display": "none"}}>
                            <div id="give-form-62-wrap" className="give-form-wrap give-display-onpage">
                                <form onSubmit={(e) => handleSubmit(e)} className="give-form">
                                    <div>
                                        <fieldset>
                                            <legend>RECHERCHE RAPIDE</legend>
                                            <p className="form-row form-row-first form-row-responsive">
                                                <label className="give-label" htmlFor="give-first"> Thème</label>
                                                <select onChange={(e) => handleChangeTheme(e)}
                                                        className="give-input required">
                                                    <option value="">Sélectionner un thème</option>
                                                    {renderListThemes()}
                                                </select>
                                            </p>
                                            <p className="form-row form-row-last form-row-responsive">
                                                <label className="give-label" htmlFor="give-last"> Statut de la
                                                    cagnotte</label>
                                                <select onChange={(e) => handleChangeStatut(e)}
                                                        className="give-input required">
                                                    <option value="">Sélectionner le statut</option>
                                                    <option value="0">En cours</option>
                                                    <option value="1">Cloturée</option>
                                                </select>
                                            </p>
                                            <p className="form-row form-row-first form-row-responsive">
                                                <label className="give-label" htmlFor="give-last"> Date de
                                                    début </label>
                                                <input ref={inputDateInf} onChange={handleChangeDateStart} type="date"
                                                       name="dateDebut" id="dateDebut" defaultValue=""/>
                                            </p>
                                            <p className="form-row form-row-last form-row-responsive">
                                                <label className="give-label" htmlFor="give-last"> Date de fin </label>
                                                <input ref={inputDateFin} onChange={handleChangeDateEnd} type="date"
                                                       name="dateFin" id="dateFin" defaultValue=""/>
                                            </p>
                                        </fieldset>
                                        <fieldset className="give-donation-submit">
                                            {isValidForm === false ? errorMessageForm() : null}
                                            <div className="give-submit-button-wrap give-clearfix pull-right">
                                                <button type="submit" className="give-submit">Rechercher</button>
                                            </div>
                                        </fieldset>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="separator"></div>
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-ce6aa8f elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default top-ecart mt-5"
                data-id="ce6aa8f" data-element_type="section"
                data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">

                <div className="elementor-container elementor-column-gap-no">
                    <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-869dc3e"
                        data-id="869dc3e" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated margin-30 top-ecart">

                            {loading === false ? (
                                <>
                                    {/* Cagnottes en cours */}
                                    <section
                                        className="elementor-section elementor-inner-section elementor-element elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                        data-id="09205ac" data-element_type="section">
                                        <div className="elementor-container elementor-column-gap-no">
                                            <div
                                                className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-09f281c"
                                                data-id="09f281c" data-element_type="column"
                                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div
                                                    className="elementor-widget-wrap elementor-element-populated">
                                                    <div
                                                        className="elementor-element elementor-element-32c4ddb elementor-align-center elementor-widget__width-initial elementor-widget elementor-widget-heading"
                                                        data-id="32c4ddb" data-element_type="widget"
                                                        data-widget_type="heading.default">
                                                        <div className="elementor-widget-container">
                                                            <h2 className="elementor-heading-title elementor-size-default">
                                                                Cagnottes en cours
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <section
                                                        className="elementor-section elementor-inner-section elementor-element elementor-element-448094f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                        data-id="448094f" data-element_type="section">
                                                        <div className={aucuneCagnotteEnCours ? "aucune_cagnotte_cloturees_encours_style" : "elementor-container elementor-column-gap-no"} style={{display: "contents"}}>
                                                                <p className={aucuneCagnotteEnCours ? "" : "hide"}>Aucune cagnotte en cours</p>
                                                            <div className="wrap">
                                                                <div id="primary" className="content-area">
                                                                    <main id="main" className="site-main">
                                                                        <div data-elementor-type="wp-post"
                                                                             data-elementor-id="1997"
                                                                             className="elementor elementor-1997"
                                                                             data-elementor-settings="[]">
                                                                            <div className="elementor-inner">
                                                                                <div className="elementor-section-wrap">
                                                                                    <div
                                                                                        className="elementor-section elementor-top-section elementor-element elementor-element-fe76d22 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                                                        data-id="fe76d22"
                                                                                        data-element_type="section">
                                                                                        <div
                                                                                            className="elementor-container elementor-column-gap-no">
                                                                                            <div
                                                                                                className="elementor-row">
                                                                                                <div
                                                                                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a476304"
                                                                                                    data-id="a476304"
                                                                                                    data-element_type="column">
                                                                                                    <div
                                                                                                        className="elementor-column-wrap elementor-element-populated">
                                                                                                        <div
                                                                                                            className="elementor-widget-wrap">
                                                                                                            <div
                                                                                                                className="elementor-element elementor-element-f0782d5 campain-grid-style-1 elementor-widget elementor-widget-opal-give-campain"
                                                                                                                data-id="f0782d5"
                                                                                                                data-element_type="widget"
                                                                                                                data-widget_type="opal-give-campain.default">
                                                                                                                <div
                                                                                                                    className="elementor-widget-container">
                                                                                                                    <div
                                                                                                                        className="give-wrap">
                                                                                                                        <div
                                                                                                                            className="give-grid give-grid--3">
                                                                                                                            {
                                                                                                                                roleAmbassadeur ? (
                                                                                                                                    renderDisplayComponent(listCagnotteNotClotureeByAmbassadeur)
                                                                                                                                ) : (
                                                                                                                                    renderDisplayComponent(listCagnotteNotCloturee)
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            roleAmbassadeur ? (
                                                                                                                                voirPlusListCagnotteNotClotureeByAmbassadeur(listCagnotteNotClotureeByAmbassadeur)
                                                                                                                            ) : (
                                                                                                                                voirPlusListCagnotteNotCloturee(listCagnotteNotCloturee)
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </main>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>

                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    {/* Cagnottes terminees */}
                                    <section
                                        className={roleAmbassadeur ? "hide" : "elementor-section elementor-inner-section elementor-element elementor-section-boxed elementor-section-height-default elementor-section-height-default"}
                                        data-id="09205ac" data-element_type="section">
                                        <div className="elementor-container elementor-column-gap-no">
                                            <div
                                                className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-09f281c"
                                                data-id="09f281c" data-element_type="column"
                                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div
                                                    className="elementor-widget-wrap">
                                                    <div
                                                        className="elementor-element elementor-element-32c4ddb elementor-align-center elementor-widget__width-initial elementor-widget elementor-widget-heading"
                                                        data-id="32c4ddb" data-element_type="widget"
                                                        data-widget_type="heading.default">
                                                        <div className="elementor-widget-container">
                                                            <h2 className="elementor-heading-title elementor-size-default">
                                                                Cagnottes cloturées
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <section
                                                        className="elementor-section elementor-inner-section elementor-element elementor-element-448094f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                        data-id="448094f" data-element_type="section">
                                                        <div className={aucuneCagnotteCloturees ? "aucune_cagnotte_cloturees_encours_style" : "elementor-container elementor-column-gap-no"} style={{display: "contents"}}>
                                                            <p className={aucuneCagnotteCloturees ? "" : "hide"}>Aucune cagnotte cloturée</p>
                                                            <div className="wrap">
                                                                <div id="primary" className="content-area">
                                                                    <main id="main" className="site-main">
                                                                        <div data-elementor-type="wp-post"
                                                                             data-elementor-id="1997"
                                                                             className="elementor elementor-1997"
                                                                             data-elementor-settings="[]">
                                                                            <div className="elementor-inner">
                                                                                <div className="elementor-section-wrap">
                                                                                    <div
                                                                                        className="elementor-section elementor-top-section elementor-element elementor-element-fe76d22 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                                                        data-id="fe76d22"
                                                                                        data-element_type="section">
                                                                                        <div
                                                                                            className="elementor-container elementor-column-gap-no">
                                                                                            <div
                                                                                                className="elementor-row">
                                                                                                <div
                                                                                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a476304"
                                                                                                    data-id="a476304"
                                                                                                    data-element_type="column">
                                                                                                    <div
                                                                                                        className="elementor-column-wrap elementor-element-populated"
                                                                                                    >
                                                                                                        <div
                                                                                                            className="elementor-widget-wrap">
                                                                                                            <div
                                                                                                                className="elementor-element elementor-element-f0782d5 campain-grid-style-1 elementor-widget elementor-widget-opal-give-campain"
                                                                                                                data-id="f0782d5"
                                                                                                                data-element_type="widget"
                                                                                                                data-widget_type="opal-give-campain.default"

                                                                                                            >
                                                                                                                <div
                                                                                                                    className="elementor-widget-container">
                                                                                                                    <div
                                                                                                                        className="give-wrap">
                                                                                                                        <div
                                                                                                                            className="give-grid give-grid--3">
                                                                                                                            {
                                                                                                                                roleAmbassadeur ? (
                                                                                                                                    renderDisplayComponent(listCagnotteClotureeByAmbassadeur)
                                                                                                                                ) : (
                                                                                                                                    renderDisplayComponent(listCagnotteCloturee)
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            roleAmbassadeur ? (
                                                                                                                                voirPlusListCagnotteClotureeByAmbassadeur(listCagnotteClotureeByAmbassadeur)
                                                                                                                            ) : (
                                                                                                                                voirPlusListCagnotteCloturee(listCagnotteCloturee)
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </main>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    {/* Cagnottes programmées */}
                                    <section
                                        className={roleAmbassadeur ? "elementor-section elementor-inner-section elementor-element elementor-section-boxed elementor-section-height-default elementor-section-height-default" : "hide"}
                                        data-id="09205ac" data-element_type="section">
                                        <div className="elementor-container elementor-column-gap-no">
                                            <div
                                                className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-09f281c"
                                                data-id="09f281c" data-element_type="column"
                                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div
                                                    className="elementor-widget-wrap">
                                                    <div
                                                        className="elementor-element elementor-element-32c4ddb elementor-align-center elementor-widget__width-initial elementor-widget elementor-widget-heading"
                                                        data-id="32c4ddb" data-element_type="widget"
                                                        data-widget_type="heading.default">
                                                        <div className="elementor-widget-container">
                                                            <h2 className="elementor-heading-title elementor-size-default">
                                                                Cagnottes programmées
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <section
                                                        className="elementor-section elementor-inner-section elementor-element elementor-element-448094f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                        data-id="448094f" data-element_type="section">
                                                        <div className={aucuneCagnotteProgrammees ? "aucune_cagnotte_programmees_style" : "elementor-container elementor-column-gap-no"} style={{display: "contents"}}>
                                                            <p className={aucuneCagnotteProgrammees ? "" : "hide"}>Aucune cagnotte programmée</p>
                                                            <div className="wrap">
                                                                <div id="primary" className="content-area">
                                                                    <main id="main" className="site-main">
                                                                        <div data-elementor-type="wp-post"
                                                                             data-elementor-id="1997"
                                                                             className="elementor elementor-1997"
                                                                             data-elementor-settings="[]">
                                                                            <div className="elementor-inner">
                                                                                <div className="elementor-section-wrap">
                                                                                    <div
                                                                                        className="elementor-section elementor-top-section elementor-element elementor-element-fe76d22 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                                                        data-id="fe76d22"
                                                                                        data-element_type="section">
                                                                                        <div
                                                                                            className="elementor-container elementor-column-gap-no">
                                                                                            <div
                                                                                                className="elementor-row">
                                                                                                <div
                                                                                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a476304"
                                                                                                    data-id="a476304"
                                                                                                    data-element_type="column">
                                                                                                    <div
                                                                                                        className="elementor-column-wrap elementor-element-populated"
                                                                                                    >
                                                                                                        <div
                                                                                                            className="elementor-widget-wrap">
                                                                                                            <div
                                                                                                                className="elementor-element elementor-element-f0782d5 campain-grid-style-1 elementor-widget elementor-widget-opal-give-campain"
                                                                                                                data-id="f0782d5"
                                                                                                                data-element_type="widget"
                                                                                                                data-widget_type="opal-give-campain.default"

                                                                                                            >
                                                                                                                <div
                                                                                                                    className="elementor-widget-container">
                                                                                                                    <div
                                                                                                                        className="give-wrap">
                                                                                                                        <div
                                                                                                                            className="give-grid give-grid--3">
                                                                                                                            {
                                                                                                                                renderDisplayComponent(listCagnotteProgrammeesByAmbassadeur)
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            voirPlusListCagnotteProgrammeeByAmbassadeur(listCagnotteProgrammeesByAmbassadeur)
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </main>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </>
                            ) : (
                                <LoadingSpinner />
                            )

                            }

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Cagnottes;