import {useNavigate, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import '../Contribution.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus, faUserEdit, faLongArrowAltUp, faLongArrowAltDown, faToggleOn, faToggleOff, faTrash
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import {updateAmbassadeur} from "../api/queries";
import Modal from "react-bootstrap/Modal";
import deleteAmbassadeur from "../api/queries/deleteAmbassadeur";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import getAdministrateursByRoles from "../api/queries/getAdministrateursByRoles"

const GestionAmbassadeur = () => {

    let isLogged = JSON.parse(sessionStorage.getItem('isLogged')) ?? null;
    let [listeAmbassadeur, setListeAmbassadeur] = useState([]);
    let [statutSuppressionCompteAmbassadeur, setStatutSuppressionCompteAmbassadeur] = useState(JSON.parse(sessionStorage.getItem('statutSuppressionCompteAmbassadeur')) ?? null);
    let [statutCompteAmbassadeur, setStatutCompteAmbassadeur] = useState(JSON.parse(sessionStorage.getItem('statutCompteAmbassadeur')) ?? null);
    let [selectedAmbassadeurId, setSelectedAmbassadeurId] = useState(JSON.parse(sessionStorage.getItem('statutCompteAmbassadeur')) ?? null);
    const [errMsg, setErrMsg] = useState('');
    const [succesMsg, setSuccesMsg] = useState('');
    const [ambassadeurIdState, setAmbassadeurId] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const handleCloseConfirm = () => setShowConfirm(false);
    const handleShowConfirm = () => setShowConfirm(true);
    let [succesMsgAmbassadeurEdition] = useState(sessionStorage.getItem('succesMsgAmbassadeurEdition') ?? null);
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const navigate = useNavigate();

    const {SearchBar} = Search;

    const [columns] = useState([{
        dataField: 'nom',
        text: 'Nom',
        sort: true
    }, {
        dataField: 'prenom',
        text: 'Prénom',
        sort: true
    }
        , {
            dataField: 'telephone',
            text: 'Téléphone',
            sort: true
        },
        {
            dataField: 'email',
            text: 'E-mail',
            sort: true
        },
        {
            dataField: 'active',
            text: 'Statut du compte',
            formatter: statusFormatter
        },
        {
            dataField: '',
            text: 'Actions',
            formatter: showMenus
        }
    ]);

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
      De {from} à {to} sur {size} résultat(s)
    </span>
    );

    function statusFormatter(cell) {
        if (cell) {
            return <span className="badge badge-success">Activé</span>;
        } else {
            return <span className="badge badge-danger">Désactivé</span>;
        }
    }

    const sortFunc = (a, b, order, dataField) => {
        
        if ('string' == typeof a && 'string' == typeof b) {
            if (order === 'asc') {
                return a.localeCompare(b);
            }

            if (order === 'desc') {
                return b.localeCompare(a);
            }

        } else {
            if (order === 'asc') {
                return a - b;
            }
            return b - a; // desc
        }
    }

    const sortOption = {
        // No need to configure sortFunc per column
        sortFunc: sortFunc,
        // No need to configure sortCaret per column
        sortCaret: (order, column) => {
            if (!order) return (<span> <FontAwesomeIcon icon={faLongArrowAltUp} /><FontAwesomeIcon icon={faLongArrowAltDown} /> </span>);
            else if (order === 'asc') return (<span>&nbsp;<FontAwesomeIcon icon={faLongArrowAltUp} /><font style={{color: "#9C14FC"}}><FontAwesomeIcon icon={faLongArrowAltDown} /></font></span>);
            else if (order === 'desc') return (<span>&nbsp;<font style={{color: "#9C14FC"}}><FontAwesomeIcon icon={faLongArrowAltUp} /></font><FontAwesomeIcon icon={faLongArrowAltDown} /></span>);
            return null;
        }
    };

    const [options] = useState({
        pageStartIndex: 1,
        paginationSize: 5,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: false, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Retour',
        nextPageText: 'Suivant',
        lastPageText: 'Dernier',
        nextPageTitle: 'Première page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Page suivante',
        lastPageTitle: 'Dernière page',
        paginationTotalRenderer: customTotal,
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '30', value: 30
        }, {
            text: '50', value: 50
        }, {
            text: 'Tout', value: listeAmbassadeur?.length
        }]
    });

    function showMenus(cell, row) {

            return (
                <>
                    <Button
                        title="Modifier un ambassadeur"
                        onClick={() => onGotoEditAmbassadeur(row)} type="button"
                        className='btn btn-primary btn-sm'
                    >
                        <FontAwesomeIcon icon={faUserEdit}/>
                    </Button>
                    &nbsp;
                    <Button
                        title="désactiver cet ambassadeur"
                        onClick={() => editStatutCompteAmbassadeur(row.id, row.active)} type="button"
                        className={row.active ? 'btn btn-warning btn-sm' : 'hide'}
                    >
                        <FontAwesomeIcon icon={faToggleOff}/>
                    </Button>
                    <Button
                        title="activer cet ambassadeur"
                        onClick={() => editStatutCompteAmbassadeur(row.id, row.active)} type="button"
                        className={!row.active ? 'btn btn-success btn-sm' : 'hide'}
                    >
                        <FontAwesomeIcon icon={faToggleOn}/>
                    </Button>
                    &nbsp;
                    <button
                        onClick={() => onDeleteAmbassadeur(row)}
                        title="Supprimer cet ambassadeur"
                        type="button"
                        className={row.tParticipationAmbassadeurCagnottes.length === 0 && row.tDonataire.length === 0 ? 'btn btn-danger btn-sm' : 'hide'}
                    >
                        <FontAwesomeIcon icon={faTrash}/>
                    </button>
                </>
            );
    }

    const onDeleteAmbassadeur = (ambassadeur) => {
        setAmbassadeurId(ambassadeur?.id);
        handleShowConfirm();
    }

    function onGotoAddAmbassadeur() {
        navigate(`/ambassadeur/creation-ambassadeur`);
    }

    function onGotoEditAmbassadeur(ambassadeur) {
        // Enregistrement de l'id de l'amnbassadeur courant dans les local storage
        sessionStorage.setItem('ambassadeur_id', JSON.stringify(ambassadeur?.id));

        // Transmission de données à la page
        let datas = {
            ambassadeur: ambassadeur
        }

        navigate(`/ambassadeur/modification-ambassadeur`, { state: datas});
    }

    const onCloseModalConfirm = () => {
        setAmbassadeurId(null);
        handleCloseConfirm();
    }

    const editStatutCompteAmbassadeur = async (ambassadeurId, ambassadeurStatutCompte) => {

        // Si le compte de l'ambassadeur est activé
        if(ambassadeurStatutCompte === true){
            // On le désactive
            try {
                let params = {
                    active: false
                }

                    let response = await updateAmbassadeur(ambassadeurId, params);

                    if (response?.status === 200) {
                        sessionStorage.setItem('selectedAmbassadeurId', JSON.stringify(ambassadeurId));
                        sessionStorage.setItem('statutCompteAmbassadeur', JSON.stringify(false));
                        setSelectedAmbassadeurId(ambassadeurId);
                        setStatutCompteAmbassadeur(false);
                        setSuccesMsg("Compte de l'ambassadeur désactivé avec succès!");
                    }

            } catch (err) {
                setErrMsg("Une erreur est survenue lors de la désactivation du compte de l'ambassadeur");
            }
        }

        // Si le compte de l'ambassadeur est désactivé,
        if(ambassadeurStatutCompte === false){
            // On l'active

            try {
                let params = {
                    active: true
                }

                    let response = await updateAmbassadeur(ambassadeurId, params);

                    if (response?.status === 200) {
                        sessionStorage.setItem('selectedAmbassadeurId', JSON.stringify(ambassadeurId));
                        sessionStorage.setItem('statutCompteAmbassadeur', JSON.stringify(true));
                        setSelectedAmbassadeurId(ambassadeurId);
                        setStatutCompteAmbassadeur(true);
                        setSuccesMsg("Compte de l'ambassadeur activé avec succès!");
                    }

            } catch (err) {
                setErrMsg("Une erreur est survenue lors de l'activation du compte de l'ambassadeur");
            }
        }

        navigate(`/gestion-ambassadeur`);
    }

    const showAucunAmbassadeur = () => {
        return <div className="mt-4"><p>Aucun ambassadeur disponible</p></div>;
    };

    const onDeleteOk = async () => {
        try {
            let deleteAmbassadeurReponse = await deleteAmbassadeur(ambassadeurIdState, axiosPrivate, location, navigate);

            if (deleteAmbassadeurReponse?.status === 204) {
                sessionStorage.setItem('selectedAmbassadeurId', JSON.stringify(ambassadeurIdState));
                sessionStorage.setItem('statutSuppressionCompteAmbassadeur', JSON.stringify(true));
                setSelectedAmbassadeurId(ambassadeurIdState);
                setStatutSuppressionCompteAmbassadeur(true);
                setAmbassadeurId(null);
                setSuccesMsg("Ambassadeur suprrimé avec succès!");
                handleCloseConfirm();
            }

        } catch (err) {
            setErrMsg("Une erreur est survenue lors de la suppression de l'ambassadeur");
        }
    }

    useEffect(() => {

        let isMounted = true;
        const getListeAmbassadeur = async () => {

            try{
                if(isMounted){
                    // Si une personne est connecté
                    if (isLogged === true) {

                    // On récupère le rôle de l'utilisateur actuellement connecté
                    let user_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles

                    // Si la personne connecté est un administrateur
                        if (user_role?.includes("ROLE_ADMIN")) {
                            const resp = await getAdministrateursByRoles();
                            setListeAmbassadeur(resp?.["hydra:member"]);
                        }
                    }
                }

            } catch(err){
                setErrMsg("Une erreur est survenue lors de la récupération de la liste des ambassadeurs");
            }
        }

        getListeAmbassadeur();

        // Si le message de sucess de validation de la contribution existe, le supprimé des sessions storage
        if(succesMsgAmbassadeurEdition){
            sessionStorage.removeItem('succesMsgAmbassadeurEdition');
        }

        return () => (isMounted = false)

    }, [statutCompteAmbassadeur, selectedAmbassadeurId, statutSuppressionCompteAmbassadeur, succesMsgAmbassadeurEdition, isLogged])

    return (

        <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
            <div className="give-wrap-inner">
                <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">

                    <div className="summary entry-summary">
                        <div className="card mb-5 custom_card_style_1">
                            <h5 className="card-header text-center custom_card_style_2"> Liste des ambassadeurs</h5>
                            <div className="card-body">

                                <div id="give-form-62-wrap" className="give-display-onpage"></div>
                                <div className={succesMsg ? "alert alert-success alert-dismissable" : "offscreen"}>
                                    {succesMsg}
                                </div>
                                <div className={succesMsgAmbassadeurEdition ? "alert alert-success alert-dismissable" : "offscreen"}>
                                    {succesMsgAmbassadeurEdition}
                                </div>
                                <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                
                                { listeAmbassadeur?.length > 0 ?
                                    <ToolkitProvider
                                        keyField="id"
                                        data={listeAmbassadeur}
                                        columns={columns}
                                        search={{defaultSearch: '', searchFormatted: true}}
                                    >
                                        {
                                            props => (
                                                <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                                    <div className='col-md-4 pull-right'>
                                                        <SearchBar
                                                            placeholder="Rechercher" {...props.searchProps} />
                                                    </div>

                                                    <hr/>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        pagination={paginationFactory(options)}
                                                        sort={ sortOption }
                                                        classes="fix-responsive-width"
                                                    />
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider> : showAucunAmbassadeur()
                                }
                            
                                <div>
                                    <Button className="give-submit give-btn pull-left valider_contribution_details_for_validation_style" title="Ajouter un nouvel ambassadeur"
                                        onClick={() => onGotoAddAmbassadeur()} type="button">
                                        <span>Ajouter</span>&nbsp;<FontAwesomeIcon icon={faUserPlus}/>
                                    </Button>
                                </div>

                                {/* Modal Confirmation */}
                                <Modal show={showConfirm} onHide={onCloseModalConfirm} backdrop="static" keyboard={false}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Suppression de l'ambassadeur</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        Voulez-vous vraiment continuer ?
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={onCloseModalConfirm}>Annuler </Button>
                                        <Button onClick={onDeleteOk} style={{"backgroundColor": "#9C14FC"}}>Confirmer</Button>
                                    </Modal.Footer>
                                </Modal>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GestionAmbassadeur;