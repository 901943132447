import {useEffect, useState, useRef } from "react";
import LoginContributeur from "./LoginContributeur";
import InscriptionContributeurAnonyme from "../../pages/InscriptionContributeurAnonyme";
import {useLocation, useOutletContext} from "react-router-dom";

import './SecondItem.css'

const SecondItem = (props) => {
    const [locationPathName, setLocationPathName] = useState('');
    const location = useLocation();

    // attribut dans le cas - choix s'inscrire pendant la contribution
    const [showFormAfterInscrire, setShowFormAfterInscrire] = useState(true);
    const [showRecuperation, setShowRecuperation] = useState(false);
    const [requiredAfterInscrire, setRequiredAfterInscrire] = useState(true);

    let [typeContributeur, setTypeContributeur] = useState('inscrit');
    const [validTypeContributeur, setvalidTypeContributeur] = useState(false);
    let [successContributeurAnonyme, setSuccessContributeurAnonyme] = useState(false);
    let [successContributeurInscrit, setSuccessContributeurInscrit] = useState(false);
    const [connected] = useOutletContext();
    const [ValidStep, setValidStep] = useState(false);

    const childCompRef = useRef();

    const handleSuccessContributeurAnonyme = () => {
        setSuccessContributeurAnonyme(true)
    }

    const handleSuccessContributeurInscrit = () => {
        setSuccessContributeurInscrit(true)
    }

    function handleTypeContributeur(e) {
        setTypeContributeur(e.target.value)
        setvalidTypeContributeur(true)
    }

    const changeValidateButton = (value) => {
        setValidStep(value);
    }


    const handleModePaiement = (e) => {
        if (e.target.value === "Virement") {
            setShowRecuperation(true);
            props.config.modePaiement = e.target.value;
            setRequiredAfterInscrire(true);
        } else{
            setShowRecuperation(false);
            localStorage.setItem('modeRecuperationCoupon', "");
            props.config.modePaiement = e.target.value;
            setRequiredAfterInscrire(false);
        }

        if (e.target.value === "") {
            setRequiredAfterInscrire(true);
        }
    }

    const handleModeRecuperationCoupon = (e) => {
        localStorage.setItem('modeRecuperationCoupon', e.target.value);
        props.config.modeRecuperation = e.target.value;
        setRequiredAfterInscrire(false);
        if (e.target.value === "") {
            setRequiredAfterInscrire(true);
        }
    }


    function formTypeContributeur(){

        if (typeContributeur === "") {
            return
        } else if(typeContributeur === "anonyme") {
            return (
                <div className="col-md-4 d-flex mx-auto" style={{"background": "white"}}>
                    <div className="align-self-center w-100">
                        <InscriptionContributeurAnonyme
                            config={props.config}
                            activeButtonNext={changeValidateButton}
                            successContributeurAnonymeBis={handleSuccessContributeurAnonyme}
                            onNextFunction={props.submitNext}
                            ref={childCompRef}
                        />
                    </div>
                </div>
            )
        } else if(typeContributeur === "inscrit") {
            if (!showFormAfterInscrire) {
                return (
                    <div className="col-md-8 d-flex mx-auto" style={{"background": "white"}}>
                        <div className="align-self-center w-100">
                            {/*<div className="alert alert-success" role="alert">*/}
                            {/*    Veuillez vérifier votre courriel pour l'activation de votre compte et continuer l'opération.*/}
                            {/*</div>                                                          */}

                            <fieldset id="give_checkout_user_info">
                                {/* Choix du mode de paiement */}
                                <p id="give-firgrrst-name-wrap" className="form-row form-row-wide">
                                    <label className="give-label" htmlFor="give-last"> Mode de paiement
                                        <span className="give-required-indicator"> *</span>
                                    </label>
                                    <select id="modepaiement" name="modepaiement" className="give-input required"
                                            onChange={(e) => handleModePaiement(e)} aria-required="true" aria-describedby="modepaiementnote">
                                        <option value="">Sélectionner le mode de paiement </option>
                                        <option value="Virement">Virement</option>
                                        <option value="Chèque">Chèque</option>
                                        <option value="Espèce">Espèce</option>
                                    </select>
                                </p>

                                {/* Choix du mode de récupération du coupon; afficher que si le choix est un virement */}
                                { showRecuperation &&
                                <p id="give-last-name-wrap" className="form-row form-row-wide">
                                    <label className="give-label" htmlFor="give-last"> Mode de récuperation du coupon
                                        <span className="give-required-indicator"> *</span>
                                    </label>
                                    <select id="moderecuperationcoupon" name="moderecuperationcoupon" className="give-input required"
                                            onChange={(e) => handleModeRecuperationCoupon(e)} aria-required="true" aria-describedby="moderecuperationcouponnote">
                                        <option value="">Sélectionner le mode de recupération </option>
                                        <option value="En main propre">En main propre</option>
                                        <option value="E-mail">E-mail</option>
                                    </select>
                                </p>
                                }
                            </fieldset>
                        </div>
                    </div>
                )
            }

            return (
                <div className="col-md-6 d-flex mx-auto mt-4" style={{"background": "white"}}>
                    <div className="align-self-center w-100 my_custom_flex_center_col">
                        <LoginContributeur
                            config={props.config}
                            activeButtonNext={changeValidateButton}
                            cagnotteCurrent={props.cagnotteCurrent}
                            setShowFormAfterInscrireFunc={setShowFormAfterInscrire}
                            successContributeurInscritBis={handleSuccessContributeurInscrit}
                            villes={props.villes}
                            locationPathName={locationPathName}
                        />
                    </div>
                </div>
            )
        }
    }

    const handleSubmitBtnNextStep3Invite = (e) => {
        childCompRef.current.handleSubmitt(e);
    }

    // Gestion du bouton suivant
    function handleBoutonSuivant(
        typeContributeurparam,
        successContributeurAuthentifieparam,
        validTypeContributeurparam,
        successContributeurAnonymeparam,
        successContributeurInscritparam){

        if(typeContributeurparam === 'anonyme') {
            // Cas du contributeur anonyme
            return(
                <button
                    id="contributeur_anonyme" name="contributeur_anonyme"
                    className={typeContributeurparam === "anonyme" ? "show give-submit give-btn" : "hide"}
                    onClick={(e) => handleSubmitBtnNextStep3Invite(e)}
                    disabled={!ValidStep}
                >Suivant </button>
            )
        } else if(typeContributeurparam === 'inscrit' && successContributeurAuthentifieparam === true) {
            // Cas du contributeur inscrit et authentifié | option inscrit et bouton "se connecter"
            return(
                <button
                    id="contributeur_inscrit_authentifie"
                    name="contributeur_inscrit_authentifie"
                    className={typeContributeurparam === "inscrit" ? "show give-submit give-btn" : "hide"}
                    onClick={(e) => props.submitNext(e)}
                    disabled={!ValidStep}
                >Suivant </button>
            )
        } else if(typeContributeurparam === 'inscrit' && successContributeurAuthentifieparam === false) {
            // Cas du contributeur inscrit et non authentifié | option inscrit et bouton "s'inscrire"
            return(
                <button
                    id="contributeur_inscrit"
                    name="contributeur_inscrit"
                    className={typeContributeurparam === "inscrit" ? "show give-submit give-btn" : "hide"}
                    onClick={(e) => props.submitNext(e)}
                    disabled={!validTypeContributeurparam || !successContributeurInscritparam || requiredAfterInscrire ? true : false}
                >Suivant</button>
            )
        }
    }

    useEffect(() => {
        setLocationPathName(location.pathname);
        formTypeContributeur();

        // activation du btn "suivant"
        changeValidateButton(true);
    }, [typeContributeur, ValidStep, requiredAfterInscrire, showFormAfterInscrire]) //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id="give_purchase_form_wrap">

            {/* progress bar */}
            <div className="progress-segment">
                <div className="progresso">
                    <div className="step-name etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero" style={{color: "#000"}} >1</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p >Choisir une cagnotte <br/>de collecte en cours</p>
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso">
                    <div className="step-name etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero">2</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p >Choisir le nombre de coupons et<br/> l’ambassadeur de la dignité</p>
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso-active">
                    <div className="step-name-active etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero-active">3</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p style={{color: "#000"}}>S'identifier, contribuer et<br/> récupérez vos coupons</p>
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso-active-mobile">
                    <div className="step-name-active etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero-active">3</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            S'identifier, contribuer et récupérez vos coupons
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso">
                    <div className="step-name etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero">4</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p >Dédier la contribution <br/>à une âme chère</p>
                        </div>
                    </div>
                    <div className="item"></div>
                </div>
            </div>

            <fieldset id="give_checkout_user_info">
                {/*<div id="pwdnote" className={!validTypeContributeur ? "instructions_bis" : "offscreen"}>*/}
                {/*    <div className="alert alert-secondary" role="alert">*/}
                {/*        <FontAwesomeIcon icon={faInfoCircle} /> &nbsp;Veuillez sélectionner une option pour continuer*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="col-md-8 d-flex mx-auto" style={{"background": "white"}}>
                    <div className="align-self-center w-100 my_custom_flex_center pt-3">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                type="radio"
                                id="customRadioInline1"
                                name="customRadioInline1"
                                className="custom-control-input"
                                value="inscrit"
                                onChange={handleTypeContributeur}
                                required
                                checked={typeContributeur === 'inscrit' ? true : false}
                            />
                            <label className="custom-control-label" htmlFor="customRadioInline1">Inscrit</label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                type="radio"
                                id="customRadioInline2"
                                name="customRadioInline1"
                                className="custom-control-input"
                                value="anonyme"
                                onChange={handleTypeContributeur}
                                required/>
                            <label className="custom-control-label" htmlFor="customRadioInline2" >Invité</label>
                        </div>
                    </div>
                </div>

                { formTypeContributeur() }
            </fieldset>
            <fieldset id="give_purchase_submit" className="give-donation-submit">
                <div className="give-submit-button-wrap give-clearfix pull-left">
                    <button onClick={(e) => props.submitPrevious(e)} className="give-submit give-btn">Retour</button>
                </div>
                <div className="give-submit-button-wrap give-clearfix pull-right">
                    {/*<button onClick={(e) => props.submitNext(e)} className="give-submit give-btn">Terminer</button>*/}
                    {
                        // rendu du btn suivant
                        handleBoutonSuivant(typeContributeur, connected, validTypeContributeur, successContributeurAnonyme, successContributeurInscrit)
                    }
                </div>
            </fieldset>

        </div>
    )
}

export default SecondItem;
