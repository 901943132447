import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import {useRef, useState} from "react";
import updateFeedback from "../api/queries/updateFeedback";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const RejetValidationFeedback = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const [motifRejetRequired, setMotifRejetRequired] = useState(false);
    const [motifRejetMsg, setMotifRejetMsg] = useState('');
    const [motifRejetStatut, setMotifRejetStatut] = useState(false);
    const [valide, setValide] = useState('');

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();

    const handleMotifRejet = (e) => {
        setMotifRejetMsg(e.target.value);

        if (e.target.value) {
            setMotifRejetRequired(true);
        } else {
            setMotifRejetRequired(false);
        }
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        try {

            let params = {
                valide: 2,
                motifRejet: motifRejetMsg,
                motifRejetStatut: true
            }

            let updateFeedbackResponse = await updateFeedback(props.feedbackAValiderId, params, axiosPrivate, location, navigate);

            if (updateFeedbackResponse?.status === 200) {
                setMotifRejetStatut(true);
                setValide(2);
                props.setUpdateFeedbackStatutFromParent(true);
                sessionStorage.setItem('feedbackRejetMsgFromParent', 'La dédicace a été rejeté avec succès!');
                navigate(`/contributions/validation-feedbacks`);
            }

        } catch (err) {
            console.error(err)
            if (!err?.response) {
                setErrMsg('Une erreur est survenue');
            } else {
                setErrMsg("L'enregistrement a echoué")
            }
        }
    }

    return (
        <>
            <Button className={motifRejetStatut || props.updateFeedbackStatut || props.feedbackRejete === 2 ? "hide" : "give-submit give-btn pull-right valider_contribution_details_for_validation_style mr-1"} onClick={handleShow}>
                Rejeter
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Motif de rejet de la dédicace</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <div className="form-group">
                        <textarea
                            placeholder="Veuillez saisir le motif de rejet de la dédicace"
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="2"
                            cols="1"
                            onChange={(e) => handleMotifRejet(e)}
                            required
                            readOnly={motifRejetStatut ? true : false}
                        ></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                            onClick={(e) => handleSubmit(e)}
                            disabled={!motifRejetRequired ? true : false}
                            className={motifRejetStatut || valide === 2 ? "hide" : ""}
                    >
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RejetValidationFeedback;