import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation} from "react-router-dom";
import { getDemandeDetails } from '../api/queries';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const DemandeDetails = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    let isLogged = JSON.parse(sessionStorage.getItem('isLogged')) ?? null;
    let [detailsDemande, setDetailsDemande] = useState({});
    const axiosPrivate = useAxiosPrivate();

    const initialDatas = async () => {
        let identifiant = parseInt(params.identifiant);
        if (isLogged === true) {
            // On récupère le rôle de l'utilisateur actuellement connecté
            let user_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles;
            // Si la personne connecté est un admin
            if (user_role?.includes("ROLE_ADMIN")) {
                const resp = await getDemandeDetails(identifiant, axiosPrivate, location, navigate);
                setDetailsDemande(resp);
            }
        }
    }

    useEffect(() => {
        initialDatas();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
            
            <div className="give-wrap-inner">
                <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">

                    <div className="summary entry-summary">
                        <div className="card mb-5 custom_card_style_1">
                            <h5 className="card-header text-center custom_card_style_2"> Détails de la demande</h5>
                            <div className="card-body">
                                <div id="give-form-62-wrap" className="give-display-onpage">
                                    <div className="col-md-7 mx-auto">
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Nom :</label>&nbsp;
                                                { detailsDemande?.nom }
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Prénom : </label>
                                                { detailsDemande?.prenom }
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Nom conjoint:</label>&nbsp;
                                                { detailsDemande.nomConjoint !== undefined ? detailsDemande?.nomConjoint : "-" }
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Prénom conjoint: </label>
                                                { detailsDemande?.prenomConjoint !== undefined ? detailsDemande?.prenomConjoint : "-" }
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Téléphone:</label>&nbsp;
                                                { detailsDemande?.telephone !== undefined ? detailsDemande?.telephone : "-" }
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Situation matrimoniale: </label>
                                                { detailsDemande?.situationMatrimonialConjointe !== undefined ? detailsDemande?.situationMatrimonialConjointe : "-" }
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Nombre de personnes:</label>&nbsp;
                                                { detailsDemande?.nombrePersonnes !== undefined ? detailsDemande?.nombrePersonnes : "-" }
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Nombre d'enfants: </label>
                                                { detailsDemande?.nombreEnfants !== undefined ? detailsDemande?.nombreEnfants : "-" }
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Emplacement:</label>&nbsp;
                                                { detailsDemande?.emplacement !== undefined ? detailsDemande?.emplacement : "-" }
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Notaire: </label>
                                                { detailsDemande?.notaire !== undefined ? detailsDemande?.notaire : "-" }
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Titre: </label>
                                                { detailsDemande?.cagnotte?.titre !== undefined ? detailsDemande?.cagnotte?.titre : "-" }
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Statut:</label>&nbsp;
                                                { detailsDemande?.statut === 0 ? (<span className="badge badge-warning">En cours</span>) :
                                                    detailsDemande?.statut === 1 ? (<span className="badge badge-success">Activé</span>) : (<span className="badge badge-danger">Refusé</span>)
                                                }
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <label className="give-label my_inline_style"> Description:</label><br/>
                                                { detailsDemande?.cagnotte?.description !== undefined ? detailsDemande?.cagnotte?.description : "-" }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" onClick={() => navigate("/contributions/demandes-a-valider")} className="give-submit give-btn pull-left valider_contribution_details_for_validation_style mt-4">Retour</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default DemandeDetails;