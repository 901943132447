import GoogleLogin from "react-google-login"
import { useNavigate, useOutletContext } from "react-router-dom";
import {getContributeurByEmail, postContributeur, postLoginContributeur} from "../api/queries";
import googleicon from "../assets/img/google.svg";

const GooglePage = (props) => {
    const [connected, setConnected] = useOutletContext();
    const navigate = useNavigate();
    const saveToken = (params) => {
        sessionStorage.setItem('token', JSON.stringify(params?.token));
        sessionStorage.setItem('isLogged', true);
        sessionStorage.setItem('login', JSON.stringify(params?.data?.login));
        sessionStorage.setItem('email', JSON.stringify(params?.data?.email));
        sessionStorage.setItem('user_data', JSON.stringify(params?.data));
    };

    const responseFailure = async (response) => {
        console.log(response);
        return false;
    }

    const responseSuccess = async (response) => {
        try {
            sessionStorage.setItem('google_user', JSON.stringify(response));
            // On vérifie si un contributeur possédant la même adresse e-mail existe déja dans la BD
            let result = await getContributeurByEmail(response.profileObj.email);

            if (result?.data?.["hydra:member"].length === 0) {
                // Sinon on l'enregistre dans la base de données
                let paramsGoogleUser = {
                    login: response?.profileObj?.email,
                    roles: ["CONTRIBUTEUR_INSCRIT"],
                    password: response?.profileObj?.googleId,
                    nomContributeur: response?.profileObj?.familyName,
                    prenomContributeur: response?.profileObj?.givenName,
                    emailContributeur: response?.profileObj?.email,
                    telContributeur: '',
                    paysContributeur: '',
                    villeContributeur: null,
                    modeInscription: 'GOOGLE'
                }

                var postContributeurReponse;

                // Si le login n'est pas vide ou null ou undefined
                if(paramsGoogleUser?.login){
                    postContributeurReponse = await postContributeur(paramsGoogleUser);
                } else {
                    props.setMsg('Le login est obligatoire et doit être votre adresse e-mail !');
                    return;
                }

                let paramsGoogleUserLogin = {
                    username: response?.profileObj?.email,
                    password: response?.profileObj?.googleId,
                }

                // Si l'username et le mot de passe sont défini alors on proccède à l'authentification
                if(postContributeurReponse?.status === 201 && paramsGoogleUserLogin?.username && paramsGoogleUserLogin?.password){
                    const responsePostLogin = await postLoginContributeur(paramsGoogleUserLogin);
                    saveToken(responsePostLogin?.data)
                    // Etablissement de l'authentification
                    setConnected(true);
                } else {
                    props.setMsg('Identifiant et/ou mot de passe Google incorrect !');
                    return;
                }

            } else {
                // On proccède à l'authentification JWT
                let paramsGoogleUserLogin = {
                    username: response?.profileObj?.email,
                    password: response?.profileObj?.googleId,
                }

                // Si l'username et le mot de passe sont défini alors on proccède à l'authentification
                if(paramsGoogleUserLogin?.username && paramsGoogleUserLogin?.password){
                    const responsePostLogin = await postLoginContributeur(paramsGoogleUserLogin);
                    saveToken(responsePostLogin?.data)
                    // Etablissement de l'authentification
                    setConnected(true);
                } else {
                    props.setMsg('Identifiant et/ou mot de passe Google incorrect !');
                    return;
                }
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    function handleGoogleLogin() {
        let googleContent

        if (connected === true) {
            navigate("/");
        } else {
            googleContent = (
                <GoogleLogin
                    clientId="465059280563-ks2u89etlm3c42tsangtdi7ddh8hk1fb.apps.googleusercontent.com"
                    buttonText=""
                    onSuccess={responseSuccess}
                    onFailure={responseFailure}
                    cookiePolicy={'single_host_origin'}
                    render={renderProps => (
                        <img onClick={renderProps.onClick} src={googleicon} alt='google-icon'
                             style={{maxWidth: '180px', cursor: 'pointer'}}/>
                    )}
                />
            )
        }

        return (
            googleContent
        )
    }

    return (
        <>
            {
                handleGoogleLogin()
            }
        </>
    )
}

export default GooglePage;
