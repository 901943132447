import endpoints from '../endpoints';

const updateFeedback = async (feedback_id, params, axiosPrivate, location, navigate) => {
    try {
        return await axiosPrivate.put(endpoints.urlgetFeedbackById(feedback_id), {
            valide: params?.valide,
            motifRejet: params?.motifRejet,
            motifRejetStatut: params?.motifRejetStatut
        });
    } catch (error) {
        if (error?.response?.status === 403 || error?.response?.status === 401) {
            navigate('/auth', {state: {from: location}, replace: true});
        }
    }
}

export default updateFeedback;