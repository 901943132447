import endpoints from '../endpoints';

const putChangeDemande = async (donataire_id, params ,axiosPrivate, location, navigate) => {
    try {
        return await axiosPrivate.put(endpoints.urlPutDemandeChange(donataire_id), params);
    } catch (err) {
        if (err?.response?.status === 403 || err?.response?.status === 401) {
            navigate('/auth', {state: {from: location}, replace: true});
        }
    }
}

export default putChangeDemande;