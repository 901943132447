import React, { useRef, useState, useEffect } from "react";
import {
    getVilles,
    getAdministrateurByLogin,
    postAmbassadeur,
    getAdministrateurByEmail
} from "../api/queries";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import validator from 'validator';
import { AsyncPaginate } from 'react-select-async-paginate';
import {Link, useLocation, useNavigate} from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import LoadingSpinner from '../components/LoadingSpinner';

// const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const InscriptionAmbassadeurForm = (props) => {
    
    const errRef = useRef();
    const errLoginRef = useRef();
    const nomAmbassadeurRef = useRef();
    const prenomAmbassadeurRef = useRef();
    const emailAmbassadeurRef = useRef();
    const emailRepeatAmbassadeurRef = useRef();
    const passwordRef = useRef();
    const passwordRepeatRef = useRef();
    const telAmbassadeurRef = useRef();
    const [succesMsg, setSuccesMsg] = useState('');
    const [loading, setLoading] = useState(false);

    // Login de l'ambassadeur
    const [user, setUser] = useState('');
    // Mot de passe de l'ambassadeur
    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);
    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);
    // Nom de l'ambassadeur
    const [nomAmbassadeur, setNomAmbassadeur] = useState('');
    // Prénom de l'ambassadeur
    const [prenomAmbassadeur, setPrenomAmbassadeur] = useState('');

    // E-mail de l'ambassadeur
    const [emailAmbassadeur, setEmailAmbassadeur] = useState('');
    const [emailFocus, setEmailFocus] = useState(false);
    const [matchEmail, setMatchEmail] = useState('');
    const [validMatchEmail, setValidMatchEmail] = useState(false);
    const [matchEmailFocus, setMatchEmailFocus] = useState(false);

    // Téléphone de l'ambassadeur
    const [telAmbassadeur, setTelAmbassadeur] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [errMsgLogin, setErrMsgLogin] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [villeAmbassadeur, setVilleAmbassadeur] = useState(null);
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const navigate = useNavigate();

    // Affichage des villes par tranche de 30 dans la liste déroulante
    const villesPerPage = 5;
    const defaultAdditional = {
        page: 1
    };

    const handleEmailAmbassadeur = (e) => {

        var email = e.target.value
        setEmailAmbassadeur(email)

        if (validator.isEmail(email)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }

    const myCustomStyles = {
        control: styles => ({ ...styles, backgroundColor: '#f9f9f9',padding: '4.9px', borderRadius:'0px',lineHeight:'2.1' })
    };

    async function loadVilles(search, page) {

        const listVill = await getVilles();

        const villes = listVill?.["hydra:member"].map((el) => (
            {
                value: el?.id,
                label: el?.nomVille
            }
        ));

        let filteredVilles;
        if (!search) {
            filteredVilles = villes;
        } else {
            const searchLower = search.toLowerCase();

            filteredVilles = villes.filter(({ label }) =>
                label.toLowerCase().includes(searchLower)
            );
        }

        const hasMore = Math.ceil(filteredVilles.length / villesPerPage) > page;

        const slicedVilles = filteredVilles.slice(
            (page - 1) * villesPerPage,
            page * villesPerPage
        );

        return {
            options: slicedVilles,
            hasMore
        };
    }

    const loadPageVilles = async (q, prevOptions, { page }) => {
        const { options, hasMore } = await loadVilles(q, page);

        return {
            options,
            hasMore,
            additional: {
                page: page + 1
            }
        };
    };

    useEffect(() => {

        // Test de conformité du nom de l'utilisateur
        // setValidName(USER_REGEX.test(user));
        // Test de conformité et de correspondance des mots de passe
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);

        // Test de correspondance des adresses email
        setValidMatchEmail(emailAmbassadeur === matchEmail);
    }, [user, pwd, matchPwd, emailAmbassadeur, matchEmail])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const v2 = PWD_REGEX.test(pwd);
        if (!v2 || emailAmbassadeurRef.current.value !== emailRepeatAmbassadeurRef.current.value || passwordRef.current.value !== passwordRepeatRef.current.value) {
            setErrMsg("Veuillez renseigner correctement le formulaire.");
            setSuccesMsg('');
            return;
        }
        try {
            setLoading(true);
            let params = {
                login: emailAmbassadeur,
                password: pwd,
                nomAmbassadeur: nomAmbassadeur,
                prenomAmbassadeur: prenomAmbassadeur,
                emailAmbassadeur: emailAmbassadeur,
                telAmbassadeur: telAmbassadeur,
                villeAmbassadeur: villeAmbassadeur ? "/api/ville/"+villeAmbassadeur.value : null
            }

            // Récupération des données de l'ambassadeur à partir de son login
            let resultUser = await getAdministrateurByLogin(emailAmbassadeur);
            // Récupération des données de l'ambassadeur à partir de son adresse e-mail
            let result = await getAdministrateurByEmail(emailAmbassadeur);

            result?.data?.["hydra:member"].length >= 1 ? setErrMsg("Cet email est déjà utilisé") : setErrMsg('');
            resultUser?.data?.["hydra:member"].length >= 1 ? setErrMsgLogin("Ce Login est déjà utilisé") : setErrMsgLogin('');

            if (result?.data?.["hydra:member"].length >= 1 || resultUser?.data?.["hydra:member"].length >= 1) {
                setSuccesMsg('');
                setUser('');
                setPwd('');
                setMatchPwd('');
                setMatchEmail('');
                setNomAmbassadeur('');
                setPrenomAmbassadeur('');
                setEmailAmbassadeur('');
                setTelAmbassadeur('');
                setLoading(false);
                return;
            }

            // Enregistrement de l'ambassadeur en BDD
            await postAmbassadeur(params, axiosPrivate, location, navigate);
            setErrMsg("");
            setSuccesMsg('Ambassadeur enregitré avec succès!');
            // Activation du bouton de soumission du formulaire

            setUser('');
            setPwd('');
            setMatchPwd('');
            setMatchEmail('');
            setNomAmbassadeur('');
            setPrenomAmbassadeur('');
            setEmailAmbassadeur('');
            setTelAmbassadeur('');
            setLoading(false);

        } catch (err) {
            console.log(err)
            if (!err?.response) {
                setErrMsg('Aucune réponse du serveur');
            } else if (err.response?.status === 409) {
                setErrMsg("Nom d'utilisateur déjà existant");
            } else {
                setErrMsg('Enregistrement échoué')
            }
        }
    }

    return (
        <>
            <div id="give-wrap" className="give-wrap" style={{ "position": "relative"}}>
                <div className="give-wrap-inner">
                        <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                            <div className="summary entry-summary">

                                { loading ? 
                                    <LoadingSpinner /> 
                                    : (
                                        <div id="give-form-62-wrap" className="give-display-onpage">
                                            <p style={{"color": "#004085"}} ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                            <p ref={errLoginRef} className={errMsgLogin ? "errmsg" : "offscreen"} aria-live="assertive">{errMsgLogin}</p>
                                            <div className={succesMsg ? "alert alert-success alert-dismissable" : "offscreen"}>
                                                {succesMsg}
                                            </div>
                                            <form onSubmit={handleSubmit} >
                                                <fieldset>
                                                    <legend>Identification d'un ambassadeur</legend>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputNom">Nom <span style={{ "color": "#a00"}}> *</span></label>
                                                            <input type="text" ref={nomAmbassadeurRef} className="form-control" id="nomAmbassadeur" 
                                                                onChange={(e) => setNomAmbassadeur(e.target.value)} value={nomAmbassadeur} required
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputNom">Prénom <span style={{ "color": "#a00"}}> *</span></label>
                                                            <input type="text" ref={prenomAmbassadeurRef} className="form-control" id="prenomAmbassadeur"
                                                                onChange={(e) => setPrenomAmbassadeur(e.target.value)} value={prenomAmbassadeur} required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputNom">Email <span style={{ "color": "#a00"}}> *</span></label>
                                                            <input type="email" ref={emailAmbassadeurRef} className="form-control" id="emailAmbassadeur"
                                                                onChange={(e) => handleEmailAmbassadeur(e)} value={emailAmbassadeur} aria-describedby="emailnote" 
                                                                onFocus={() => setEmailFocus(true)} onBlur={() => setEmailFocus(false)} required
                                                            />
                                                            <p id="emailnote" className={emailFocus && !validEmail ? "instructions_bis" : "offscreen"}>
                                                                <FontAwesomeIcon icon={faInfoCircle} />&nbsp;<span style={{fontWeight: 'bold', color: 'red' }}>Entrer un e-mail valide</span>
                                                            </p>
                                                        </div>

                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputNom">Confirmer votre adresse mail <span style={{ "color": "#a00"}}> *</span>
                                                                <FontAwesomeIcon icon={faCheck} className={validMatchEmail && matchEmail ? "valid" : "hide"} />
                                                                <FontAwesomeIcon icon={faTimes} className={validMatchEmail || !matchEmail ? "hide" : "invalid"} />
                                                            </label>
                                                            <input type="email" className="form-control" ref={emailRepeatAmbassadeurRef}
                                                                onChange={(e) => setMatchEmail(e.target.value)} value={matchEmail} required
                                                                aria-invalid={validMatchEmail ? "false" : "true"} aria-describedby="confirmnote"
                                                                onFocus={() => setMatchEmailFocus(true)} onBlur={() => setMatchEmailFocus(false)}
                                                            />
                                                            <p id="confirmnote" className={matchEmailFocus && !validMatchEmail ? "instructions" : "offscreen"}>
                                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                                Doit correspondre au premier champ de saisie de l'adresse e-mail.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputNom">Téléphone</label>
                                                            <input
                                                                type="text"
                                                                ref={telAmbassadeurRef}
                                                                className="form-control"
                                                                id="telAmbassadeur"
                                                                onChange={(e) => setTelAmbassadeur(e.target.value)}
                                                                value={telAmbassadeur}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="give-email"> Ville
                                                            </label>
                                                            <AsyncPaginate
                                                                value={villeAmbassadeur}
                                                                loadOptions={loadPageVilles}
                                                                onChange={setVilleAmbassadeur}
                                                                placeholder="Sélectionner une ville"
                                                                styles={myCustomStyles}
                                                                additional={defaultAdditional}
                                                            />
                                                        </div>
                                                    </div>
                                                </fieldset>

                                                <fieldset>
                                                    <legend>Accès ambassadeur</legend>
                                                    <div className="form-row">
                                                        
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="password">
                                                                Mot de passe
                                                                <span style={{ "color": "#a00"}}> *</span>
                                                                <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                                                                <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                                                            </label>
                                                            <input type="password" id="password" onChange={(e) => setPwd(e.target.value)} value={pwd} required
                                                                aria-invalid={validPwd ? "false" : "true"} aria-describedby="pwdnote" ref={passwordRef}
                                                                onFocus={() => setPwdFocus(true)}
                                                                onBlur={() => setPwdFocus(false)}
                                                            />
                                                            <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                                8 à 24 caractères.<br />
                                                                Doit inclure des lettres majuscules et minuscules, un chiffre et un caractère spécial.<br />
                                                                Caractères spéciaux autorisés: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                                            </p>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="password"> Confirmer le mot de passe <span style={{ "color": "#a00"}}> *</span>
                                                                <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                                                                <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                                                            </label>
                                                            <input type="password" id="confirm_pwd" onChange={(e) => setMatchPwd(e.target.value)} ref={passwordRepeatRef}
                                                                value={matchPwd} required aria-invalid={validMatch ? "false" : "true"} aria-describedby="confirmnote"
                                                                onFocus={() => setMatchFocus(true)}
                                                                onBlur={() => setMatchFocus(false)}
                                                            />
                                                            <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                                Doit correspondre au premier champ de saisie du mot de passe.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </fieldset>

                                                <p style={{color: "crimson"}}> Les champs marqués par (*) sont obligatoires</p>

                                                <hr/>

                                                {/* Le bouton retour ne s'affiche que pour le formulaire d'authentification principal */}
                                                { !props.locationPathName &&
                                                <div className="give-submit-button-wrap pull-left"
                                                >
                                                    <Link to="/gestion-ambassadeur" className="give-submit valider_contribution_details_for_validation_style">Retour</Link>
                                                </div>
                                                }

                                                <input type="submit" value="valider"
                                                    className="give-submit give-btn pull-right valider_contribution_details_for_validation_style"
                                                    style={{textTransform: "capitalize"}}
                                                />
                                            </form>
                                        </div>
                                    )
                                }

                                

                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default InscriptionAmbassadeurForm