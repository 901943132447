import React, { useRef, useState, useCallback, useEffect } from 'react';

import { postLoginContributeur } from '../../api/queries';
import {useLocation, useOutletContext} from "react-router-dom";
import InscriptionContributeurModal from "./InscriptionContributeurModal";
import Facebook from "../Facebook";
import Google from "../GooglePage";

const LoginContributeur = (props) => {
    const usernameRef = useRef();
    const pwdRef = useRef();
    const [success, setSuccess] = useState(true);
    const [connected, setConnected] = useOutletContext();
    const [msg, setMsg] = useState('Veuillez saisir tous les champs.');
    const [showRecuperation, setShowRecuperation] = useState(false);
    const [locationPathName, setLocationPathName] = useState('');
    const location = useLocation();

    const saveToken = (params) => {
        sessionStorage.setItem('token', JSON.stringify(params?.token));
        sessionStorage.setItem('isLogged', true);
        sessionStorage.setItem('login', JSON.stringify(params?.data?.login));
        sessionStorage.setItem('email', JSON.stringify(params?.data?.email));
        sessionStorage.setItem('user_data', JSON.stringify(params?.data));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (usernameRef.current.value === "" || usernameRef.current.value === undefined || pwdRef.current.value === undefined || pwdRef.current.value === "") {
            setSuccess(false);
            setMsg('Veuillez saisir tous les champs.');
            messageShow();
        } else {

            try {
                let params = {
                    username: usernameRef.current.value,
                    password: pwdRef.current.value
                }

                const response = await postLoginContributeur(params);
                if (response?.status === 200 && response?.data?.token !== 0) {
                    // login success
                    setSuccess(true);
                    messageShow();
                    saveToken(response?.data);
                    // Authentification
                    setConnected(true);

                } else {
                    setSuccess(false);
                    setMsg("Votre compte est inactif - consultez votre adresse email pour l'activer !");
                    messageShow();
                }

            } catch (err) {
                if (err.response?.status === 401) {
                    setSuccess(false);
                    setMsg("Identifiant ou mot de passe incorrect !");
                    messageShow();
                }
            }
        }
    }

    const handleModePaiement = async (e) => {
        if (e.target.value === "Virement") {
            setShowRecuperation(true);
            props.config.modePaiement = e.target.value;
            props.activeButtonNext(false); // on grise le btn Suivant
        } else{
            setShowRecuperation(false);
            localStorage.setItem('modeRecuperationCoupon', "");
            props.activeButtonNext(true); // on active le btn Suivant
            props.config.modePaiement = e.target.value;
        }

        if (e.target.value === "") {
            props.activeButtonNext(false); // on grise le btn Suivant
        }
        localStorage.setItem('modePaiement', e.target.value);
    }

    const handleModeRecuperationCoupon = async (e) => {
        localStorage.setItem('modeRecuperationCoupon', e.target.value);
        props.config.modeRecuperation = e.target.value;
        props.activeButtonNext(true); // on active le btn Suivant
        if (e.target.value === "") {
            props.activeButtonNext(false); // on grise le btn Suivant
        }
    }

    const messageShow = useCallback(function() {
        return success ? null : (
            <div className="alert alert-warning" role="alert">
                {msg}
            </div>
        );
    }, [success, msg]);

    useEffect(() => {
        setLocationPathName(location.pathname);
    }, [connected]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {connected ? (
                <>
                    <div id="give_purchase_form_wrap">
                        <div className="alert alert-success" role="alert">
                            Vous êtes authentifié ! Veuillez selectionner les champs en dessous
                        </div>

                        <fieldset id="give_checkout_user_info">
                            {/* Choix du mode de paiement */}
                            <p id="give-firgrrst-name-wrap" className="form-row form-row-wide">
                                <label className="give-label" htmlFor="give-last"> Mode de paiement
                                    <span className="give-required-indicator"> *</span>
                                </label>
                                <select id="modepaiement" name="modepaiement" className="give-input required"
                                        onChange={(e) => handleModePaiement(e)} aria-required="true" aria-describedby="modepaiementnote">
                                    <option value="">Sélectionner le mode de paiement</option>
                                    <option value="Virement">Virement</option>
                                    <option value="Chèque">Chèque</option>
                                    <option value="Espèce">Espèce</option>
                                </select>
                            </p>

                            {/* Choix du mode de récupération du coupon; afficher que si le choix est un virement */}
                            { showRecuperation &&
                            <p id="give-last-name-wrap" className="form-row form-row-wide">
                                <label className="give-label" htmlFor="give-last"> Mode de récuperation du coupon
                                    <span className="give-required-indicator"> *</span>
                                </label>
                                <select id="moderecuperationcoupon" name="moderecuperationcoupon" className="give-input required"
                                        onChange={(e) => handleModeRecuperationCoupon(e)} aria-required="true" aria-describedby="moderecuperationcouponnote">
                                    <option value="">Sélectionner le mode de recupération </option>
                                    <option value="En main propre">En main propre</option>
                                    <option value="E-mail">E-mail</option>
                                </select>
                            </p>
                            }
                        </fieldset>
                    </div>
                </>
            ) : (
                <>
                <section className="col-md-8">
                    {messageShow()}
                    <div style={{marginBottom:"30px"}}>
                        <div className="form-group">
                            <label htmlFor="username">Identifiant <span className='give-required-indicator'>*</span> :</label>
                            <input type="text" id="username" ref={usernameRef} required />
                        </div>
                        <div>
                            <label htmlFor="password">Mot de passe <span className='give-required-indicator'>*</span> :</label>
                            <input ref={pwdRef} type="password" id="password" required />
                        </div>
                        {/* Passage de la cagnotte courante au composant de la fenêtre modale */}
                        <InscriptionContributeurModal
                            cagnotteCurrent={props.cagnotteCurrent}
                            setShowFormAfterInscrireFunc={props.setShowFormAfterInscrireFunc}
                            successContributeurInscritBis={props.successContributeurInscritBis}
                            villes={props.villes}
                            locationPathName={locationPathName}
                        />
                        <button onClick={(e) => handleSubmit(e)}
                                className="btn btn-primary pull-right mt-3"
                                // className="btn btn-primary btn-block w-100 mt-1"
                            style={{textTransform:"uppercase", fontSize:"0.875rem"}}
                        >
                            Se connecter</button>
                    </div>

                    <div>
                        <div className="divider my-4">
                        <span>
                            ou
                        </span>
                        </div>

                        <div className="my_custom_flex_row">
                            <div className="my_custom_flex_center">
                                <div>
                                    <Facebook/>
                                </div>
                            </div>

                            {/*<div className="my_custom_flex_center">*/}
                            {/*    <div>*/}
                            {/*        <LinkedIn/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div>
                                <div className="my_custom_flex_center">
                                    <Google/>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                </>
            )
            }
        </>
    )
}

export default LoginContributeur