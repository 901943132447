import axios from "axios"
import endpoints from '../endpoints';

const postContribution = async ({ dateContribution, paymentValide, couponGenere, contributeur, cagnotte,
                                    nombreCoupons, modePaiment, ambassadeur, modeRecuperation}) => {
    try {
        return await axios({
            method: 'post',
            url: endpoints.postContribution(),
            data: {
                dateContribution: dateContribution,
                paymentValide: paymentValide,
                couponGenere: couponGenere,
                contributeur: contributeur,
                cagnotte: cagnotte,
                nombreCoupons: nombreCoupons,
                modePaiment: modePaiment,
                ambassadeur: ambassadeur,
                modeRecuperation: modeRecuperation
            }
        });
    } catch (error) {
        console.error(error);
        return false;
    }
}

export default postContribution;