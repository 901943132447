import {useCallback, useEffect, useRef, useState} from "react";
import {Link, useNavigate, useOutletContext} from "react-router-dom";
import { postLoginAmbassadeur } from '../api/queries';
import LoadingSpinner from '../components/LoadingSpinner';

const LoginAdministrateur = () => {
    const navigate = useNavigate();
    const fieldEmail = useRef("");
    const fieldPassword = useRef("");
    const [connected, setConnected] = useOutletContext();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(true);
    const [msg, setMsg] = useState('');

    const saveToken = (params) => {
        sessionStorage.setItem('token', JSON.stringify(params?.token));
        sessionStorage.setItem('isLogged', true);
        sessionStorage.setItem('login', JSON.stringify(params?.data?.login));
        sessionStorage.setItem('email', JSON.stringify(params?.data?.email));
        sessionStorage.setItem('user_data', JSON.stringify(params?.data));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (fieldEmail.current.value === "" || fieldEmail.current.value === undefined || fieldPassword.current.value === undefined || fieldPassword.current.value === "") {
            setSuccess(false);
            setMsg('Veuillez saisir tous les champs.');
            messageShow();
        } else {

            try {
                setLoading(true);
                let params = {
                    username: fieldEmail.current.value,
                    password: fieldPassword.current.value
                }

                const response = await postLoginAmbassadeur(params);
                // login success
                if (response?.status === 200 && response?.data?.token !== 0) {
                    setSuccess(true);
                    saveToken(response.data);
                    setConnected(true);
                }

                // login success et compte desactivé
                if (response?.status === 200 && response?.data?.token === 0) {
                    setSuccess(false);
                    setMsg("Votre compte est inactif - consultez votre adresse email pour l'activer !");
                    messageShow();
                } 
                
                // login erreur
                if (response === false) {
                    setSuccess(false);
                    setMsg("Identifiant ou mot de passe incorrect !");
                    messageShow();
                }
                setLoading(false);

            } catch (err) {
                if (err?.response?.status === 401) {
                    setSuccess(false);
                    setMsg("Identifiant ou mot de passe incorrect !");
                }
                setLoading(false);
            }
        }
    }

    const messageShow = useCallback(function() {
        return success ? null : (
            <div className="alert alert-warning" role="alert">
                {msg}
            </div>
        );
    }, [success, msg]);

    useEffect(() => {

        //  verifier si le contributeur est deja connecté
        if(connected){
            sessionStorage.setItem('premiere_connexion_admin', JSON.stringify(true));
            navigate("/");
        }
    }, [connected]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <section>
            <div className="container login__container">
                <div className="login__row my_custom_flex_center">
                    <div className="col-md-5" style={{"background": "#FFF", "borderRadius": "40px", "paddingBottom": "40px"}}>
                        <div className="row d-flex justify-content-center" style={{"padding":"10px 0"}}>
                            <div className="col-12 col-md-8">
                                <div className="align-self-center mb-2 w-100">
                                    <h3 className="mb-3 mt-5 text-center">Authentification</h3>
                                    {messageShow()}
                                    <form>
                                        <div className="form-group">
                                            <input type="email" ref={fieldEmail} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Identifiant"/>
                                        </div>
                                        <div style={{"marginBottom": "10px"}}>
                                            <input type="password" ref={fieldPassword} className="form-control" id="exampleInputPassword1" placeholder="Mot de passe"/>
                                        </div>

                                        { loading === false ? (
                                            <>
                                                {/* bouton mot de passe oublié */}
                                                <Link style={{"marginBottom": "35px"}} to="/forgot/password" className='pull-left' variant="primary">
                                                    Mot de passe oublié ?
                                                </Link>
                                                <button onClick={(event) => handleSubmit(event)}
                                                        // className="btn btn-primary btn-block w-100 mt-1"
                                                        className="btn btn-block mt-1 btn-primary"
                                                        style={{textTransform:"uppercase", fontSize:"0.875rem"}}
                                                >
                                                    Se connecter </button>
                                            </>
                                            ) : (
                                                <LoadingSpinner />
                                            )
                                        }

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default LoginAdministrateur;