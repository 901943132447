import React from "react";
import "./Spinner.css";

const LoadingSpinner = () => {
    return (
        <div style={{"justifyContent": "center"}} className="row">
          <div className="loading-spinner justify-content-center"></div>
        </div>
    );
}

export default LoadingSpinner;