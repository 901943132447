import axios from "axios"
import endpoints from '../endpoints'

const getFeedbackAccueil = async (valide=1) => {
    try {
        const response = await axios({
            method: 'get',
            url: endpoints.urlgetFeedbackAccueil(valide)
        });
        return response.data;

    } catch (error) {
        console.error(error);
        return false;
    }
}

export default getFeedbackAccueil;