import React, { useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const ShareCagnotte = (props) => {

    const navigate = useNavigate();

    const [succesMsg, setSuccesMsg] = useState('');
   useEffect(()=>{
    setSuccesMsg('');
    if(props.statutFeedback){
        setSuccesMsg('La dédicace a été validée avec succès !');
    }
    },[succesMsg, props.statutFeedback])

    function onGotoHome() {
        navigate(`/`);
    }

    return (

        <div>
            {/* progress bar */}
            <div className="progress-segment">
                <div className="progresso">
                    <div className="step-name etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero" style={{color: "#000"}} >1</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p >Choisir une cagnotte <br/>de collecte en cours</p>
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso">
                    <div className="step-name etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero">2</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p >Choisir le nombre de coupons et<br/> l’ambassadeur de la dignité
                            </p>
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso">
                    <div className="step-name etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero">3</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p >S'identifier, contribuer et<br/> récupérez vos coupons</p>
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso-active">
                    <div className="step-name-active etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero-active">4</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            <p style={{color: "#000"}}>Dédier la contribution <br/>à une âme chère</p>
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

                <div className="progresso-active-mobile">
                    <div className="step-name-active etape_progress_bar_style"><br/>
                        <div className="number_etape_progress_bar_style">
                            <span className="numero-active">4</span>
                        </div>
                        <div className="text_etape_progress_bar_style">
                            Dédier la contribution à une âme chère
                        </div>
                    </div>
                    <div className="item black-common"></div>
                </div>

            </div>

            {/* <div className="alert alert-success" role="alert">
                Votre contribution a été enregistrée avec succès. Merci d'avoir participé à changer positivement des
                vies.
            </div> */}

            <div className="row my_custom_flex_center_col">
                <div className="col-12 col-md-9">
                    <div className={succesMsg ? "alert alert-success alert-dismissable mt-3" : "offscreen"}>
                        {succesMsg}
                    </div>
                    <div className="alert alert-success" role="alert">
                        Votre contribution a été enregistrée avec succès. Merci d'avoir participé à changer positivement des
                        vies.<br/>
                        Un ambassadeur va prendre contact avec vous pour vous remettre vos coupons après réception de votre contribution
                    </div>
                    {/* Contenu */}
                    <div>
                        <p className="text-center w-100" style={{"color": "#000", fontWeight: "600"}}>Où aimeriez-vous partager la cagnotte?</p>
                    </div>
                    <div className="my_custom_flex_row" style={{padding:"30px 0"}}>
                        <div>
                            <div className="my_custom_flex_center">
                                <a target="_blank"  rel="noreferrer"
                                    // href="https://www.facebook.com/sharer/sharer.php?u=https://n3ichob7alnass-demo.intelcia-solutions.com/"
                                   href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.href}
                                >
                                    <img src={props.facebookicon} alt='facebook-icon'/>
                                </a>
                            </div>
                            <p style={{
                                "color": "#000",
                            }}>Facebook</p>
                        </div>

                        <div>
                            <div className="my_custom_flex_center">
                                <a target="_blank"  rel="noreferrer"
                                   href="https://api.whatsapp.com/send/?phone=212652234748&text&app_absent=0">
                                    <img src={props.whatsappicon} alt='whatsapp-icon'/>
                                </a>
                            </div>
                            <p style={{
                                "color": "#000",
                            }}>Whatsapp</p>
                        </div>

                        <div>
                            <div className="my_custom_flex_center">
                                <a
                                    target="_blank"  rel="noreferrer"
                                    href={"https://www.linkedin.com/shareArticle?mini=true&url="+window.location.href}
                                    // href={"https://www.linkedin.com/shareArticle?mini=true&url="+window.location.href+"&title="+props.cagnotteActuelle.titre+"&summary="+props.cagnotteActuelle.description}
                                    // href="https://www.linkedin.com/shareArticle?mini=true&url=https://n3ichob7alnass-demo.intelcia-solutions.com/"
                                >
                                    <img src={props.linkedinicon} alt='linkedin-icon'/>
                                </a>
                            </div>
                            <p style={{
                                "color": "#000",
                            }}>LinkedIn</p>
                        </div>
                    </div>

                    <div className="mt-5">
                        <p className="text-center w-100" style={{"color": "#000", fontWeight: "600"}}>Rejoindre nos pages</p>
                    </div>
                    <div className="my_custom_flex_row" style={{padding:"30px 0"}}>
                        <div>
                            <div className="my_custom_flex_center">
                                <a target="_blank"  rel="noreferrer"
                                   href="https://www.facebook.com/DariB7alNass/"
                                >
                                    <img src={props.facebookicon} alt='facebook-icon'/>
                                </a>
                            </div>
                            <p style={{
                                "color": "#000",
                            }}>Facebook</p>
                        </div>

                        <div>
                            <div className="my_custom_flex_center">
                                <a target="_blank" rel="noreferrer"
                                   href="https://www.instagram.com/darib7alnass/"
                                >
                                    <img src={props.instagramicon} alt='instagram-icon'/>
                                </a>
                            </div>
                            <p style={{
                                "color": "#000",
                            }}>Instagram</p>
                        </div>
                    </div>

                </div>
                <br/>
            </div>

            {/*<div*/}
            {/*    id="retour_share_cagnotte"*/}
            {/*    className="pull-left">*/}
            {/*    <button*/}
            {/*        onClick={(e) => props.submitPrevious(e)}*/}
            {/*        className="give-submit give-btn valider_contribution_details_for_validation_style"*/}
            {/*    >*/}
            {/*        Retour*/}
            {/*    </button>*/}
            {/*</div>*/}

            <div className="pull-right" role="group">
                <button
                    onClick={() => onGotoHome()}
                    type="button"
                    className="ml-1 give-submit give-btn pull-right valider_contribution_details_for_validation_style"
                >Terminer
                </button>
            </div>

            <div className="new_displaying_share_cagnotte_button" style={{display: "none"}}>
                <div style={{width: "250px"}}>
                    {/*<button id="btn_retour_share" onClick={(e) => props.submitPrevious(e)} className="give-submit give-btn pull-left valider_contribution_details_for_validation_style">*/}
                    {/*    Retour*/}
                    {/*</button>*/}
                    {/* <button id="btn_passer_etape"
                        onClick={(e) => props.submitNext(e)}
                        className="ml-1 give-submit give-btn pull-right valider_contribution_details_for_validation_style"
                    >
                        Passer cette étape
                    </button> */}
                </div>
                {/* <div className="mt-3" style={{width: "250px"}}>
                    <button id="btn_aller_accueil"
                        style={{width: "250px"}}
                        onClick={() => onGotoHome()}
                        type="button"
                        className="give-submit give-btn valider_contribution_details_for_validation_style"
                    >Aller à la page d'accueil
                    </button>
                </div> */}
            </div>
        </div>
    )
}

export default ShareCagnotte;