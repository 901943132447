import axios from "axios"
import endpoints from '../endpoints';

const postContributeur = async ({ login, roles, password, nomContributeur, prenomContributeur, emailContributeur, telContributeur, paysContributeur, villeContributeur, modeInscription}) => {
    try {
        return await axios({
            method: 'post',
            url: endpoints.postContributeur(),
            data: {
                login: login,
                roles: roles,
                password: password,
                nom: nomContributeur,
                prenom: prenomContributeur,
                email: emailContributeur,
                telephone: telContributeur,
                pays: paysContributeur,
                ville: villeContributeur,
                modeInscription: modeInscription,
                active: false
            }
        });
    } catch (error) {
        console.error(error);
        return false;
    }
}

export default postContributeur;