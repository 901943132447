import axios from "axios"
import endpoints from '../endpoints';

const postContributeurAnonyme = async ({ nomContributeurAnonyme, prenomContributeurAnonyme, emailContributeurAnonyme}) => {
    try {
        return await axios({
            method: 'post',
            url: endpoints.postContributeur(),
            data: {
                roles: [
                    "CONTRIBUTEUR_ANONYME"
                ],
                nom: nomContributeurAnonyme,
                prenom: prenomContributeurAnonyme,
                email: emailContributeurAnonyme,
                modeInscription: "ANONYME",
                password: ""
            }
        });
    } catch (error) {
        console.error(error);
        return false;
    }
}

export default postContributeurAnonyme;