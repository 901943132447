import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getCagnotteBySlug} from "../api/queries";
import 'react-circular-progressbar/dist/styles.css';
import getContributeurByCagnotte from "../api/queries/getContributeurByCagnotte";
import ProgressBar from "../components/ProgressBar";

const ContributionDetail = () => {
    const navigate = useNavigate();
    const params = useParams();
    let [cagnotteCurrent, setCagnotteCurrent] = useState({});
    let [listContributeurByCagnotte, setListContributeurByCagnotte] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [succesMsg, setSuccesMsg] = useState('');

    window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto'
    });

    const loadCagnotteImage = (cagnotteCurrentParam) => {
        if (cagnotteCurrentParam.tImage !== null) {
            return (
                <div className="give-card__thumbnail">
                <img width="850" height="550"
                     src={cagnotteCurrentParam.tImage !== undefined ? cagnotteCurrentParam.tImage.chemin : ""}
                     className="attachment-gainlove-featured-image-large size-gainlove-featured-image-large wp-post-image"
                     alt={cagnotteCurrentParam.tImage !== undefined ? cagnotteCurrentParam.tImage.description : ""}
                     loading="lazy"/>
                </div>
            )
        } else {
            return null;
        }
    }

    const InitiatePage = async () => {
        if (Object.keys(params).length === 0) {
            navigate("/");
        } else {
            let result = await getCagnotteBySlug(params?.slug);
            result === false ? navigate("/") : setCagnotteCurrent(result?.data);
            loadDataContributeurByCagnotte(result?.data?.id);
        }
    }

    const loadDataContributeurByCagnotte= async (cagnotte_id) => {
        let rstContributeurByCagnotte = await getContributeurByCagnotte(cagnotte_id);
        setListContributeurByCagnotte(rstContributeurByCagnotte?.["hydra:member"]);
    }

    useEffect(() => {
        InitiatePage();

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (

        <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
            <div className="give-wrap-inner">
                <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <div
                    className={succesMsg ? "alert alert-success alert-dismissable" : "offscreen"}>
                    {succesMsg}
                </div>
                <div id="give-form-62-content"
                     className="give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">

                    {/*   Rendre ce composant dynamiquement sans recharger la page */}
                    <ProgressBar
                        cagnotteCurrent={cagnotteCurrent}
                        listContributeurByCagnotte={listContributeurByCagnotte}
                        setErrMsg={setErrMsg}
                        setSuccesMsg={setSuccesMsg}
                    />

                    <div className="summary entry-summary">
                        {/* form contribution */}
                        <div id="give-form-62-wrap" className="give-form-wrap give-display-onpage">
                            <h2>{cagnotteCurrent.titre}</h2>
                            <h3 className="give-card__title style_parrain_marraine">
                                { 'Parrain/Marraine : '+cagnotteCurrent?.parrainMarraine }
                            </h3>
                                {loadCagnotteImage(cagnotteCurrent)}
                        </div>

                        {/* description */}
                        <div id="give-form-single-tab"
                             className="give-form-tabs ui-tabs ui-corner-all ui-widget ui-widget-content give-form-wrap">
                            <ul role="tablist"
                                className="ui-tabs-nav ui-corner-all ui-helper-reset ui-helper-clearfix ui-widget-header">
                                <li role="tab" tabIndex="0"
                                    className="ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active"
                                    aria-controls="tabs-1" aria-labelledby="ui-id-1" aria-selected="true"
                                    aria-expanded="true">
                                    <a href="#tabs-1" role="presentation" tabIndex="-1" className="ui-tabs-anchor"
                                       id="ui-id-1">Description</a>
                                </li>
                            </ul>
                            <div id="tabs-1" aria-labelledby="ui-id-1" role="tabpanel"
                                 className="ui-tabs-panel ui-corner-bottom ui-widget-content p-2" aria-hidden="false">
                                <div id="give-form-content-62" className="give-form-content-wrap give_pre_form-content">
                                    <p className="contenu-justify m-0">
                                        {cagnotteCurrent.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContributionDetail;