import endpoints from '../endpoints';

const postAmbassadeur = async (params, axiosPrivate, location, navigate) => {

    try {
        return await axiosPrivate.post(endpoints.postAmbassadeur(), {
            login: params?.login,
            roles: ["ROLE_AMBASSADEUR"],
            password: params?.password,
            nom: params?.nomAmbassadeur,
            prenom: params?.prenomAmbassadeur,
            email: params?.emailAmbassadeur,
            telephone: params?.telAmbassadeur,
            rVille: params?.villeAmbassadeur,
            active: true,
            firstConnection: true
        });
    } catch (err) {
        navigate('/auth', {state: {from: location}, replace: true});
    }
}

export default postAmbassadeur;