import React from 'react';
import { Link } from 'react-router-dom';

function DropdownBlock(props) {

  return (
    <>
        <ul className="sub-menu elementor-nav-menu--dropdown sm-nowrap"
            id="sm-16539928881300806-2" role="link"
            aria-hidden="true"
            aria-labelledby="sm-16539928881300806-1"
            aria-expanded="false"
            style={{
                "width": "auto",
                "display": props.displayValue,
                "top": "auto",
                "left": "0px",
                marginLeft: "0px",
                marginTop: "0px",
                minWidth: "250px",
                maxWidth: "500px"
            }}>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item--6764945405681838000">
                <Link to="/contributions/validation-contributions" className="elementor-sub-item">Valider les
                    contributions</Link>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item--6764945405681838000">
                <Link to="/contributions/validation-feedbacks" className="elementor-sub-item">Valider les
                    dédicaces</Link>
            </li>
        </ul>
    </>
  );
}

export default DropdownBlock;
