import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { postActivationCompteContributeur } from '../api/queries';
import './ActivationCompteContributeur.css';

const ActivationCompteContributeur = () => {

    const params = useParams();
    const [error, setError] = useState(false);
    const [msg, setMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const gotoLoginPage = () => {
        navigate("/authentification");
    }

    const loadActivationCompte = async () => {
        let body = {"key_confirmation": params.key_confirmation }
        let result = await postActivationCompteContributeur(body);
        if (result.status === 200) {
            setSuccess(true);
            setMsg(result?.data.description);
            result.data.error ? setError(true) : setError(false);
        }
    }

    // const loadInitial = async () => {
    //     let body = { "key_confirmation": params.key_confirmation }
    //     let res = await postVerificationCompte(body);
    //     if (res.status === 200 && res.data.error) {
    //         setSuccess(true);
    //         setMsg("Impossible de continuer sur cette page");
    //         setError(true);
    //     } else {
    //         setError(false);
    //         setMsg("");
    //         setSuccess(false);
    //     }
    // }

    useEffect(() => {}, [success, error, msg]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id="give-wrap" className="give-wrap" style={{ "position": "relative"}}>
            <div className="give-wrap-inner">
                <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                    <div className="summary entry-summary top-ecart col-md-7 d-flex" style={{"justifyContent": "center"}}>

                        <div className="card card-activation" style={{borderRadius:"40px", "border":"1px #E6D7F1 solid"}}>
                            <h5 className="card-header text-center" style={{borderRadius:"40px 40px 0px 0px"}}>Activation de compte</h5>
                            <div className="card-body">
                                {/* form contribution */}
                                <div id="give-form-62-wrap" className="give-display-onpage">
                                    { success ? (
                                        <>
                                            {/* message a afficher en cas de succes */}
                                            <div className={ "alert " +  (error ? "alert-danger" : "alert-success" )} role="alert">
                                                { msg }
                                            </div>
                                            <div className="row" style={{"justifyContent": "center"}}>
                                                <div className="give-submit-button-wrap give-clearfix pull-right">
                                                    <button onClick={gotoLoginPage} className="give-submit give-btn">Se connecter</button>
                                                </div>
                                            </div>
                                        </>) : (
                                            <>
                                                {/* <LoadingSpinner /> <div role="alert"> <br/> Activation de votre compte en cours ... </div> */}
                                                <p>Veuillez cliquer sur le bouton en dessous pour activer votre compte.</p>
                                                <div className="row" style={{"justifyContent": "center"}}>
                                                    <div className="give-submit-button-wrap give-clearfix pull-right">
                                                        <button onClick={loadActivationCompte} className="give-submit give-btn">Activer mon compte</button>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}

export default ActivationCompteContributeur;