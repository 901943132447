import React, {useEffect, useRef, useState} from "react";
import updateContribution from "../api/queries/updateContribution";
import {useLocation, useNavigate} from "react-router-dom";
import getContributionById from "../api/queries/getContributionById";
import getFeedbacksCagnotteByContribution from "../api/queries/getFeedbacksCagnotteByContribution";
import RejetValidationContribution from "../components/RejetValidationContribution";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import getCagnotteById from "../api/queries/getCagnotteById";
import updateCagnotte from "../api/queries/updateCagnotte";
import LoadingSpinner from '../components/LoadingSpinner';

const ContributionDetailsForValidation = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const [contributionAValiderId, setContributionAValiderId] = useState('');
    const [contributionNombreCoupon, setContributionNombreCoupon] = useState('');
    const [contributionModePaiement, setContributionModePaiement] = useState('');
    const [contributionCagnotteTitre, setContributionCagnotteTitre] = useState('');
    const [contributionCagnotteId, setContributionCagnotteId] = useState('');
    const [contributionModeRecuperation, setContributionModeRecuperation] = useState('');
    const [ambassadeurNom, setAmbassadeurNom] = useState('');
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const [succesMsg, setSuccesMsg] = useState('');
    const [updateContributionStatut, setUpdateContributionStatut] = useState(false);
    const [updateContributionStatutFromParent, setUpdateContributionStatutFromParent] = useState(false);
    const [contributionCagnotteFeedback, setContributionCagnotteFeedback] = useState('');
    const [contributeurNom, setContributeurNom] = useState('');
    const [contributeurPrenom, setContributeurPrenom] = useState('');
    const [contributeurEmail, setContributeurEmail] = useState('');
    const [statutFeedbacksCagnotteByContributeur, setStatutFeedbacksCagnotteByContributeur] = useState(false);
    const [contributionCagnotteDescription, setContributionCagnotteDescription] = useState('');
    const [contributionCagnotteParrainMarraine, setContributionCagnotteParrainMarraine] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [contributionCagnottePaymentValide, setContributionCagnottePaymentValide] = useState(1);
    const [contributionCagnottePaymentRejete, setContributionCagnottePaymentRejete] = useState(2);

    const getContributionData = async () => {
        try {
            let contribution_a_valider_id = location.state.contributionToShow;
            setContributionAValiderId(contribution_a_valider_id);
            let contributionByIdReponse = await getContributionById(contribution_a_valider_id, axiosPrivate, location, navigate);

            if(contributionByIdReponse?.data){
                setContributionNombreCoupon(contributionByIdReponse?.data?.nombreCoupons);
                setContributionModePaiement(contributionByIdReponse?.data?.modePaiment);
                setContributionCagnotteTitre(contributionByIdReponse?.data?.cagnotte.titre);
                setContributionCagnotteId(contributionByIdReponse?.data?.cagnotte?.id);
                setContributionModeRecuperation(contributionByIdReponse?.data?.modeRecuperation);
                setAmbassadeurNom(contributionByIdReponse?.data?.ambassadeur?.nom);
                setContributeurNom(contributionByIdReponse?.data?.contributeur?.nom);
                setContributeurPrenom(contributionByIdReponse?.data?.contributeur?.prenom);
                setContributeurEmail(contributionByIdReponse?.data?.contributeur?.email);
                setContributionCagnotteParrainMarraine(contributionByIdReponse?.data?.cagnotte?.parrainMarraine);
                setContributionCagnotteDescription(contributionByIdReponse?.data?.cagnotte?.description);
                setContributionCagnottePaymentValide(contributionByIdReponse?.data?.paymentValide);
                setContributionCagnottePaymentRejete(contributionByIdReponse?.data?.paymentValide);
            }

            // Récupération du feedback associé à la contribution courante
            let getFeedbacksCagnotteByContributeurReponse = await getFeedbacksCagnotteByContribution(contribution_a_valider_id, axiosPrivate, location, navigate);

            if(getFeedbacksCagnotteByContributeurReponse?.["hydra:member"].length > 0){
                setContributionCagnotteFeedback(getFeedbacksCagnotteByContributeurReponse?.["hydra:member"][0].message);
                setStatutFeedbacksCagnotteByContributeur(true);
            }

        } catch (err) {
            console.error(err);
            setErrMsg('Une erreur est survenue lors du chargement des données de la contribution');
        }
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        setSubmitSuccess(true);
        try {
            setSuccess(true);
            setLoading(true);

                // Récupération de la cagnotte liée à la contribution
                let responseGetCagnotteById = await getCagnotteById(contributionCagnotteId);

                // Récupération du nombre de coupons validés
                let responseGetCagnotteNombreCouponsValides = !responseGetCagnotteById?.data?.nombreCouponsValides ? 0 : responseGetCagnotteById?.data?.nombreCouponsValides;

                // Récupération du nombre de coupons pour ladite cagnotte
                let responseGetCagnotteNombreCoupons = !responseGetCagnotteById?.data?.nombreCoupons ? 0 : responseGetCagnotteById?.data?.nombreCoupons;

                // au nombre de coupon validé de la cagnotte actuelle, on ajoute le nombre de coupon de la contribution courante
                // pour avoir le nouveau nombre de coupons validés
                let nouveauNombreCouponsValides = responseGetCagnotteNombreCouponsValides + parseInt(contributionNombreCoupon);

                // Au nombre de coupons de la cagnotte actuelle, on retranche le nombre de coupon de la contribution courante
                // pour avoir le nouveau nombre de coupons
                let nouveauNombreCoupons = responseGetCagnotteNombreCoupons + parseInt(contributionNombreCoupon);

                // Mise à jour du nouveau nombre de coupons validés
                let updateDataCagnotte = {
                    nombreCouponsValides: nouveauNombreCouponsValides,
                    nombreCoupons: nouveauNombreCoupons,
                }

                if(nouveauNombreCouponsValides <= responseGetCagnotteById?.data?.nombreTotalCoupons){
                    let params = {
                        paymentValide: 1,
                        motifRejet: null
                    }
                    await updateContribution(contributionAValiderId, params, axiosPrivate);
                    await updateCagnotte(contributionCagnotteId, updateDataCagnotte);
                } else {
                    sessionStorage.setItem('rejetMsgFromParent', 'Le nombre de coupons à valider ne peut être supérieur au nombre total de coupons disponibles');
                    setLoading(false);
                    return;
                }

                setUpdateContributionStatut(true);
                setSuccesMsg('La contribution a été validée avec succès!');
                // Enregistrement dans les session storage
                sessionStorage.setItem('succesMsgFromParent', 'La contribution a été validée avec succès!');
                setSubmitSuccess(false);
                // Redirection vers la liste des contribution à valider
                // onGotoContributionAValider();

                // Si le nouveau nombre total de coupons validés et égal au nombre total de coupons par cagnotte
                // La cagnotte est cloturée
                if(responseGetCagnotteById?.data?.nombreTotalCoupons === nouveauNombreCouponsValides){
                    let updateStatutCagnotte = {
                        cloture: true
                    }
                    await updateCagnotte(contributionCagnotteId, updateStatutCagnotte)
                }

            setLoading(false);
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Une erreur est survenue');
            } else {
                setErrMsg("L'enregistrement a echoué")
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        getContributionData();
    },)

    function onGotoContributionAValider() {
        navigate(`/contributions/validation-contributions`);
    }

    function onGotoMesContributionsCagnottes() {
        navigate(`/mes-contributions-cagnottes`);
    }

    function computeMontantContribution(montant_contribution) {
        let montant_contribution_resultat = montant_contribution * 250
        return montant_contribution_resultat.toLocaleString('fr-FR');
    }


    return (
        <>
            {
                success ?
                    (loading === false ? (
                            onGotoContributionAValider()
                        ) : (
                            <div id="content" className="site-content">
                                <div className="wrap top-ecart">
                                    <div id="primary" className="content-area">
                                        <main id="main" className="site-main">
                                            <article id="post-1652"
                                                     className="post-1652 page type-page status-publish hentry">
                                                <div className="page-inner">
                                                    <div className="entry-content">
                                                        <LoadingSpinner/>
                                                    </div>
                                                </div>
                                            </article>
                                        </main>
                                    </div>
                                </div>
                            </div>

                        )
                    ) : (
                    <>
                        <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
                            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}
                               aria-live="assertive">{errMsg}</p>
                            <div className="give-wrap-inner">
                                <div
                                    className={succesMsg ? "alert alert-success alert-dismissable" : "offscreen"}>
                                    {succesMsg}
                                </div>
                                <div
                                    className={updateContributionStatutFromParent ? "alert alert-success" : "offscreen"}>
                                    Contribution rejetée avec succès
                                </div>
                                <div id="give-form-62-content"
                                     className="give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                                    <div className="summary entry-summary">

                                        <div className="card mb-5 custom_card_style_1">
                                            <h5 className="card-header text-center custom_card_style_2">
                                                Choix d'une cagnotte de collecte en cours</h5>
                                            <div className="card-body">
                                                {/* form contribution */}
                                                <div id="give-form-62-wrap" className="give-display-onpage">
                                                    <div className="form-row">
                                                        <div className="col-md-6 form-row form-row-wide">
                                                            <label className="give-label"> Titre de la cagnotte :
                                                            </label>&nbsp;
                                                            <p>{contributionCagnotteTitre ? contributionCagnotteTitre : "-"}</p>
                                                        </div>
                                                        <div className="col-md-6 form-row form-row-wide">
                                                            <label className="give-label"> Parrain/Marraine :
                                                            </label>&nbsp;
                                                            <p>{contributionCagnotteParrainMarraine ? contributionCagnotteParrainMarraine : "-"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col-md-12 form-row form-row-wide">
                                                            <label className="give-label"> Description :
                                                            </label>&nbsp;
                                                            <p>{contributionCagnotteDescription ? contributionCagnotteDescription : "-"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className={location?.state?.urlPath && location?.state?.urlPath === '/mes-contributions-cagnottes' ? "hide" : "card mb-5 custom_card_style_1"}
                                        >
                                            <h5 className="card-header text-center custom_card_style_2">
                                                Identification du contributeur</h5>
                                            <div className="card-body">
                                                {/* form contribution */}
                                                <div id="give-form-62-wrap" className="give-display-onpage">
                                                    <div className="form-row">
                                                        <div className="col-md-6 form-row form-row-wide">
                                                            <label className="give-label"> Nom :
                                                            </label>&nbsp;
                                                            <p>{contributeurNom ? contributeurNom : "-"}</p>
                                                        </div>
                                                        <div className="col-md-6 form-row form-row-wide">
                                                            <label className="give-label"> Prénom :
                                                            </label>&nbsp;
                                                            <p>{contributeurPrenom ? contributeurPrenom : "-"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col-md-12 form-row form-row-wide">
                                                            <label className="give-label"> E-mail :
                                                            </label>&nbsp;
                                                            <p>{contributeurEmail ? contributeurEmail : "-"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card mb-5 custom_card_style_1">
                                            <h5 className="card-header text-center custom_card_style_2">
                                                Choix du nombre de coupons et l’ambassadeur de la dignité</h5>
                                            <div className="card-body">
                                                {/* form contribution */}
                                                <div id="give-form-62-wrap" className="give-display-onpage">

                                                    <div className="form-row">
                                                        <div className="col-md-6 form-row form-row-wide">
                                                            <label className="give-label"> Nombre de coupons (250 DH /
                                                                Unité) :
                                                            </label>&nbsp;
                                                            <p>{contributionNombreCoupon ? contributionNombreCoupon : "-"}</p>
                                                        </div>
                                                        <div className="col-md-6 form-row form-row-wide">
                                                            <label className="give-label"> Ambassadeur de la dignité :
                                                            </label>&nbsp;
                                                            <p>{ambassadeurNom ? ambassadeurNom : "-"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col-md-12 form-row form-row-wide">
                                                            <label className="give-label"> Montant de la contribution :
                                                            </label>&nbsp;
                                                            <p>{contributionNombreCoupon ? computeMontantContribution(contributionNombreCoupon) + " DH" : "-"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card mb-5 custom_card_style_1">
                                            <h5 className="card-header text-center custom_card_style_2">
                                                S'identifier, contribuer et récupérez vos coupons</h5>
                                            <div className="card-body">
                                                {/* form contribution */}
                                                <div id="give-form-62-wrap" className="give-display-onpage">
                                                    <div>
                                                        <div className="form-row">
                                                            <div className="col-md-6 form-row form-row-wide">
                                                                <label className="give-label" htmlFor="give-first"> Mode
                                                                    de paiement :
                                                                </label>&nbsp;
                                                                <p>{contributionModePaiement ? contributionModePaiement : "-"}</p>
                                                            </div>
                                                            <div className="col-md-6 form-row form-row-wide">
                                                                <label className="give-label" htmlFor="give-first">
                                                                    Mode de récuperation du coupon :
                                                                </label>&nbsp;
                                                                <p>{contributionModeRecuperation ? contributionModeRecuperation : "-"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className={statutFeedbacksCagnotteByContributeur ? "card mb-5 custom_card_style_1" : "hide"}>
                                            <h5 className="card-header text-center custom_card_style_2">
                                                Dédier la contribution à une âme chère</h5>
                                            <div className="card-body">
                                                {/* form contribution */}
                                                <div id="give-form-62-wrap" className="give-display-onpage">
                                                    <div
                                                        className={location?.state?.urlPath && location?.state?.urlPath === '/mes-contributions-cagnottes' ? "hide" : "form-row"}
                                                    >
                                                        <div className="col-md-6 form-row form-row-wide">
                                                            <label className="give-label" htmlFor="give-first">
                                                                Message de la dédicace :
                                                            </label>&nbsp;
                                                            <p>{contributionCagnotteFeedback ? contributionCagnotteFeedback : "-"}</p>
                                                        </div>
                                                        <div className="col-md-6 form-row form-row-wide">
                                                            <label className="give-label" htmlFor="give-first"> Auteur :
                                                            </label>&nbsp;
                                                            <p>{contributeurNom || contributeurPrenom ? contributeurNom + " " + contributeurPrenom : "-"}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={location?.state?.urlPath && location?.state?.urlPath === '/mes-contributions-cagnottes' ? "form-row" : "hide"}
                                                    >
                                                        <div className="col-md-12 form-row form-row-wide">
                                                            <label className="give-label" htmlFor="give-first">
                                                                Message de la dédicace :
                                                            </label>&nbsp;
                                                            <p>{contributionCagnotteFeedback ? contributionCagnotteFeedback : "-"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="give-submit give-btn pull-left valider_contribution_details_for_validation_style"
                                onClick={location?.state?.urlPath && location?.state?.urlPath === '/mes-contributions-cagnottes' ?
                                    onGotoMesContributionsCagnottes : onGotoContributionAValider}
                            >Retour
                            </button>

                            <div
                                className={location?.state?.urlPath && location?.state?.urlPath === '/mes-contributions-cagnottes' ? "hide" : "pull-right"}
                                role="group">
                                <button
                                    type="button"
                                    onClick={(e) => handleSubmit(e)}
                                    className={updateContributionStatut || updateContributionStatutFromParent || contributionCagnottePaymentValide === 1 ? "hide" : "give-submit give-btn pull-right valider_contribution_details_for_validation_style"}
                                    disabled={submitSuccess ? true : false}
                                > Valider
                                </button>
                                <RejetValidationContribution contributionAValiderId={contributionAValiderId}
                                                             updateContributionStatut={updateContributionStatut}
                                                             contributionCagnottePaymentRejete={contributionCagnottePaymentRejete}
                                                             setUpdateContributionStatutFromParent={setUpdateContributionStatutFromParent}
                                                             contributionCagnotteId={contributionCagnotteId}
                                                             contributionNombreCoupon={contributionNombreCoupon}
                                                             contributionCagnottePaymentValide={contributionCagnottePaymentValide}
                                                             setLoading={setLoading}
                                                             setSuccess={setSuccess}
                                                             success={success}
                                />
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default ContributionDetailsForValidation;