import React, {useEffect, useState} from "react";
import getDonataireById from "../api/queries/getDonataireById";
import EditionDonataireForm from "./EditionDonataireForm";
import {useLocation, useNavigate} from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const UpdateDonataire = () => {

    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [errMsg, setErrMsg] = useState('');
    const [donataireData, setDonataireData] = useState({})
    // Récupération des données du bénéficiaire depuis les local storage
    let donataireId = JSON.parse(sessionStorage.getItem('donataire_id'));

    useEffect(() => {
        let ignore = false;

        try {
            async function startFetching() {
                const donataireDataResponse = await getDonataireById(donataireId, axiosPrivate, location, navigate);
                if (!ignore) {
                    setDonataireData(donataireDataResponse.data);
                }
            }
            startFetching();
        } catch (e) {
            console.error(e)
            setErrMsg("Une erreur est survenue lors de la récupération des données du bénéficiaire.");
        }

        return () => {
            ignore = true;
        };
    }, [donataireId, axiosPrivate, location, navigate]);

    return (
        <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
            <div className="give-wrap-inner">
                <div id="give-form-62-content"
                     className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                    <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <div className="summary entry-summary">

                        <div className="card mb-5 custom_card_style_1">
                            <h5 className="card-header text-center custom_card_style_2">
                                Modification d'un bénéficiaire</h5>
                            <div className="card-body">
                                <div id="give-form-62-wrap" className="give-display-onpage">
                                    <EditionDonataireForm donataireData={donataireData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateDonataire;