import {useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getDisponibiliteByAmbassadeur, postDisponibiliteAmbassadeur, deleteDisponibiliteAmbassadeur } from "../api/queries";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../components/LoadingSpinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DateRange  } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';

const AdministrationDisponibilite = () => {

    let isLogged = JSON.parse(sessionStorage.getItem('isLogged')) ?? null;
    let [listeDisponibiliteAmbassadeur, setListeDisponibiliteAmbassadeur] = useState([]);
    const {SearchBar} = Search;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();

    const [showConfirm, setShowConfirm] = useState(false);
    const handleCloseConfirm = () => setShowConfirm(false);
    const handleShowConfirm = () => setShowConfirm(true);
    const [loading, setLoading] = useState(false);
    const [msgSuccess, setMsgSuccess] = useState(false);
    const [msgError, setMsgError] = useState(false);
    const [msgNotif, setMsgNotif] = useState('');
    const [dispoSelect, setDispoSelect] = useState(null);
    const [rangeDate, setRangeDate] = useState([
        {
          startDate: new Date(),
          endDate: new Date(Date.now() + ( 3600 * 1000 * 24)),
          key: "selection"
        }
    ]);
    
    const [columns] = useState([{
        dataField: 'dateDebut',
        text: 'DATE DEBUT'
    },{
        dataField: 'dateFin',
        text: 'DATE FIN'
    }, {
            dataField: '',
            text: 'ACTIONS',
            formatter: showMenus
        }
    ]);

    const onCloseModalConfirm = () => {
        rangeDate[0].startDate = new Date();
        rangeDate[0].endDate = null;
        setDispoSelect(null);
        handleCloseConfirm();
    }

    const onDeleteDisponibilite = (value) => {
        setDispoSelect(value?.id);
        handleShowConfirm();
    }

    const onDeleteOk = async () => {
        await deleteDisponibiliteAmbassadeur(dispoSelect, axiosPrivate, location, navigate);
        // Chargement liste disponibilité
        let user_id = JSON.parse(sessionStorage.getItem('user_data'))?.id;
        const resp = await getDisponibiliteByAmbassadeur(user_id, axiosPrivate, location, navigate);
        setListeDisponibiliteAmbassadeur(resp?.["hydra:member"]);
        setMsgSuccess(true);
        setDispoSelect(null);
        handleCloseConfirm();
    }

    function showMenus(cell, row) {
        return (
            <>
                <button onClick={() => onDeleteDisponibilite(row)} title="Supprimer la disponibilité" type="button" className="btn btn-danger btn-sm">
                    <FontAwesomeIcon icon={faTrash}/>
                </button>
            </>
        );
    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            De {from} à {to} sur {size} résultat(s)
        </span>
    );

    const [options] = useState({
        pageStartIndex: 1,
        paginationSize: 5,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: false, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
        firstPageText: 'Premier',
        prePageText: 'Retour',
        nextPageText: 'Suivant',
        lastPageText: 'Dernier',
        nextPageTitle: 'Première page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Page suivante',
        lastPageTitle: 'Dernière page',
        paginationTotalRenderer: customTotal,
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '30', value: 30
        }, {
            text: '50', value: 50
        }, {
            text: 'Tout', value: listeDisponibiliteAmbassadeur.length
        }] // A numeric array is also available. the purpose of above example is custom the text
    });

    const onCreateDisponibilite = async () => {
        setMsgError(false);
        setMsgSuccess(false);
        const dernierDate = listeDisponibiliteAmbassadeur[0]?.dateFin.split('/');

        // On verifie si la nouvelle dispo debute bien correctement selon la dispo precedente
        if (dernierDate !== undefined && new Date(dernierDate[2]+'-'+dernierDate[1]+'-'+dernierDate[0]) > new Date(rangeDate[0].startDate.toISOString().split('T')[0])) {
            setMsgNotif('Impossible de débuter la nouvelle disponibilité à cette date.');
            setMsgError(true);
            handleClose();
            return;
        }

        if(rangeDate[0].startDate.toISOString().split('T')[0] === '' || rangeDate[0].endDate === null) {
            setMsgNotif('Tous les champs sont obligatoires.');
            setMsgError(true);
            handleClose();
            return;
        }

        setLoading(true);
        let user_id = JSON.parse(sessionStorage.getItem('user_data'))?.id;
        const params = {
            "dateDebut": rangeDate[0].startDate.toISOString().split('T')[0],
            "dateFin": rangeDate[0].endDate.toISOString().split('T')[0],
            "ambassadeur": user_id
        }

        const resp = await postDisponibiliteAmbassadeur(params, axiosPrivate);
        if (resp?.status === 201) {
            // Chargement liste disponibilité
            const res = await getDisponibiliteByAmbassadeur(user_id, axiosPrivate, location, navigate);
            setListeDisponibiliteAmbassadeur(res?.["hydra:member"]);
            setMsgError(false);
            setMsgSuccess(true);
        } else {
            setMsgNotif('Une erreur est survenue. veuillez réessayer svp.');
            setMsgError(true);
        }

        setLoading(false);
        handleClose();
    }

    useEffect(() => {
        const loadData = async () => {
            if (isLogged) {
                // On récupère son rôle
                let user_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles
                // Si la personne connecté est un ambassadeur
                if (user_role?.includes("ROLE_AMBASSADEUR")) {
                    let user_id = JSON.parse(sessionStorage.getItem('user_data'))?.id;
                    // Affichage de la liste des contributions à valider
                    const resp = await getDisponibiliteByAmbassadeur(user_id, axiosPrivate, location, navigate);
                    setListeDisponibiliteAmbassadeur(resp?.["hydra:member"]);
                }
            }
        }

        loadData();
    }, [isLogged, axiosPrivate, location, navigate])

    return(
        <div id="content" className="site-content">
            <div className="wrap top-ecart">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <article id="post-1652" className="post-1652 page type-page status-publish hentry">
                            <div className="page-inner">
                                <div className="entry-content">
                                    <div id="give-form-62-wrap" className="give-form-wrap give-display-onpage">
                                        <h4 className="entry-title text-center">Mes disponibilités</h4>

                                        {/* Notification */}
                                        { msgSuccess &&  <div className="alert alert-success" role="alert"> Opération effectuée avec succès.</div> }
                                        { msgError &&  <div className="alert alert-danger" role="alert"> { msgNotif } </div> }                                        

                                        { listeDisponibiliteAmbassadeur?.length > 0 ?
                                            <ToolkitProvider keyField="id"
                                                data={listeDisponibiliteAmbassadeur}
                                                columns={columns} search={{defaultSearch: '', searchFormatted: true}}
                                            >
                                                {
                                                    props => (
                                                        <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                                            <div style={{paddingRight: "0px"}} className='col-md-4 pull-right'>
                                                                <SearchBar style={{"borderRadius": "0.5rem"}} placeholder="Rechercher" {...props.searchProps} />
                                                            </div>

                                                            <hr/>
                                                            <BootstrapTable {...props.baseProps} pagination={paginationFactory(options)} classes="fix-responsive-width" />
                                                        </div>
                                                    )
                                                }
                                            </ToolkitProvider> : "Aucune Disponibilité."
                                        }

                                        <button type="button" style={{"backgroundColor": "#9C14FC", "borderRadius": "0.5rem"}} onClick={handleShow}
                                            className="btn btn-primary pull-right">Créer une nouvelle disponibilité.
                                        </button>

                                        {/* Modal creation de disponibilité */}
                                        <Modal show={show} onHide={handleClose} backdrop="static" centered 
                                            size="lg" >
                                            <Modal.Header closeButton>
                                                <Modal.Title>Nouvelle disponibilité</Modal.Title>
                                            </Modal.Header>

                                            { loading === false ? (
                                                    <>
                                                        <Modal.Body className="text-center">
                                                            Selectionner vos dates. <br/>
                                                            <DateRange
                                                                onChange={item => setRangeDate([item.selection]) }
                                                                moveRangeOnFirstSelection={false}
                                                                ranges={rangeDate}
                                                                locale={locales['fr']}
                                                            />

                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button style={{"borderRadius": "0.5rem"}} variant="secondary" onClick={handleClose}>Fermer </Button>
                                                            <Button style={{"backgroundColor": "#9C14FC", "borderRadius": "0.5rem"}} onClick={onCreateDisponibilite}>Valider</Button>
                                                        </Modal.Footer>
                                                    </>
                                                ) : (
                                                    <div className="form-group text-center">
                                                        <LoadingSpinner />
                                                    </div>
                                                )
                                            }
                                        </Modal>

                                        {/* Modal Confirmation */}
                                        <Modal show={showConfirm} onHide={onCloseModalConfirm} backdrop="static" keyboard={false}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Suppression </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                Voulez-vous vraiment continuer ?
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={onCloseModalConfirm}>Annuler </Button>
                                                <Button onClick={onDeleteOk} style={{"backgroundColor": "#9C14FC"}}>Confirmer</Button>
                                            </Modal.Footer>
                                        </Modal>

                                    </div>
                                </div>
                            </div>
                        </article>
                    </main>
                </div>
            </div>
        </div>
    )

}

export default AdministrationDisponibilite;