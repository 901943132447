import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import postCreateDemande from "../api/queries/postCreateDemande";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {useNavigate} from "react-router-dom";
import LoadingSpinner from '../components/LoadingSpinner';

const daribMontantCoupons= 250;
const daribNbCoupons= 0;
const daribNbTotalCoupons= 1000;

const CreateDonataire = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: {errors} } = useForm();
    const [showConjoint, setShowConjoint] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    let [msg, setMsg] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        setMsg(false);
        setLoading(true);
        let ambassadeur_id = JSON.parse(sessionStorage.getItem('user_data'))?.id;
        let datas = {
            ...data,
            ambassadeur_id: ambassadeur_id,
            nombreCoupons: daribNbCoupons,
            montantCoupon: daribMontantCoupons,
            nombreTotalCoupons: daribNbTotalCoupons
        }

        let resp = await postCreateDemande(datas, axiosPrivate);
        if (resp?.status === 200) {
            setMsg(true);
            navigate(`/beneficiaires?msgSuccess=true`);
        }

        setLoading(false);
    };

    const handleSituationMatrimonialeConjointe = (e) => {
        if (e.target.value === "marie") {
            setShowConjoint(true);
        } else{
            setShowConjoint(false);
        }
    }

    function onGotoListeDonataires() {
        navigate(`/beneficiaires`);
    }

    return (
        <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
            <div className="give-wrap-inner">
                <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">

                    <div className="summary entry-summary">
                        <div className="card mb-5 custom_card_style_1">
                            <h5 className="card-header text-center custom_card_style_2"> Ajouter un bénéficiaire</h5>
                            <div className="card-body">
                                <div id="give-form-62-wrap" className="give-display-onpage">

                                    { msg &&
                                        <div className="alert alert-success" role="alert">
                                            Votre demande a été ajoutée avec succès !
                                        </div>
                                    }

                                    { loading ?
                                        <LoadingSpinner />
                                        : (

                                            <form className="give-form" onSubmit={handleSubmit(onSubmit)}>
                                                <fieldset>
                                                    <legend>Informations du bénéficiaire </legend>
                                                    <div className="form-row">
                                                        <p className="form-row form-row-first form-row-responsive">
                                                            <label className="give-label" htmlFor="give-first"> Nom <span className="give-required-indicator"> *</span></label>
                                                            <input type="text" {...register("nom", { required: "Le nom est obligatoire." })} placeholder="Nom" />
                                                            { errors.nom && <label style={{color: "crimson"}}><FontAwesomeIcon icon={faTimesCircle}/> Le nom est obligatoire</label> }
                                                        </p>
                                                        <p className="form-row form-row-last form-row-responsive">
                                                            <label className="give-label" htmlFor="give-first"> Prenom <span className="give-required-indicator"> *</span></label>
                                                            <input type="text" {...register("prenom", { required: "Le prenom est obligatoire." })} placeholder="Prenom" />
                                                            { errors.prenom && <label style={{color: "crimson"}}><FontAwesomeIcon icon={faTimesCircle}/> Le prenom est obligatoire</label> }
                                                        </p>
                                                    </div>

                                                    <div className="form-row">
                                                        <p className="form-row form-row-first form-row-responsive">
                                                            <label className="give-label" htmlFor="give-first"> Téléphone <span className="give-required-indicator"> *</span></label>
                                                            <input type="text" {...register("telephone", { required: "Le téléphone est obligatoire." })} placeholder="Téléphone" />
                                                            { errors.telephone && <label style={{color: "crimson"}}><FontAwesomeIcon icon={faTimesCircle}/> Le téléphone est obligatoire</label> }
                                                        </p>
                                                        <p className="form-row form-row-last form-row-responsive">
                                                            <label className="give-label" htmlFor="give-first"> Nombre de personnes <span className="give-required-indicator"> *</span></label>
                                                            <input type="number" {...register("nombrepersonne", { min: 1, required: true })} defaultValue="1" min="1" placeholder="Nombre de personnes" />
                                                            { errors.nombrepersonne && <label style={{color: "crimson"}}><FontAwesomeIcon icon={faTimesCircle}/> Le nombre de personnes est obligatoire</label> }
                                                        </p>
                                                    </div>

                                                    <div className="form-row">
                                                        <p className="form-row form-row-first form-row-responsive">
                                                            <label className="give-label" htmlFor="give-first"> Situation matrimoniale </label>
                                                            <select {...register("situationmatrimonialconjointe")} style={{margin:"0px"}} onChange={(e) => handleSituationMatrimonialeConjointe(e)} >
                                                                <option value="">Sélectionner une situation matrimoniale</option>
                                                                <option value="marie">Marié(e)</option>
                                                                <option value="divorce">Divorcé(e)</option>
                                                                <option value="veuve">Veuf(ve)</option>
                                                                <option value="celibataire">Célibataire</option>
                                                            </select>
                                                        </p>
                                                        <p className="form-row form-row-last form-row-responsive">
                                                            <label className="give-label" htmlFor="give-first"> Nombre d'enfants</label>
                                                            <input type="number" {...register("nombreenfant", { min: 0, required: true })} defaultValue="0" min="0" placeholder="Nombre d'enfants" />
                                                        </p>
                                                    </div>

                                                    { showConjoint &&
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label className="give-label" htmlFor="give-first"> Nom du conjoint</label>
                                                                <input {...register("nomconjoint")} className="form-control" type="text"/>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label>Prénom du conjoint</label>
                                                                <input {...register("prenomconjoint")} className="form-control" type="text"/>
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="form-row">
                                                        <p className="form-row form-row-first form-row-responsive">
                                                            <label className="give-label" htmlFor="give-first"> Nom du notaire</label>
                                                            <input type="text" {...register("notaire")} placeholder="Nom du notaire" />
                                                        </p>
                                                        <p className="form-row form-row-last form-row-responsive">
                                                            <label htmlFor="exampleFormControlTextarea2">L'emplacement de l'appartement</label>
                                                            <textarea {...register("emplacement")} className="form-control" rows="2" cols="1"></textarea>
                                                        </p>
                                                    </div>
                                                </fieldset>

                                                <fieldset>
                                                    <legend>Informations sur la cagnotte </legend>
                                                    <div className="form-row">
                                                        <label className="give-label" htmlFor="give-first"> Titre <span className="give-required-indicator"> *</span></label>
                                                        <input type="text" {...register("titre", { required: true })} placeholder="Titre" />
                                                        { errors.titre && <label style={{color: "crimson"}}><FontAwesomeIcon icon={faTimesCircle}/> Le titre est obligatoire</label> }
                                                    </div>
                                                    <div className="form-row">
                                                        <label className="give-label" htmlFor="give-first"> Description <span className="give-required-indicator"> *</span></label>
                                                        <textarea {...register("description", {required: true})} className="form-control" rows="5" cols="1"></textarea>
                                                        { errors.description && <label style={{color: "crimson"}}><FontAwesomeIcon icon={faTimesCircle}/> La description est obligatoire</label> }
                                                    </div>
                                                </fieldset>

                                                <div className="mt-4">
                                                    <button
                                                        onClick={() => onGotoListeDonataires()}
                                                        type="button"
                                                        className="give-submit give-btn pull-left valider_contribution_details_for_validation_style"
                                                    >Retour
                                                    </button>

                                                    <div className="give-submit-button-wrap give-clearfix pull-right">
                                                        <button className="give-submit give-btn" style={{textTransform: "capitalize"}}>Créer la cagnotte</button>
                                                    </div>
                                                </div>
                                            </form>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateDonataire;