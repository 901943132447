import axios from "axios"
import endpoints from '../endpoints';

const getThemes = async () => {
    try {
        const response = await axios ({
            method: 'get',
            url: endpoints.urlGetThemes()
        });
        return response.data;
        
    } catch (error) {
        console.error(error);
        return false;
    }
}

export default getThemes;