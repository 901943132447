import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import getDonataireById from "../../api/queries/getDonataireById";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const DonataireDetail = () => {

    const navigate = useNavigate();
    const [errMsg, setErrMsg] = useState('');

    const [donataireNom, setDonataireNom] = useState('');
    const [donatairePrenom, setDonatairePrenom] = useState('');
    const [donataireTelephone, setDonataireTelephone] = useState('');
    const [donataireNombrePersonnes, setDonataireNombrePersonnes] = useState('');
    const [donataireNomConjoint, setDonataireNomConjoint] = useState('');
    const [donatairePrenomConjoint, setDonatairePrenomConjoint] = useState('');
    const [donataireNombreEnfants, setDonataireNombreEnfants] = useState(0);
    const [donataireSituationMatrimonialConjointe, setDonataireSituationMatrimonialConjointe] = useState('');
    const [donataireNotaire, setDonataireNotaire] = useState('');
    const [donataireEmplacement, setDonataireEmplacement] = useState('');
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const [donataireCagnotteTitre, setDonataireCagnotteTitre] = useState('');
    const [donataireCagnotteDescription, setDonataireCagnotteDescription] = useState('');

    // Récupération des données du bénéficiaire depuis les local storage
    let donataireId = JSON.parse(sessionStorage.getItem('donataire_id'));

    useEffect(() => {
        let ignore = false;

        try {
            async function startFetching() {
                const donataireDataResponse = await getDonataireById(donataireId, axiosPrivate, location, navigate);
                if (!ignore) {
                    setDonataireNom(donataireDataResponse?.data?.nom);
                    setDonatairePrenom(donataireDataResponse?.data?.prenom);
                    setDonataireTelephone(donataireDataResponse?.data?.telephone);
                    setDonataireNombrePersonnes(donataireDataResponse?.data?.nombrePersonnes);
                    setDonataireNomConjoint(donataireDataResponse?.data?.nomConjoint);
                    setDonatairePrenomConjoint(donataireDataResponse?.data?.prenomConjoint);
                    setDonataireSituationMatrimonialConjointe(donataireDataResponse?.data?.situationMatrimonialConjointe);
                    setDonataireNombreEnfants(donataireDataResponse?.data?.nombreEnfants);
                    setDonataireNotaire(donataireDataResponse?.data?.notaire);
                    setDonataireEmplacement(donataireDataResponse?.data?.emplacement);
                    setDonataireCagnotteTitre(donataireDataResponse?.data?.cagnotte?.titre);
                    setDonataireCagnotteDescription(donataireDataResponse?.data?.cagnotte?.description);
                }
            }
            startFetching();
        } catch (e) {
            console.error(e)
            setErrMsg("Une erreur est survenue lors de la récupération des données du bénéficiaire.");
        }

        return () => {
            ignore = true;
        };

    }, [donataireId, axiosPrivate, location, navigate]);

    function onGotoListeDonataires() {
        navigate(`/beneficiaires`);
    }

    const processSituationMatrimonialConjointe = (donataireStatutMatrimonialConjointeParam) => {

        switch (donataireStatutMatrimonialConjointeParam) {
            case 'marie':
                return (
                    <span>Marié(e)</span>
                )
            case 'divorce':
                return (
                    <span>Divorcé(e)</span>
                )
            case 'veuve':
                return (
                    <span>Veuf(ve)</span>
                )
            case 'celibataire':
                return (
                    <span>Célibataire</span>
                )
            default:
                return (
                    <span></span>
                )
        }
    }

    return (

        <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
            <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <div className="give-wrap-inner">
                <div id="give-form-62-content"
                     className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">

                    <div className="summary entry-summary">

                        <div className="card mb-5 custom_card_style_1">
                            <h5 className="card-header text-center custom_card_style_2">
                                Détails du bénéficiaire</h5>
                            <div className="card-body">
                                <div id="give-form-62-wrap" className="give-display-onpage">

                                    <div className="col-md-11 mx-auto">
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Nom :
                                                </label>&nbsp;
                                                <span>{donataireNom}</span>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Prénom :
                                                </label>&nbsp;
                                                <span>{donatairePrenom}</span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Téléphone :
                                                </label>&nbsp;
                                                <span>{donataireTelephone}</span>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Nombre de personnes :
                                                </label>&nbsp;
                                                <span>{donataireNombrePersonnes}</span>
                                            </div>
                                        </div>

                                        { donataireNomConjoint &&
                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <label className="give-label my_inline_style"> Nom du conjoint :
                                                    </label>&nbsp;
                                                    <span>{donataireNomConjoint}</span>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="give-label my_inline_style"> Prénom du conjoint :
                                                    </label>&nbsp;
                                                    <span>{donatairePrenomConjoint}</span>
                                                </div>
                                            </div>
                                        }

                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Nombre d'enfants :
                                                </label>&nbsp;
                                                <span>{donataireNombreEnfants}</span>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Situation matrimoniale :
                                                </label>&nbsp;
                                                {
                                                    processSituationMatrimonialConjointe(donataireSituationMatrimonialConjointe)
                                                }
                                            </div>
                                        </div>

                                        <div className="row">
                                            { donataireNotaire && 
                                                <div className="form-group col-md-6">
                                                    <label className="give-label my_inline_style"> Notaire :
                                                    </label>&nbsp;
                                                    <span>{donataireNotaire}</span>
                                                </div>
                                            }

                                            { donataireEmplacement &&
                                                <div className="form-group col-md-6">
                                                    <label className="give-label my_inline_style"> Emplacement :
                                                    </label>&nbsp;
                                                    <span>{donataireEmplacement}</span>
                                                </div>
                                            }
                                        </div>

                                        <div className="row">
                                            { donataireCagnotteTitre &&
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Titre de la cagnotte :
                                                </label>&nbsp;
                                                <span>{donataireCagnotteTitre}</span>
                                            </div>
                                            }

                                            { donataireCagnotteDescription &&
                                            <div className="form-group col-md-6">
                                                <label className="give-label my_inline_style"> Description de la cagnotte :
                                                </label>&nbsp;
                                                <span>{donataireCagnotteDescription}</span>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                </div>
                                <button
                                    id="return_to_feedback_list_a_valider"
                                    name="return_to_feedback_list_a_valider"
                                    onClick={() => onGotoListeDonataires()}
                                    type="button"
                                    className="give-submit give-btn pull-left valider_contribution_details_for_validation_style mt-4"
                                >Retour
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DonataireDetail;