const NotFound = () => (
    
    <div
        className="elementor-section elementor-top-section elementor-element elementor-element-a1452d9 elementor-section-stretched elementor-section-height-full elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
        data-id="a1452d9"
        data-element_type="section"
        data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
        style={{ width: "1170px", left: "-134.8px" }}>
        <div className="elementor-container elementor-column-gap-no">
            <div className="elementor-row">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7f03eb8" data-id="7f03eb8" data-element_type="column">
                    <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                            <div className="elementor-element elementor-element-147b4be elementor-widget elementor-widget-image" data-id="147b4be" data-element_type="widget" data-widget_type="image.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-image"><img src="http://demo2.themelexus.com/gainlove/wp-content/uploads/2021/01/404.svg" title="404" alt="404" /></div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-8b10f4a elementor-align-center elementor-widget elementor-widget-heading" data-id="8b10f4a" data-element_type="widget" data-widget_type="heading.default">
                                <div className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Oups! Page non trouvée.</h2>
                                </div>
                            </div>
                          
                            <div
                                className="elementor-element elementor-element-e52ee19 elementor-widget__width-auto elementor-button-primary elementor-widget elementor-widget-button"
                                data-id="e52ee19"
                                data-element_type="widget"
                                data-widget_type="button.default"
                            >
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
  
export default NotFound;