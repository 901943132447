import endpoints from '../endpoints';

const postParticipationAmbassadeurCagnotte = async (ambassadeur_id, cagnotte_id, axiosPrivate) => {
    try {
        return await axiosPrivate.post(endpoints.urlPostParticipationAmbassadeurCagnotte(), {
            "ambassadeur": `/api/administrateur/${ambassadeur_id}`,
            "cagnotte": `/api/cagnotte/${cagnotte_id}`
        });
    } catch (error) {
        console.error(error);
        return false;
    }
}

export default postParticipationAmbassadeurCagnotte;