import axios from "axios"
import endpoints from '../endpoints';

const getParticipationByAmbassadeurAndCagnotte = async (ambassadeur_id, cagnotte_id) => {
    try {
        const response = await axios ({
            method: 'get',
            url: endpoints.urlGetParticipationByAmbassadeurAndCagnotte(ambassadeur_id, cagnotte_id)
        });
        return response.data;

    } catch (error) {
        console.error(error);
        return false;
    }
}

export default getParticipationByAmbassadeurAndCagnotte;

