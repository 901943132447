import { useEffect, useState } from "react";
import {useNavigate, useLocation} from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import LoadingSpinner from '../components/LoadingSpinner';
import { getDemandesComplete, putChangeDemande } from '../api/queries';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltUp, faLongArrowAltDown, faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const CompleteDemandes = () => {
    let isLogged = JSON.parse(sessionStorage.getItem('isLogged')) ?? null;
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { SearchBar } = Search;
    let [listeDemandes, setListeDemandes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const handleCloseConfirm = () => setShowConfirm(false);
    let [itemSelect, setItemSelect] = useState(null);
    let [msgSuccess, setMsgSucces] = useState(false);

    const [columns] = useState([{
            dataField: 'ambassadeur.fullName',
            text: 'Ambassadeur'
        },{
            dataField: 'fullNameResponsable',
            text: "Responsable",
            sort: true
        }, {
            dataField: 'nombrePersonnes',
            text: "Nombre de personnes",
            sort: true
        },  {
            dataField: 'nombreEnfants',
            text: "Nombre d'enfants",
            sort: true
        }, {
            dataField: '',
            text: 'Actions',
            formatter: showMenus
        }
    ]);

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            De {from} à {to} sur {size} résultat(s)
        </span>
    );

    const [options] = useState({
        pageStartIndex: 1,
        paginationSize: 5,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: false, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Retour',
        nextPageText: 'Suivant',
        lastPageText: 'Dernier',
        nextPageTitle: 'Première page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Page suivante',
        lastPageTitle: 'Dernière page',
        paginationTotalRenderer: customTotal,
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '30', value: 30
        }, {
            text: '50', value: 50
        }, {
            text: 'Tout', value: listeDemandes?.length
        }]
    });

    const sortFunc = (a, b, order, dataField) => {
        if ('string' == typeof a && 'string' == typeof b) {
            if (order === 'asc') {
                return a.localeCompare(b);
            }

            if (order === 'desc') {
                return b.localeCompare(a);
            }

        } else {
            if (order === 'asc') {
                return a - b;
            }
            return b - a; // desc
        }
    }

    const sortOption = {
        // No need to configure sortFunc per column
        sortFunc: sortFunc,
        // No need to configure sortCaret per column
        sortCaret: (order, column) => {
            if (!order) return (<span> <FontAwesomeIcon icon={faLongArrowAltUp} /><FontAwesomeIcon icon={faLongArrowAltDown} /> </span>);
            else if (order === 'asc') return (<span>&nbsp;<FontAwesomeIcon icon={faLongArrowAltUp} /><font style={{color: "#9C14FC"}}><FontAwesomeIcon icon={faLongArrowAltDown} /></font></span>);
            else if (order === 'desc') return (<span>&nbsp;<font style={{color: "#9C14FC"}}><FontAwesomeIcon icon={faLongArrowAltUp} /></font><FontAwesomeIcon icon={faLongArrowAltDown} /></span>);
            return null;
        }
    };

    const onCreateCagnotte = (id) => {
        setShowConfirm(true);
        setItemSelect(id);
    }

    const creerCagnotte = async () => {
        let body = { statutDemandeAjout: 0 };

        let res = await putChangeDemande(itemSelect, body, axiosPrivate, location, navigate);
        if (res?.status === 200) {
            setMsgSucces(true);
        }

        onCloseModalConfirm();
    }

    const onCloseModalConfirm = () => {
        setItemSelect(null);
        handleCloseConfirm();
    }

    const gotoDetails = (id) => {
        navigate(`/contributions/demande/details/${id}`);
    }

    function showMenus(cell, row) {
        return (
            <>
                <button title="Vois plus de details" type="button" onClick={() => gotoDetails(row.id)} className="btn btn-secondary btn-sm mr-1">
                    <FontAwesomeIcon icon={faSearchPlus}/>
                </button>
                <button title="Créer la demande" onClick={() => onCreateCagnotte(row.id)} type="button" className="btn btn-success btn-sm mr-1">
                    Création cagnotte
                </button>
            </>
        );
    }
    
    const initialDatas = async () => {
        setLoading(true);
        // Si une personne est connecté
        if (isLogged === true) {
            // On récupère le rôle de l'utilisateur actuellement connecté
            let user_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles
            // Si la personne connecté est un ambassadeur
            if (user_role?.includes("ROLE_ADMIN")) {
                // Affichage de la liste des demandes
                const resp = await getDemandesComplete(axiosPrivate, location, navigate);
                setListeDemandes(resp?.["hydra:member"]);
            }
        }
        setMsgSucces(false);
        setLoading(false);
    }

    useEffect(() => {
        initialDatas();
    }, [isLogged, msgSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id="content" className="site-content">
            <div className="wrap top-ecart">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <article id="post-1652" className="post-1652 page type-page status-publish hentry">
                            <div className="page-inner">
                                <div className="entry-content">
                                    <div id="give-form-62-wrap" className="give-form-wrap give-display-onpage">
                                        <h4 className="entry-title text-center">Liste des demandes à conclure</h4>

                                        { msgSuccess &&
                                            <div class="alert alert-success alert-dismissable">
                                                Opération effectuée avec succés.
                                            </div>
                                        }

                                        { loading === false ? (
                                            listeDemandes?.length > 0 ?
                                                <ToolkitProvider keyField="id" data={listeDemandes} columns={columns} search={{defaultSearch: '', searchFormatted: true}}>
                                                    {
                                                        props => (
                                                            <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                                                <div style={{paddingRight: "0px"}} className='col-md-4 pull-right'>
                                                                    <SearchBar style={{"borderRadius": "0.5rem"}} placeholder="Rechercher" {...props.searchProps} />
                                                                </div>

                                                                <hr/>
                                                                <BootstrapTable {...props.baseProps} pagination={paginationFactory(options)} sort={ sortOption } classes="fix-responsive-width" />
                                                            </div>
                                                        )
                                                    }
                                                </ToolkitProvider> : "Vous n'avez aucune demande."
                                            ) : <LoadingSpinner />
                                        }

                                        {/* Modal Continuer pour la cagnotte */}
                                        <Modal show={showConfirm} onHide={onCloseModalConfirm} backdrop="static" keyboard={false}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Confirmer la creation de cagnotte </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                Voulez-vous continuer ?
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={onCloseModalConfirm}>Annuler </Button>
                                                <Button onClick={creerCagnotte} style={{"backgroundColor": "#9C14FC"}}>Continuer </Button>
                                            </Modal.Footer>
                                        </Modal>

                                    </div>
                                </div>
                            </div>
                        </article>
                    </main>
                </div>
            </div>
        </div>
    )

}

export default CompleteDemandes;