import axios from "axios"
import endpoints from '../endpoints'

const getCagnottesProgrammees = async (page = 1, statutProgramme = 1) => {
    try {
        const response = await axios({
            method: 'get',
            url: endpoints.urlGetCagnottesProgrammees(page, statutProgramme)
        });
        return response.data;

    } catch (error) {
        console.error(error);
        return false;
    }
}

export default getCagnottesProgrammees;