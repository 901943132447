import React from "react";

const CreateCagnotte = () => {

    return (
        
        <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
            <div className="give-wrap-inner">
                <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">

                    <div className="summary entry-summary">
                        <div className="card mb-5 custom_card_style_1">

                            <div className="card-body">
                                <div id="give-form-62-wrap" className="give-display-onpage">
                                    <div id="give-wrap" className="give-wrap" style={{ "position": "relative"}}>
                                        <div className="give-wrap-inner">
                                            <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">
                                                <div className="summary entry-summary">
                                                    <div id="give-form-62-wrap" className="give-display-onpage">

                    
                                                        <form className="give-form">
                                                            <fieldset>
                                                                <legend>Création de nouvelle cagnotte  </legend>
                                                                <div className="form-row">
                                                                    <p className="form-row form-row-first form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Titre <span className="give-required-indicator"> *</span></label>
                                                                        <input type="text" name="nom" id="nom" placeholder="Nom" />
                                                                    </p>
                                                                    <p className="form-row form-row-last form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Slug<span className="give-required-indicator"> *</span></label>
                                                                        <input type="text" name="prenom" id="prenom" placeholder="Prenom" />
                                                                    </p>
                                                                </div>

                                                                <div className="form-row">
                                                                    <p className="form-row form-row-first form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Description <span className="give-required-indicator"> *</span></label>
                                                                        <input type="email" placeholder="Email" />
                                                                    </p>
                                                                    <p className="form-row form-row-last form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Nomnre de coupon</label>
                                                                        <input type="text" name="telephone" id="telephone" placeholder="Téléphone" />
                                                                    </p>
                                                                </div>

                                                                <div className="form-row">
                                                                    <p className="form-row form-row-first form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Montant de coupon <span className="give-required-indicator"> *</span></label>
                                                                        <input type="email" placeholder="Email" />
                                                                    </p>
                                                                    <p className="form-row form-row-last form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Nombre total de coupon</label>
                                                                        <input type="text" name="telephone" id="telephone" placeholder="Téléphone" />
                                                                    </p>
                                                                </div>

                                                                <div className="form-row">
                                                                    <p className="form-row form-row-first form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Valide <span className="give-required-indicator"> *</span></label>
                                                                        <input type="email" placeholder="Email" />
                                                                    </p>
                                                                    <p className="form-row form-row-last form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Ambassadeur</label>
                                                                        <input type="text" name="telephone" id="telephone" placeholder="Téléphone" />
                                                                    </p>
                                                                </div>

                                                                <div className="form-row">
                                                                    <p className="form-row form-row-first form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Image <span className="give-required-indicator"> *</span></label>
                                                                        <input type="email" placeholder="Email" />
                                                                    </p>
                                                                    <p className="form-row form-row-last form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Date debut</label>
                                                                        <input type="text" name="telephone" id="telephone" placeholder="Téléphone" />
                                                                    </p>
                                                                </div>

                                                                <div className="form-row">
                                                                    <p className="form-row form-row-first form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Date publication <span className="give-required-indicator"> *</span></label>
                                                                        <input type="email" placeholder="Email" />
                                                                    </p>
                                                                    <p className="form-row form-row-last form-row-responsive">
                                                                        <label className="give-label" htmlFor="give-first"> Parrain marraine</label>
                                                                        <input type="text" name="telephone" id="telephone" placeholder="Téléphone" />
                                                                    </p>
                                                                </div>

                                                                <div className="give-submit-button-wrap give-clearfix pull-left">
                                                                    <button className="give-submit give-btn">Retour</button>
                                                                </div>
                                                                <div className="give-submit-button-wrap give-clearfix pull-right">
                                                                    <button className="give-submit give-btn">Créer la cagnotte</button>
                                                                </div>
                                                            </fieldset>
                                                        </form>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
                                
    )
}

export default CreateCagnotte;