import { useEffect, useState } from "react";

import { getCagnottes } from '../api/queries';
import DisplayCard from "../components/DisplayCard";

const CagnotteCloturees = () => {
    let [liste, setListe] = useState([]);
    let [currentPage, setCurrentPage] = useState(1)
    const [nbPages, setNbPages] = useState(1); 
    const dataLimit = 6; // nombre d'elements par page
    const pageLimit = 4; // nombre de pagination

    const renderDisplayComponent = () => {
        return liste?.map((el, index) => <DisplayCard data={el} key={index} />);
    }

    const goToNextPage = () => {
        setCurrentPage((currentPage) => currentPage + 1);
    }

    const goToPreviousPage = () => {
        setCurrentPage((currentPage) => currentPage - 1);
    }

    const changePage = (event) => {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    }

    //  afficher le groupe de numéros de page dans la pagination
    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
    };

    useEffect(() => {
        const getListe = async () => {
            const resp = await getCagnottes(currentPage, 1);
            setListe(resp?.["hydra:member"]);
            setNbPages( Math.round(resp?.["hydra:totalItems"]/dataLimit));
        }
        getListe();
    }, [currentPage])

    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-ce6aa8f elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="ce6aa8f" data-element_type="section"
            data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
            style={{ "width": "1489px", "left": "-99.5333px", "marginTop": "5em" }}>
            <div className="elementor-container elementor-column-gap-no">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-869dc3e"
                    data-id="869dc3e" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated margin-30">

                        <section className="elementor-section elementor-inner-section elementor-element elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="09205ac" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-no">
                                <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-09f281c"
                                    data-id="09f281c" data-element_type="column"
                                    data-settings='{"background_background":"classic"}'>
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-32c4ddb elementor-align-center elementor-widget__width-initial elementor-widget elementor-widget-heading"
                                            data-id="32c4ddb" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h2 className="elementor-heading-title elementor-size-default">Cagnottes cloturées </h2>
                                            </div>
                                        </div>
                                        <section
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-448094f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="448094f" data-element_type="section">
                                            <div className="elementor-column-gap-no">
                                                <div className="wrap">
                                                    <div id="primary" className="content-area">
                                                        <main id="main" className="site-main">
                                                            <div data-elementor-type="wp-post" data-elementor-id="1997"
                                                                className="elementor elementor-1997" data-elementor-settings="[]">
                                                                <div className="elementor-inner">
                                                                    <div className="elementor-section-wrap">
                                                                        <div className="elementor-section elementor-top-section elementor-element elementor-element-fe76d22 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                                            data-id="fe76d22" data-element_type="section">
                                                                            <div
                                                                                className="elementor-container elementor-column-gap-no">
                                                                                <div className="elementor-row">
                                                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a476304"
                                                                                        data-id="a476304"
                                                                                        data-element_type="column">
                                                                                        <div
                                                                                            className="elementor-column-wrap elementor-element-populated">
                                                                                            <div className="elementor-widget-wrap">
                                                                                                <div className="elementor-element elementor-element-f0782d5 campain-grid-style-1 elementor-widget elementor-widget-opal-give-campain"
                                                                                                    data-id="f0782d5"
                                                                                                    data-element_type="widget"
                                                                                                    data-widget_type="opal-give-campain.default">
                                                                                                    <div className="elementor-widget-container">
                                                                                                        <div className="give-wrap">
                                                                                                            <div className="give-grid give-grid--3">
                                                                                                                { renderDisplayComponent() }
                                                                                                            </div>
                                                                                                            <div className="row pull-right">
                                                                                                                <nav aria-label="Page navigation example">
                                                                                                                    <ul className="pagination">
                                                                                                                        <li className="page-item"> 
                                                                                                                            <button onClick={goToPreviousPage} className="page-link" aria-label="Previous" disabled={currentPage === 1}> 
                                                                                                                                <span aria-hidden="true">«</span>
                                                                                                                                <span className="sr-only">Previous</span>
                                                                                                                            </button>
                                                                                                                        </li>
                                                                                                                        {/* show page numbers */}
                                                                                                                        {getPaginationGroup()?.map((item, index) => index+1 <= nbPages ?
                                                                                                                            (
                                                                                                                                <li key={index} className={`page-item ${currentPage === item ? 'active' : null}`}>
                                                                                                                                    <button onClick={(e) => changePage(e)} className="page-link" href="#">{item}</button>
                                                                                                                                </li>
                                                                                                                            ) : null
                                                                                                                        )}
                                                                                                                        <li className="page-item">
                                                                                                                            <button onClick={goToNextPage} className="page-link" aria-label="Previous" disabled={currentPage === nbPages}>
                                                                                                                                <span aria-hidden="true">»</span>
                                                                                                                                <span className="sr-only">Next</span>
                                                                                                                            </button>
                                                                                                                        </li>
                                                                                                                    </ul>
                                                                                                                </nav>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </main>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default CagnotteCloturees;