import {useNavigate, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import '../Contribution.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import getDonataireByAmbassadeur from '../api/queries/getDonataireByAmbassadeur'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faUserPlus,
    faUserEdit,
    faSearch,
    faLongArrowAltUp,
    faLongArrowAltDown,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const Donataire = () => {
    let isLogged = JSON.parse(sessionStorage.getItem('isLogged')) ?? null;
    let [listeDonatairesByAmbassadeur, setListeDonatairesByAmbassadeur] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const {SearchBar} = Search;
    const navigate = useNavigate();
    const [columns] = useState([{
        dataField: 'nom',
        text: 'Nom',
        sort: true
    }, {
        dataField: 'prenom',
        text: 'Prénom',
        sort: true
    }
        , {
            dataField: 'telephone',
            text: 'Téléphone',
            sort: true
        },
        {
            dataField: 'nombrePersonnes',
            text: 'Nombre de personnes',
            sort: true
        },
        {
            dataField: 'statut',
            text: 'Statut de la demande',
            formatter: statusFormatter
        },
        {
            dataField: '',
            text: 'Actions',
            formatter: showMenus
        }
    ]);
    let [msg, setMsg] = useState(false);

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
      De {from} à {to} sur {size} résultat(s)
    </span>
    );

    const sortFunc = (a, b, order, dataField) => {
        
        if ('string' == typeof a && 'string' == typeof b) {
            if (order === 'asc') {
                return a.localeCompare(b);
            }

            if (order === 'desc') {
                return b.localeCompare(a);
            }

        } else {
            if (order === 'asc') {
                return a - b;
            }
            return b - a; // desc
        }
    }

    const sortOption = {
        // No need to configure sortFunc per column
        sortFunc: sortFunc,
        // No need to configure sortCaret per column
        sortCaret: (order, column) => {
            if (!order) return (<span> <FontAwesomeIcon icon={faLongArrowAltUp} /><FontAwesomeIcon icon={faLongArrowAltDown} /> </span>);
            else if (order === 'asc') return (<span>&nbsp;<FontAwesomeIcon icon={faLongArrowAltUp} /><font style={{color: "#9C14FC"}}><FontAwesomeIcon icon={faLongArrowAltDown} /></font></span>);
            else if (order === 'desc') return (<span>&nbsp;<font style={{color: "#9C14FC"}}><FontAwesomeIcon icon={faLongArrowAltUp} /></font><FontAwesomeIcon icon={faLongArrowAltDown} /></span>);
            return null;
        }
    };

    const [options] = useState({
        pageStartIndex: 1,
        paginationSize: 5,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: false, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Retour',
        nextPageText: 'Suivant',
        lastPageText: 'Dernier',
        nextPageTitle: 'Première page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Page suivante',
        lastPageTitle: 'Dernière page',
        paginationTotalRenderer: customTotal,
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '30', value: 30
        }, {
            text: '50', value: 50
        }, {
            text: 'Tout', value: listeDonatairesByAmbassadeur?.length
        }]
    });

    function showMenus(cell, row) {
        return (
            <>
                <button title="Détail du bénéficiaire" onClick={() => onGotoDetailDonataire(row.id)} type="button" className="btn btn-info btn-sm mr-1">
                    <FontAwesomeIcon icon={faSearch}/>
                </button>
                <Button className={row.statutDemandeAjout === true ? "hide":"give-submit btn-sm"}
                    title="Modifier un bénéficiaire" onClick={() => onGotoEditDonataire(row.id)} type="button"
                >
                    <FontAwesomeIcon icon={faUserEdit}/>
                </Button>
                {
                    (row.statut === 0 && row.cagnotte.programme === false) &&
                    <Button className={row.statutDemandeAjout === true ? "hide":"btn-danger btn-sm ml-1"}
                                        title="Supprimer" onClick={() => onGotoEditDonataire(row.id)} type="button"
                                    >
                        <FontAwesomeIcon icon={faTimes}/>
                    </Button>                    
                }
            </>
        );
    }

    function statusFormatter(cell, row) {
        console.log( cell , row )
        if (row.statut === 0 && row.cagnotte.programme === true) {
            return <span className="badge badge-info">Programmé </span>;
        } else if(row.statut === 0 && row.cagnotte.programme === false) {
            return <span className="badge badge-warning">En cours </span>;
        } else {
            return <span className="badge badge-success">validation effectué</span>;
        }
    }

    function onGotoAddDonataire() {
        navigate(`/beneficiaire/creation-beneficiaire`);
    }

    function onGotoDetailDonataire(donataireId) {
        // Enregistrement de l'id du bénéficiaire courant dans les local storage
        sessionStorage.setItem('donataire_id', JSON.stringify(donataireId));
        navigate(`/beneficiaire/detail`);
    }

    function onGotoEditDonataire(donataireId) {
        // Enregistrement de l'id du bénéficiaire courant dans les local storage
        sessionStorage.setItem('donataire_id', JSON.stringify(donataireId));
        navigate(`/beneficiaire/modification-beneficiaire`);
    }

    const showAucunDonataire = () => {
        return <div className="mt-4"><p>Aucun bénéficiaire disponible</p></div>;
    };



    useEffect(() => {
        const getListeDonataireByAmbassadeur = async () => {
            // Si une personne est connecté
            if (isLogged === true) {

                // On récupère le rôle de l'utilisateur actuellement connecté
                let user_role = JSON.parse(sessionStorage.getItem('user_data'))?.roles

                // Si la personne connecté est un ambassadeur
                if (user_role?.includes("ROLE_AMBASSADEUR")) {
                    let ambassadeur_id = JSON.parse(sessionStorage.getItem('user_data'))?.id;
                    // Affichage de la liste des bénéficiaires par ambassadeur
                    const resp = await getDonataireByAmbassadeur(ambassadeur_id, axiosPrivate, location, navigate);
                    setListeDonatairesByAmbassadeur(resp?.["hydra:member"]);
                }
            }
        }
        getListeDonataireByAmbassadeur();
        const searchParams = new URLSearchParams(window.location.search);

        if(searchParams.get('msgSuccess') === 'true'){
            setMsg(true);
        }
    }, [isLogged, axiosPrivate, location, navigate])

    return (
        <div id="give-wrap" className="give-wrap top-ecart" style={{"position": "relative"}}>
            <div className="give-wrap-inner">
                <div id="give-form-62-content" className="post-62 give_forms type-give_forms status-publish has-post-thumbnail give_forms_category-children give_forms_category-wildlife">

                    <div className="summary entry-summary">
                        <div className="card mb-5 custom_card_style_1">
                            <h5 className="card-header text-center custom_card_style_2"> Liste des bénéficiaires</h5>
                            <div className="card-body">
                            { msg &&
                                        <div className="alert alert-success" role="alert">
                                            Votre demande a été ajoutée avec succès !
                                        </div>
                                    }
                                {listeDonatairesByAmbassadeur?.length > 0 ?
                                    <ToolkitProvider keyField="id" data={listeDonatairesByAmbassadeur} 
                                        columns={columns} search={{defaultSearch: '', searchFormatted: true}}
                                    >
                                        {
                                            props => (
                                                <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                                    <div className='col-md-4 pull-right'>
                                                        <SearchBar placeholder="Rechercher" {...props.searchProps} />
                                                    </div>

                                                    <hr/>
                                                    <BootstrapTable {...props.baseProps}
                                                        pagination={paginationFactory(options)} sort={ sortOption } classes="fix-responsive-width"
                                                    />
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider> : showAucunDonataire()
                                }
                                <div>
                                    <Button
                                        className="give-submit give-btn pull-left valider_contribution_details_for_validation_style"
                                        title="Ajouter un nouveau bénéficiaire"
                                        onClick={() => onGotoAddDonataire()} type="button"
                                    >
                                        <span>Ajouter</span>&nbsp;<FontAwesomeIcon icon={faUserPlus}/>
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Donataire;